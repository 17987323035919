@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');
.topbar {
    background-color: 
    #e0a231;
    color: #999ea3;
}
ul li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    line-height: 26px;
}
.topbar .contact-info ul li {
    display: inline-block;
    font-size: 14px;
    color: rgba(255,255,255,0.7);
    line-height: 48px;
    padding: 0 16px;
    font-weight: normal;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.topbar .contact-info ul li i.fa {
    font-size: 18px;
    margin-right: 5px;
}
.topbar .social {
    float: right;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}
.topbar .social li {
    float: left;
}
.topbar .social a {
    display: inline-block;
    font-size: 18px;
    float: left;
    padding: 0 15px;
    color: #FFF;
    line-height: 48px;
    border-right: 1px solid 
    #e0a231;
    border-left: 1px solid 
    #e0a231;
    transition: all .3s ease;
}
a{
    color: black;
}
.black{
    color: white;
    font-size: 17px;
    font-weight: 500;
}
.SOCIAL{
    
    float: left;
     text-align: center;
}
.SOCIAL a {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    text-align: center;
    background: transparent;
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 16px;
    line-height: 36px;
    margin: 9px 2px 0px;
    border-radius: 70px;
    transition: all 500ms ease-out 0s;
    background-color: 
    gray;
}
.bs-slider .bs-slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.40);
}
.paddingrl{
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
}
.one1{
    height: 75px;
}
.navbar-nav .dropdown-menu{
margin-left: -85px;
}

@media only screen and (max-width: 575px) {
    .SOCIAL{
    
       display: none;
    }
    .logo1{
        margin-right: 153px;
    }
    .pera{
        display: none;
      }
}
.iconnn{
width: 20px;
margin-bottom: 5px;
}
.fixednev{
   
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: transparent  !important;
        /* border-bottom: 1px solid white; */
        z-index: 99;
        /* Add more styling as needed */
     
}
.margintop{
    /* margin-top: 108px; */
}
.navbar-toggler-icon{
    background-image:white !important;
}
.navbar-toggler{
    color:transparent !important;
    background-color: transparent !important;
}
.navbar-toggler{
    border: transparent;
}
/* .sliderclass{
    display: flex;
    position: relative;
} */

.navbar-toggler-icon {
    background-image:url("../assets//sss.png") !important;
    font-size: 13px;
}