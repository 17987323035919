/* table of content - start
==================================================================================================== */
/* ==================================================
* Project Name 	:  Harmoni Event Management
* Author 		:  jThemes Studio
* File 			:  Css Base
* Version 		:  1.0.0
* Project Start :  20 April 2018
* Last Change 	:  06 june 2018
================================================== */
/* table of content - end
==================================================================================================== */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i|Roboto:400,400i,700,700i,900,900i");
/* fonts load - start
==================================================================================================== */
body, p {
  font-family: "Open Sans", sans-serif; }

button, a,
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif; }

/* fonts load - end
==================================================================================================== */
/* global area - start
==================================================================================================== */
body {
  margin: 0;
  padding: 0;
  word-spacing: 2px;
  letter-spacing: .6px;
  color: #878787;
  font-size: 16px;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased; }

::-moz-selection {
  color: #ffbe30;
  background: #333333; }

::selection {
  color: #ffbe30;
  background: #333333; }

.container {
  margin: 0 auto;
  max-width: 1200px; }

a {
  color: #ffbe30; }

a,
button {
  border: none;
  cursor: pointer;
  background: none;
  text-decoration: none;
  display: inline-block; }
  a:focus,
  button:focus {
    outline: none; }

h1, h2, h3, h4, h5, h6 {
  color: #333333;
  text-transform: capitalize; }

p {
  color: #333333; }

img {
  height: auto;
  max-width: 100%; }

.image-wrapper {
  overflow: hidden;
  position: relative; }

strong, span, small, i {
  line-height: normal;
  display: inline-block; }

strong {
  font-weight: 700; }

a:hover {
  text-decoration: none;
  color: #e0a231; }

button:focus,
select:focus {
  outline: none; }

.form-wrapper .form-item {
  width: 100%;
  height: 50px;
  display: table;
  position: relative; }
  .form-wrapper .form-item input {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0px 25px;
    background-color: #f7f7f7;
    border: 1px solid #f0f0f0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
    .form-wrapper .form-item input:focus {
      outline: none;
      border-color: #ffbe30; }
  .form-wrapper .form-item .form-item-btn {
    top: 0px;
    z-index: 1;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    font-weight: 700;
    overflow: hidden;
    padding: 0px 30px;
    text-align: center;
    position: absolute;
    color: #ffffff;
    text-transform: uppercase; }
    .form-wrapper .form-item .form-item-btn:after, .form-wrapper .form-item .form-item-btn:before {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: -1;
      content: '';
      position: absolute;
      -webkit-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .form-wrapper .form-item .form-item-btn:before {
      background: #ffbe30; }
    .form-wrapper .form-item .form-item-btn:after {
      opacity: 0;
      background: #ffbe30; }
    .form-wrapper .form-item .form-item-btn:hover:before {
      opacity: 0; }
    .form-wrapper .form-item .form-item-btn:hover:after {
      opacity: 1; }

textarea {
  width: 100%;
  padding: 25px;
  display: block;
  min-height: 150px;
  background-color: #f7f7f7;
  border: 1px solid #f0f0f0;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }
  textarea:focus {
    outline: none;
    border-color: #ffbe30; }


 
  .custom-select:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent; }

:-moz-placeholder {
  color: #878787; }

::-moz-placeholder {
  color: #878787; }

:-ms-input-placeholder {
  color: #878787; }

::-webkit-input-placeholder {
  color: #878787; }

.owl-theme .owl-nav,
.owl-theme .owl-dots {
  margin: 0px;
  padding: 0px; }

/* customization of unorder list - start 
================================================== */
.ul-li ul,
.ul-li-block ul {
  margin: 0px;
  padding: 0px; }

.ul-li ul li {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
  display: inline-block; }

.ul-li-block ul li {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none; }

/* customization of unorder list - end
================================================== */
/* countdown-timer - style - start
================================================== */
.countdown-timer {
  width: 100%;
  display: table;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .countdown-timer ul {
    margin: 0px;
    padding: 0px;
    display: table; }
    .countdown-timer ul li {
      float: left;
      height: 80px;
      min-width: 80px;
      margin: 0px 5px;
      padding: 15px 10px;
      text-align: center;
      border-radius: 15px;
      display: inline-block;
      color: #878787;
      border: 2px solid #878787; }
      .countdown-timer ul li strong {
        margin: 0px;
        width: 100%;
        display: block;
        line-height: .8;
        font-weight: 900;
        font-size: 36px;
        font-family: "Roboto", sans-serif; }
      .countdown-timer ul li small {
        font-size: 14px;
        text-transform: uppercase; }

/* countdown-timer - style - end
================================================== */
/* pagination setting - start
================================================== */
.pagination {
  border-radius: 0px; }
  .pagination ul {
    display: table; }
    .pagination ul .page-item {
      height: 40px;
      min-width: 40px;
      position: relative; }
      .pagination ul .page-item .page-link {
        margin: 0px;
        width: 100%;
        height: 100%;
        padding: 5px;
        border: none;
        padding: 0px;
        display: block;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: #878787;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        background-color: transparent; }
      .pagination ul .page-item:hover .page-link {
        color: #333333; }
    .pagination ul .page-item.active .page-link {
      color: #333333;
      font-size: 24px; }
      .pagination ul .page-item.active .page-link:after {
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        width: 100%;
        content: '';
        position: absolute;
        background-color: #ffbe30; }
    .pagination ul .page-item.prev-item .page-link,
    .pagination ul .page-item.next-item .page-link {
      padding: 0px 30px;
      border-radius: 0px;
      position: relative; }
      .pagination ul .page-item.prev-item .page-link:before,
      .pagination ul .page-item.next-item .page-link:before {
        top: 50%;
        font-weight: 900;
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free'; }
    .pagination ul .page-item.prev-item:hover .page-link,
    .pagination ul .page-item.next-item:hover .page-link {
      color: #333333; }
      .pagination ul .page-item.prev-item:hover .page-link:before,
      .pagination ul .page-item.next-item:hover .page-link:before {
        color: #333333; }
    .pagination ul .page-item.prev-item .page-link:before {
      left: 15px;
      content: '\f053'; }
    .pagination ul .page-item.next-item .page-link:before {
      right: 15px;
      content: '\f054'; }

/* pagination setting - end
================================================== */
/* padding - margin setting - start 
================================================== */
.sec-ptb-100 {
  padding: 50px 0px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-80 {
  margin-bottom: 80px; }

/* padding - margin setting - end
================================================== */
/* link button - start
================================================== */
.custom-btn {
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
  padding: 10px 20px;
  text-align: center;
  position: relative;
  border-radius: 50px;
  border: 1px solid gray;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  text-transform: uppercase;
  background: #e0a231;
  color: #ffffff !important;
  -webkit-box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2); }
  .custom-btn:after, .custom-btn:before {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    content: '';
    position: absolute;
    border-radius: 50px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
  .custom-btn:before {
    background:black;
  
}
  .custom-btn:after {
    opacity: 0;
    background: -webkit-gradient(linear, left top, right bottom, from(#ffbe30), to(#ff3e00));
    background: -webkit-linear-gradient(top left, #ffbe30, #ff3e00);
    background: -o-linear-gradient(top left, #ffbe30, #ff3e00);
    background: linear-gradient(to bottom right, #ffbe30, #ff3e00); }
  .custom-btn:hover:before {
    opacity: 0; }
  .custom-btn:hover:after {
    opacity: 1; }

/* link button - end
================================================== */
/* title - start
================================================== */
.section-title {
  position: relative; }
  .section-title .line-style {
    z-index: 1;
    top: -30px;
    left: -30px;
    width: 70px;
    height: 3px;
    position: absolute;
    background-color: #ffbe30; }
  .section-title .sub-title {
    margin-bottom: 15px;
    letter-spacing: 5px;
    color: gray;
    font-size: 18px;
    text-transform: uppercase; }
  .section-title .big-title {
    margin: 0px;
    padding: 0px;
    font-size: 48px;
    font-weight: 400;
    color: white; }
    .section-title .big-title strong {
      font-weight: 900; }

.title-xlarge {
  font-size: 36px; }

.title-large {
  font-size: 30px; }

.title-medium {
  font-size: 24px; }

.title-small {
  font-size: 18px; }

.title-xsmall {
  font-size: 16px; }

.title-xxsmall {
  font-size: 14px; }

/* title - end
================================================== */
/* back to top - start
================================================== */
.thetop {
  top: 0;
  left: 0;
  right: 0;
  position: absolute; }

.backtotop {
  right: 15px;
  width: 40px;
  bottom: 15px;
  height: 60px;
  z-index: 999;
  position: fixed;
  overflow: hidden;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.2); }
  .backtotop .scroll {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    line-height: 60px;
    text-align: center;
    position: relative;
    color: #ffffff;
    font-size: 30px; }
    .backtotop .scroll:after, .backtotop .scroll:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      content: '';
      position: absolute;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .backtotop .scroll:before {
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
    .backtotop .scroll:after {
      opacity: 0;
      background: -webkit-gradient(linear, left top, right bottom, from(#ffbe30), to(#ff3e00));
      background: -webkit-linear-gradient(top left, #ffbe30, #ff3e00);
      background: -o-linear-gradient(top left, #ffbe30, #ff3e00);
      background: linear-gradient(to bottom right, #ffbe30, #ff3e00); }
    .backtotop .scroll:hover:before {
      opacity: 0; }
    .backtotop .scroll:hover:after {
      opacity: 1; }

/* back to top - end
================================================== */
/* preloader - start
================================================== */
#preloader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  position: fixed;
  overflow: visible;
 
}
/* preloader - end
================================================== */
/* plus hover effect - start
================================================== */
.plus-effect {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  background: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 62, 0, 0.8)), to(rgba(255, 190, 48, 0.8)));
  background: -webkit-linear-gradient(top left, rgba(255, 62, 0, 0.8), rgba(255, 190, 48, 0.8));
  background: -o-linear-gradient(top left, rgba(255, 62, 0, 0.8), rgba(255, 190, 48, 0.8));
  background: linear-gradient(to bottom right, rgba(255, 62, 0, 0.8), rgba(255, 190, 48, 0.8)); }
  .plus-effect:before, .plus-effect:after {
    top: 50%;
    left: 50%;
    content: '';
    border-radius: 6px;
    position: absolute;
    background: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); }
  .plus-effect:before {
    width: 4px;
    height: 50px; }
  .plus-effect:after {
    height: 4px;
    width: 50px; }

/* plus hover effect - end
================================================== */
/* custom colorz - start
================================================== */
.default-color {
  color: #878787; }

.yellow-color {
  color: #ffbe30; }

.orange-color {
  color: #ff3e00; }

.black-color {
  color: #333333; }

.white-color {
  color: #ffffff; }

.gray-deep-color {
  color: #f0f0f0; }

.gray-light-color {
  color: #f7f7f7; }

.bg-default {
  background-color: #878787; }

.bg-yellow {
  background-color: #ffbe30; }

.bg-orange {
  background-color: #ff3e00; }

.bg-black {
  background-color: #333333; }

.bg-white {
  background-color: #ffffff; }

.bg-gray-deep {
  background-color: #f0f0f0; }

.bg-gray-light {
  background-color: black; }

/* .overlay-black {
  background: black; } */

.overlay-white {
  background: rgba(255, 255, 255, 0.5); }

/* custom colorz - end
================================================== */
/* transition - start
================================================== */
a,
button {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* transition - end
================================================== */
/* global area - end
==================================================================================================== */
.default-header-p {
  padding-top: 164px; }

/* header section - start
==================================================================================================== */
.default-header-section {
  z-index: 999;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1); }
  .default-header-section .header-top {
    padding: 15px 0px;
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
    .default-header-section .header-top .basic-contact ul {
      margin: 0px;
      padding: 0px; }
      .default-header-section .header-top .basic-contact ul li {
        float: left;
        list-style: none;
        position: relative;
        margin-right: 30px;
        padding-right: 30px;
        color: #ffffff;
        display: inline-block;
        font-size: 14px; }
        .default-header-section .header-top .basic-contact ul li:after {
          top: 0;
          right: 0;
          bottom: 0;
          content: '|';
          position: absolute; }
        .default-header-section .header-top .basic-contact ul li:last-child {
          margin-right: 0px;
          padding-right: 0px; }
          .default-header-section .header-top .basic-contact ul li:last-child:after {
            display: none; }
        .default-header-section .header-top .basic-contact ul li a {
          color: #ffffff; }
          .default-header-section .header-top .basic-contact ul li a i {
            margin-right: 5px; }
    .default-header-section .header-top .register-login-group ul {
      margin: 0px;
      float: right;
      padding: 0px;
      display: table; }
      .default-header-section .header-top .register-login-group ul li {
        float: left;
        list-style: none;
        position: relative;
        margin-right: 30px;
        padding-right: 30px;
        display: inline-block; }
        .default-header-section .header-top .register-login-group ul li:after {
          top: 0;
          right: 0;
          bottom: 0;
          content: '|';
          position: absolute;
          color: #ffffff; }
        .default-header-section .header-top .register-login-group ul li:last-child {
          margin-right: 0px;
          padding-right: 0px; }
          .default-header-section .header-top .register-login-group ul li:last-child:after {
            display: none; }
        .default-header-section .header-top .register-login-group ul li a {
          color: #ffffff;
          font-size: 14px; }
          .default-header-section .header-top .register-login-group ul li a i {
            margin-right: 5px; }
  .default-header-section .header-bottom {
    padding: 30px 0px; }
    .default-header-section .header-bottom .menu-item-list {
      padding: 8px 0px; }
      .default-header-section .header-bottom .menu-item-list > ul {
        float: right;
        display: table; }
        .default-header-section .header-bottom .menu-item-list > ul > li {
          position: relative; }
          .default-header-section .header-bottom .menu-item-list > ul > li:last-child {
            margin-right: 0px; }
          .default-header-section .header-bottom .menu-item-list > ul > li:before {
            left: 0;
            right: 0;
            opacity: 0;
            content: '';
            width: 100%;
            height: 3px;
            bottom: -40px;
            position: absolute;
            -webkit-transform: scale(0.3);
            -ms-transform: scale(0.3);
            transform: scale(0.3);
            background-color: #ffbe30;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
          .default-header-section .header-bottom .menu-item-list > ul > li > a {
            font-weight: 700;
            padding: 5px 15px;
            text-align: center;
            color: #333333;
            font-size: 16px;
            text-transform: uppercase; }
          .default-header-section .header-bottom .menu-item-list > ul > li:hover a {
            color: #ffbe30; }
          .default-header-section .header-bottom .menu-item-list > ul > li:hover:before {
            opacity: 1;
            bottom: -38px;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1); }
        .default-header-section .header-bottom .menu-item-list > ul > .active a {
          color: #ffbe30; }
        .default-header-section .header-bottom .menu-item-list > ul > .active:before {
          opacity: 1;
          bottom: -38px;
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1); }
      .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu {
        left: 50%;
        top: 34px;
        z-index: 1;
        opacity: 0;
        height: auto;
        min-width: 180px;
        visibility: hidden;
        padding-top: 38px;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu:after {
          top: 38px;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          content: '';
          position: absolute;
          -webkit-box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1); }
        .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li {
          width: 100%;
          height: 40px;
          display: block;
          text-align: left;
          font-size: 14px;
          background-color: #333333;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
          .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li:last-child {
            border-bottom: none; }
          .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li > a {
            width: 100%;
            height: 100%;
            display: block;
            padding: 0px 15px;
            line-height: 40px;
            text-align: center;
            position: relative;
            font-size: 14px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.5); }
          .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li .active {
            color: #ffbe30; }
          .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li:hover > a {
            color: #ffbe30; }
      .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children:hover .sub-menu {
        opacity: 1;
        visibility: visible; }
      .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children {
        position: relative; }
        .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu {
          top: 0;
          left: 150%;
          opacity: 0;
          padding-top: 0px;
          visibility: hidden;
          position: absolute; }
          .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li {
            border-left: 1px solid rgba(255, 255, 255, 0.5); }
            .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li a {
              position: relative; }
            .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li:hover a {
              color: #ffbe30; }
        .default-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children:hover > .sub-menu {
          opacity: 1;
          visibility: visible; }
    .default-header-section .header-bottom .user-search-btn-group {
      padding: 5px 0px; }
      .default-header-section .header-bottom .user-search-btn-group ul {
        float: right;
        display: table; }
        .default-header-section .header-bottom .user-search-btn-group ul li {
          width: 40px;
          height: 40px;
          text-align: center;
          margin-right: 10px; }
          .default-header-section .header-bottom .user-search-btn-group ul li:last-child {
            margin-right: 0px; }
          .default-header-section .header-bottom .user-search-btn-group ul li > a,
          .default-header-section .header-bottom .user-search-btn-group ul li > button {
            width: 100%;
            height: 100%;
            display: block;
            line-height: 40px;
            border-radius: 100%;
            color: #333333;
            background-color: #f0f0f0; }
          .default-header-section .header-bottom .user-search-btn-group ul li:hover > a,
          .default-header-section .header-bottom .user-search-btn-group ul li:hover > button {
            color: #ffffff;
            background-color: #ffbe30; }

.auto-hide-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  will-change: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: -webkit-transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s, -webkit-transform .5s; }

.auto-hide-header.is-hidden {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%); }

.sticky-header-section {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  background-color: transparent; }
  .sticky-header-section .header-bottom {
    padding: 30px 0px; }
    .sticky-header-section .header-bottom .menu-item-list {
      padding: 8px 0px; }
      .sticky-header-section .header-bottom .menu-item-list > ul {
        float: right;
        display: table; }
        .sticky-header-section .header-bottom .menu-item-list > ul > li {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-right: 2px;
          position: relative; }
          .sticky-header-section .header-bottom .menu-item-list > ul > li:last-child {
            margin-right: 0px; }
          .sticky-header-section .header-bottom .menu-item-list > ul > li > a {
            z-index: 1;
            font-weight: 700;
            overflow: hidden;
            padding: 5px 12px;
            text-align: center;
            border-radius: 3px;
            position: relative;
            color: #ffffff;
            font-size: 16px;
            text-transform: uppercase; }
            .sticky-header-section .header-bottom .menu-item-list > ul > li > a:before {
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              opacity: 0;
              content: '';
              z-index: -1;
              border-radius: 3px;
              position: absolute;
              background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
              background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
              background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
              background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
              -webkit-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out; }
          .sticky-header-section .header-bottom .menu-item-list > ul > li:hover a {
            color: #ffffff; }
            .sticky-header-section .header-bottom .menu-item-list > ul > li:hover a:before {
              opacity: 1; }
        .sticky-header-section .header-bottom .menu-item-list > ul > .active a {
          color: #ffffff; }
          .sticky-header-section .header-bottom .menu-item-list > ul > .active a:before {
            opacity: 1; }
      .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu {
        left: 50%;
        top: 25px;
        z-index: 1;
        opacity: 0;
        height: auto;
        min-width: 180px;
        padding-top: 10px;
        visibility: hidden;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu:after {
          top: 55px;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          content: '';
          position: absolute;
          -webkit-box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1); }
        .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li {
          width: 100%;
          height: 40px;
          display: block;
          text-align: left;
          position: relative;
          font-size: 14px;
          background-color: #333333;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
          .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li:last-child {
            border-bottom: none; }
          .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li > a {
            width: 100%;
            height: 100%;
            display: block;
            padding: 0px 15px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.5); }
          .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li .active {
            color: #ffbe30; }
          .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li:hover > a {
            color: #ffbe30; }
        .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > .active > a {
          color: #ffbe30; }
      .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children:hover .sub-menu {
        opacity: 1;
        visibility: visible; }
      .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children {
        position: relative; }
        .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu {
          top: 0;
          left: 150%;
          opacity: 0;
          padding-top: 0px;
          visibility: hidden;
          position: absolute; }
          .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li {
            border-left: 1px solid rgba(255, 255, 255, 0.5); }
            .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li:hover a {
              color: #ffbe30; }
        .sticky-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children:hover > .sub-menu {
          opacity: 1;
          visibility: visible; }
    .sticky-header-section .header-bottom .user-search-btn-group {
      padding: 5px 0px; }
      .sticky-header-section .header-bottom .user-search-btn-group ul {
        float: right;
        display: table; }
        .sticky-header-section .header-bottom .user-search-btn-group ul li {
          width: 40px;
          height: 40px;
          text-align: center;
          margin-right: 10px; }
          .sticky-header-section .header-bottom .user-search-btn-group ul li:last-child {
            margin-right: 0px; }
          .sticky-header-section .header-bottom .user-search-btn-group ul li > a,
          .sticky-header-section .header-bottom .user-search-btn-group ul li > button {
            width: 100%;
            height: 100%;
            display: block;
            line-height: 36px;
            border-radius: 100%;
            color: #ffffff;
            border: 3px solid #ffbe30; }
          .sticky-header-section .header-bottom .user-search-btn-group ul li:hover > a,
          .sticky-header-section .header-bottom .user-search-btn-group ul li:hover > button {
            color: #ffffff;
            background-color: #ffbe30; }

.sticky-header-section.stuck {
  background-color: #333333;
  -webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1); }
  .sticky-header-section.stuck .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu {
    padding-top: 47px; }

.scrolltop-fixed-header-section {
  left: 0;
  right: 0;
  top: 30px;
  z-index: 999;
  position: fixed;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .scrolltop-fixed-header-section .header-bottom {
    padding: 30px;
    border-radius: 3px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.2); }
    .scrolltop-fixed-header-section .header-bottom .menu-item-list {
      padding: 8px 0px; }
      .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul {
        float: right;
        display: table; }
        .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > li {
          position: relative; }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > li > a {
            font-weight: 700;
            padding: 5px 15px;
            text-align: center;
            color: #333333;
            font-size: 16px;
            text-transform: uppercase; }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > li:hover a {
            color: #ffbe30; }
        .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .active a {
          color: #ffbe30; }
      .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu {
        left: 50%;
        top: 34px;
        z-index: 1;
        opacity: 0;
        height: auto;
        min-width: 180px;
        padding-top: 38px;
        visibility: hidden;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu:after {
          top: 38px;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          content: '';
          position: absolute;
          -webkit-box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1); }
        .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li {
          width: 100%;
          height: 40px;
          display: block;
          text-align: left;
          position: relative;
          font-size: 14px;
          background-color: #333333;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li:last-child {
            border-bottom: none; }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li > a {
            width: 100%;
            height: 100%;
            display: block;
            padding: 0px 15px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.5); }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li .active {
            color: #ffbe30; }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children .sub-menu > li:hover > a {
            color: #ffbe30; }
      .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children:hover .sub-menu {
        opacity: 1;
        visibility: visible; }
      .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children {
        position: relative; }
        .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu {
          top: 0;
          left: 150%;
          opacity: 0;
          padding-top: 0px;
          visibility: hidden;
          position: absolute; }
          .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li {
            border-left: 1px solid rgba(255, 255, 255, 0.5); }
            .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > li:hover a {
              color: #ffbe30; }
        .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > .menu-item-has-children > .sub-menu > .menu-item-has-children:hover > .sub-menu {
          opacity: 1;
          visibility: visible; }
    .scrolltop-fixed-header-section .header-bottom .user-search-btn-group {
      padding: 5px 0px; }
      .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul {
        float: right;
        display: table; }
        .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul li {
          width: 40px;
          height: 40px;
          text-align: center;
          margin-right: 10px; }
          .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul li:last-child {
            margin-right: 0px; }
          .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul li > a,
          .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul li > button {
            width: 100%;
            height: 100%;
            display: block;
            line-height: 36px;
            border-radius: 100%;
            color: #333333;
            border: 3px solid #ffbe30; }
          .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul li:hover > a,
          .scrolltop-fixed-header-section .header-bottom .user-search-btn-group ul li:hover > button {
            color: #ffffff;
            background-color: #ffbe30; }

.scrolltop-fixed-header-section.stuck {
  top: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1); }
  .scrolltop-fixed-header-section.stuck .header-bottom {
    -webkit-box-shadow: none;
    box-shadow: none; }

.reglog-modal-wrapper {
  height: auto;
  z-index: 1000;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1170px;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.1); }
  .reglog-modal-wrapper .overlay-black {
    position: relative;
    background-color: rgba(51, 51, 51, 0.8); }
  .reglog-modal-wrapper .popup-modal-dismiss {
    top: 10px;
    z-index: 1;
    right: 12px;
    line-height: 0px;
    text-align: right;
    position: absolute;
    color: #ffffff;
    font-size: 30px; }
    .reglog-modal-wrapper .popup-modal-dismiss:before {
      top: -81px;
      right: -83px;
      width: 0px;
      height: 0px;
      content: '';
      z-index: -1;
      position: absolute;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-top: 70px solid transparent;
      border-left: 70px solid #ffbe30;
      border-right: 70px solid transparent;
      border-bottom: 70px solid transparent; }
  .reglog-modal-wrapper .leftside-content {
    width: 60%;
    float: left;
    height: 600px;
    padding: 50px 30px;
    text-align: center;
    position: relative; }
    .reglog-modal-wrapper .leftside-content .register-login-link ul {
      width: 100%;
      margin: 0px;
      padding: 0px;
      display: table; }
      .reglog-modal-wrapper .leftside-content .register-login-link ul li {
        width: 100%;
        display: table;
        list-style: none;
        text-align: center;
        margin-bottom: 5px; }
        .reglog-modal-wrapper .leftside-content .register-login-link ul li:last-child {
          margin-bottom: 0px; }
        .reglog-modal-wrapper .leftside-content .register-login-link ul li a {
          font-weight: 700;
          color: #878787;
          text-transform: uppercase; }
          .reglog-modal-wrapper .leftside-content .register-login-link ul li a:hover {
            color: #ffbe30; }
      .reglog-modal-wrapper .leftside-content .register-login-link ul li.active a {
        color: #ffffff; }
    .reglog-modal-wrapper .leftside-content .copyright-text {
      left: 50%;
      width: 100%;
      bottom: 50px;
      position: absolute;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
      .reglog-modal-wrapper .leftside-content .copyright-text .fa-heart {
        color: #ff5a26; }
  .reglog-modal-wrapper .rightside-content {
    width: 40%;
    float: right;
    height: 600px;
    padding: 50px 30px;
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
    .reglog-modal-wrapper .rightside-content .fb-login-btn {
      overflow: hidden;
      border-radius: 30px; }
      .reglog-modal-wrapper .rightside-content .fb-login-btn a {
        width: 100%;
        height: 100%;
        display: table;
        font-weight: 700;
        padding: 12px 0px;
        text-align: center;
        color: #ffffff;
        background-color: #2852ab;
        text-transform: capitalize; }
        .reglog-modal-wrapper .rightside-content .fb-login-btn a .icon {
          width: 60px;
          float: left;
          height: 50px;
          line-height: 50px;
          margin: -12px 0px;
          text-align: center;
          color: #ffffff;
          font-size: 18px;
          border-right: 1px solid #ffffff; }
    .reglog-modal-wrapper .rightside-content .or-text {
      text-align: center; }
      .reglog-modal-wrapper .rightside-content .or-text span {
        padding: 0px 20px;
        position: relative;
        color: #ffffff;
        text-transform: uppercase; }
        .reglog-modal-wrapper .rightside-content .or-text span:after, .reglog-modal-wrapper .rightside-content .or-text span:before {
          top: 50%;
          content: '';
          height: 2px;
          width: 130px;
          position: absolute;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: #ffffff; }
        .reglog-modal-wrapper .rightside-content .or-text span:after {
          right: -100%; }
        .reglog-modal-wrapper .rightside-content .or-text span:before {
          left: -100%; }
    .reglog-modal-wrapper .rightside-content .login-form .form-item input {
      width: 100%;
      border: none;
      height: 50px;
      display: block;
      padding: 0px 30px;
      margin-bottom: 10px;
      border-radius: 30px;
      background-color: #ffffff; }
      .reglog-modal-wrapper .rightside-content .login-form .form-item input:focus {
        outline: none; }
    .reglog-modal-wrapper .rightside-content .login-form .login-btn {
      width: 100%;
      height: 50px;
      font-weight: 700;
      padding: 0px 30px;
      text-align: center;
      color: #ffffff;
      border-radius: 30px;
      text-transform: uppercase;
      background-color: #ffbe30; }

.register-modal .leftside-content,
.register-modal .rightside-content {
  height: 650px; }

.register-modal .rightside-content .human-verification {
  height: 50px;
  padding: 0px 30px;
  line-height: 50px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 30px;
  background-color: #ffffff; }
  .register-modal .rightside-content .human-verification input {
    opacity: 1;
    position: relative; }
    .register-modal .rightside-content .human-verification input:before {
      top: 50%;
      left: 0px;
      width: 21px;
      content: '';
      height: 21px;
      position: absolute;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: #ffffff;
      border: 2px solid #ffbe30;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  .register-modal .rightside-content .human-verification label {
    margin: 0px;
    cursor: pointer;
    padding-left: 15px;
    color: #333333;
    position: relative; }
    .register-modal .rightside-content .human-verification label:after {
      top: 50%;
      left: -15px;
      z-index: 1;
      opacity: 0;
      content: '\f14a';
      font-weight: 900;
      position: absolute;
      color: #ffbe30;
      font-size: 14px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      font-family: 'Font Awesome 5 Free'; }
  .register-modal .rightside-content .human-verification input[type=checkbox]:checked + label:after {
    opacity: 1; }
  .register-modal .rightside-content .human-verification .verification-image {
    top: 50%;
    right: 30px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.search-body {
  top: 80px;
  opacity: 0;
  right: 15px;
  width: 300px;
  height: auto;
  z-index: 999;
  padding: 15px;
  position: absolute;
  visibility: hidden;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1); }
  .search-body .search-form {
    width: 100%;
    height: auto;
    position: relative; }
    .search-body .search-form .search-input {
      width: 100%;
      height: 50px;
      padding: 0px 20px;
      border-radius: 3px;
      border: 1px solid #f0f0f0;
      background-color: #f7f7f7; }
      .search-body .search-form .search-input:focus {
        outline: none;
        border-color: #ffbe30; }
    .search-body .search-form .search-close {
      top: 50%;
      z-index: 1;
      right: 5px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      position: absolute;
      border-radius: 3px;
      color: #ffffff;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: #ffbe30; }

.search-open {
  opacity: 1;
  visibility: visible; }

/* header section - end
==================================================================================================== */
/* altranative header - start
==================================================================================================== */
.header-altranative {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  display: none;
  position: fixed;
  padding: 30px 0px;
  background-color: #333333; }
  .header-altranative .logo-area {
    max-width: 150px; }
    .header-altranative .logo-area a {
      width: 100%;
      height: 100%;
      display: block; }
  .header-altranative .alt-menu-btn {
    padding: 0px;
    line-height: normal;
    color: #ffbe30;
    font-size: 30px; }

.sidebar-menu-wrapper {
  display: block;
  position: relative; }
  .sidebar-menu-wrapper .sidebar {
    top: 0;
    bottom: 0;
    left: -300px;
    height: 100%;
    z-index: 9999;
    position: fixed;
    min-width: 300px;
    max-width: 300px;
    overflow-y: scroll;
    padding-bottom: 50px;
    font-size: 14px;
    background-color: #151515;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .sidebar-menu-wrapper .sidebar .overlay {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100vw;
      height: 100vh;
      display: none;
      position: fixed;
      background: rgba(0, 0, 0, 0.8); }
    .sidebar-menu-wrapper .sidebar #sidebar-dismiss {
      top: 5px;
      right: 5px;
      width: 40px;
      height: 40px;
      color: #ffc107;
      font-size: 18px;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      border-radius: 3px;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.1); }
    .sidebar-menu-wrapper .sidebar .sidebar-header {
      padding: 80px 0px;
      text-align: center; }
    .sidebar-menu-wrapper .sidebar .sidebar-form {
      width: 100%;
      height: 50px;
      display: table;
      padding: 0px 15px;
      position: relative;
      margin-bottom: 30px; }
      .sidebar-menu-wrapper .sidebar .sidebar-form input {
        width: 100%;
        height: 50px;
        display: block;
        padding: 0px 20px;
        border-radius: 3px;
        color: #ffffff;
        border: 1px solid #000000;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: rgba(255, 255, 255, 0.05); }
        .sidebar-menu-wrapper .sidebar .sidebar-form input:focus {
          outline: none;
          border-color: #ffbe30; }
          .sidebar-menu-wrapper .sidebar .sidebar-form input:focus + label {
            color: #ffbe30; }
      .sidebar-menu-wrapper .sidebar .sidebar-form label {
        top: 1px;
        z-index: 1;
        right: 16px;
        bottom: 1px;
        margin: 0px;
        min-width: 50px;
        line-height: 48px;
        border-radius: 3px;
        text-align: center;
        position: absolute;
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
    .sidebar-menu-wrapper .sidebar .menu-title {
      margin: 0px;
      padding: 15px;
      font-weight: 700;
      color: #333333;
      font-size: 16px;
      text-transform: uppercase; }
    .sidebar-menu-wrapper .sidebar .menu-link-list {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #333333; }
      .sidebar-menu-wrapper .sidebar .menu-link-list ul {
        width: 100%;
        margin: 0px;
        padding: 0px;
        display: table; }
        .sidebar-menu-wrapper .sidebar .menu-link-list ul li {
          width: 100%;
          height: 45px;
          display: block; }
          .sidebar-menu-wrapper .sidebar .menu-link-list ul li a {
            width: 100%;
            height: 100%;
            display: block;
            font-weight: 400;
            line-height: 45px;
            padding: 0px 30px;
            color: #ffffff;
            font-size: 14px;
            text-transform: capitalize; }
            .sidebar-menu-wrapper .sidebar .menu-link-list ul li a .icon {
              width: 30px;
              height: 30px;
              font-size: 12px;
              margin-right: 10px;
              line-height: 30px;
              text-align: center;
              border-radius: 100%;
              color: #ffffff;
              background-color: #ffbe30; }
          .sidebar-menu-wrapper .sidebar .menu-link-list ul li:hover a {
            background-color: rgba(51, 51, 51, 0.5); }
        .sidebar-menu-wrapper .sidebar .menu-link-list ul .active a {
          background-color: rgba(51, 51, 51, 0.5); }
    .sidebar-menu-wrapper .sidebar .login-btn-group {
      padding: 15px 0px; }
      .sidebar-menu-wrapper .sidebar .login-btn-group ul {
        width: 100%;
        margin: 0px;
        padding: 0px;
        display: table; }
        .sidebar-menu-wrapper .sidebar .login-btn-group ul li {
          width: 50%;
          float: left;
          text-align: center;
          display: inline-block;
          border-right: 1px solid #333333; }
          .sidebar-menu-wrapper .sidebar .login-btn-group ul li:last-child {
            border-right: none; }
          .sidebar-menu-wrapper .sidebar .login-btn-group ul li a {
            font-weight: 700;
            padding: 0px 15px;
            color: #ffbe30;
            font-size: 14px;
            text-transform: uppercase; }
    .sidebar-menu-wrapper .sidebar .social-links {
      padding: 15px 0px;
      margin-bottom: 15px; }
      .sidebar-menu-wrapper .sidebar .social-links .contact-info {
        font-weight: 400;
        padding: 0px 15px;
        margin-bottom: 10px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5); }
        .sidebar-menu-wrapper .sidebar .social-links .contact-info:last-child {
          margin-bottom: 0px; }
        .sidebar-menu-wrapper .sidebar .social-links .contact-info i {
          margin-right: 10px;
          color: #ffbe30; }
      .sidebar-menu-wrapper .sidebar .social-links ul {
        margin: 0px;
        display: table;
        padding: 0px 15px;
        margin-bottom: 15px; }
        .sidebar-menu-wrapper .sidebar .social-links ul li {
          float: left;
          width: 35px;
          height: 35px;
          list-style: none;
          overflow: hidden;
          margin-right: 5px;
          text-align: center;
          border-radius: 100%;
          display: inline-block; }
          .sidebar-menu-wrapper .sidebar .social-links ul li:last-child {
            margin-right: 0px; }
          .sidebar-menu-wrapper .sidebar .social-links ul li a {
            width: 100%;
            height: 100%;
            line-height: 35px;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            background-color: rgba(255, 255, 255, 0.1); }
          .sidebar-menu-wrapper .sidebar .social-links ul li:hover a {
            color: #ffffff;
            background-color: #ffbe30; }
      .sidebar-menu-wrapper .sidebar .social-links .contact-btn {
        height: 40px;
        margin: 0px 15px;
        padding: 0px 30px;
        line-height: 40px;
        text-align: center;
        border-radius: 30px;
        color: #ffbe30;
        font-size: 14px;
        text-transform: uppercase;
        background-color: #333333; }
        .sidebar-menu-wrapper .sidebar .social-links .contact-btn:hover {
          color: #ffffff;
          background-color: #ffbe30; }
  .sidebar-menu-wrapper .sidebar.active {
    left: 0px; }
    .sidebar-menu-wrapper .sidebar.active .overlay {
      display: block; }

/* altranative header - end
==================================================================================================== */
/* default footer section - start
==================================================================================================== */
.default-footer-section .footer-item-title {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 24px; }
  .default-footer-section .footer-item-title strong {
    font-weight: 900; }

.default-footer-section .footer-top {
  background-color: #333333; }
  .default-footer-section .footer-top .basic-info .basic-info-item {
    width: 100%;
    height: 80px;
    display: table;
    background-color: #2e2e2e; }
    .default-footer-section .footer-top .basic-info .basic-info-item .icon {
      float: left;
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin-right: 15px;
      text-align: center;
      color: #ffffff;
      font-size: 24px;
      background-color: #3b3b3b; }
    .default-footer-section .footer-top .basic-info .basic-info-item .info-content {
      display: table;
      padding: 12px 15px; }
      .default-footer-section .footer-top .basic-info .basic-info-item .info-content p {
        margin-bottom: 5px;
        color: #ffffff;
        font-size: 14px; }
        .default-footer-section .footer-top .basic-info .basic-info-item .info-content p a {
          color: #ffffff; }
      .default-footer-section .footer-top .basic-info .basic-info-item .info-content h3 {
        font-weight: 700;
        color: #ffbe30;
        font-size: 18px; }
        .default-footer-section .footer-top .basic-info .basic-info-item .info-content h3 a {
          color: #ffbe30; }
  .default-footer-section .footer-top .about-wrapper .social-links .social-title {
    font-weight: 700;
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 24px;
    text-transform: capitalize; }
  .default-footer-section .footer-top .about-wrapper .social-links ul li {
    margin-right: 15px; }
    .default-footer-section .footer-top .about-wrapper .social-links ul li:last-child {
      margin-right: 0px; }
    .default-footer-section .footer-top .about-wrapper .social-links ul li a {
      color: #878787;
      font-size: 18px; }
      .default-footer-section .footer-top .about-wrapper .social-links ul li a:hover {
        color: #ffbe30; }
  .default-footer-section .footer-top .usefullinks-wrapper {
    padding: 0px 30px; }
    .default-footer-section .footer-top .usefullinks-wrapper ul li a {
      width: 100%;
      height: 100%;
      display: block;
      padding: 8px 0;
      padding-left: 30px;
      position: relative;
      color: #878787;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      .default-footer-section .footer-top .usefullinks-wrapper ul li a:before {
        top: 50%;
        left: 0px;
        content: '\f0a9';
        font-weight: 900;
        position: absolute;
        color: #878787;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free'; }
    .default-footer-section .footer-top .usefullinks-wrapper ul li:last-child a {
      border-bottom: none; }
    .default-footer-section .footer-top .usefullinks-wrapper ul li:hover a {
      padding-left: 40px;
      color: #ffbe30; }
      .default-footer-section .footer-top .usefullinks-wrapper ul li:hover a:before {
        left: 15px;
        color: #ffbe30; }
  .default-footer-section .footer-top .instagram-wrapper ul {
    width: 100%;
    margin: -5px;
    display: table;
    margin-bottom: 30px; }
    .default-footer-section .footer-top .instagram-wrapper ul li {
      margin: 5px;
      width: 110px;
      height: 100px; }
      .default-footer-section .footer-top .instagram-wrapper ul li a {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        line-height: 100px;
        text-align: center;
        position: absolute;
        color: #ffffff;
        font-size: 24px;
        background: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 62, 0, 0.9)), to(rgba(255, 190, 48, 0.9)));
        background: -webkit-linear-gradient(top left, rgba(255, 62, 0, 0.9), rgba(255, 190, 48, 0.9));
        background: -o-linear-gradient(top left, rgba(255, 62, 0, 0.9), rgba(255, 190, 48, 0.9));
        background: linear-gradient(to bottom right, rgba(255, 62, 0, 0.9), rgba(255, 190, 48, 0.9)); }
      .default-footer-section .footer-top .instagram-wrapper ul li:hover a {
        opacity: 1; }
  .default-footer-section .footer-top .instagram-wrapper .followus-link {
    font-weight: 700;
    color: #878787;
    font-size: 16px; }
    .default-footer-section .footer-top .instagram-wrapper .followus-link a {
      color: #ffbe30; }

/* default footer section - end
==================================================================================================== */
/* footer section 2 - start
==================================================================================================== */
.footer-section2 .footer-item-title {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 24px; }
  .footer-section2 .footer-item-title strong {
    font-weight: 900; }

.footer-section2 .footer-top {
  background-color: #333333; }
  .footer-section2 .footer-top .about-wrapper .basic-info ul li {
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 14px; }
    .footer-section2 .footer-top .about-wrapper .basic-info ul li:last-child {
      margin-bottom: 0px; }
    .footer-section2 .footer-top .about-wrapper .basic-info ul li i {
      margin-right: 5px;
      color: #ffbe30; }
    .footer-section2 .footer-top .about-wrapper .basic-info ul li a {
      color: #ffffff; }
  .footer-section2 .footer-top .about-wrapper .social-links .social-title {
    font-weight: 700;
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 24px;
    text-transform: capitalize; }
  .footer-section2 .footer-top .about-wrapper .social-links ul li {
    margin-right: 15px; }
    .footer-section2 .footer-top .about-wrapper .social-links ul li:last-child {
      margin-right: 0px; }
    .footer-section2 .footer-top .about-wrapper .social-links ul li a {
      color: #878787;
      font-size: 18px; }
      .footer-section2 .footer-top .about-wrapper .social-links ul li a:hover {
        color: #ffbe30; }
  .footer-section2 .footer-top .usefullinks-wrapper {
    padding: 0px 30px; }
    .footer-section2 .footer-top .usefullinks-wrapper ul li a {
      width: 100%;
      height: 100%;
      display: block;
      padding: 8px 0;
      padding-left: 30px;
      position: relative;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
 /* .usefullinks-wrapper ul li a:before {
        top: 50%;
        left: 0px;
        content: '\f0a9';
        font-weight: 900;
        position: absolute;
        color: #878787;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free'; } */
    .footer-section2 .footer-top .usefullinks-wrapper ul li:last-child a {
      border-bottom: none; }
    .footer-section2 .footer-top .usefullinks-wrapper ul li:hover a {
      padding-left: 40px;
      color: #ffbe30; }
      .footer-section2 .footer-top .usefullinks-wrapper ul li:hover a:before {
        left: 15px;
        color: #ffbe30; }
  .footer-section2 .footer-top .instagram-wrapper ul {
    width: 100%;
    margin: -5px;
    display: table;
    margin-bottom: 30px; }
    .footer-section2 .footer-top .instagram-wrapper ul li {
      margin: 5px;
      width: 110px;
      height: 100px; }
      .footer-section2 .footer-top .instagram-wrapper ul li a {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        line-height: 100px;
        text-align: center;
        position: absolute;
        color: #ffffff;
        font-size: 24px;
        background: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 62, 0, 0.9)), to(rgba(255, 190, 48, 0.9)));
        background: -webkit-linear-gradient(top left, rgba(255, 62, 0, 0.9), rgba(255, 190, 48, 0.9));
        background: -o-linear-gradient(top left, rgba(255, 62, 0, 0.9), rgba(255, 190, 48, 0.9));
        background: linear-gradient(to bottom right, rgba(255, 62, 0, 0.9), rgba(255, 190, 48, 0.9)); }
      .footer-section2 .footer-top .instagram-wrapper ul li:hover a {
        opacity: 1; }
  .footer-section2 .footer-top .instagram-wrapper .followus-link {
    font-weight: 700;
    color: #878787;
    font-size: 16px; }
    .footer-section2 .footer-top .instagram-wrapper .followus-link a {
      color: #ffbe30; }

/* footer section 2 - end
==================================================================================================== */
/* footer section 3 - start
==================================================================================================== */
.footer-section3 .footer-item-title {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 24px; }
  .footer-section3 .footer-item-title strong {
    font-weight: 900; }

.footer-section3 .footer-top {
  background-color: #333333; }
  .footer-section3 .footer-top .basic-info .basic-info-item {
    width: 100%;
    height: 80px;
    display: table;
    background-color: #2e2e2e; }
    .footer-section3 .footer-top .basic-info .basic-info-item .icon {
      float: left;
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin-right: 15px;
      text-align: center;
      color: #ffffff;
      font-size: 24px;
      background-color: #3b3b3b; }
    .footer-section3 .footer-top .basic-info .basic-info-item .info-content {
      display: table;
      padding: 12px 15px; }
      .footer-section3 .footer-top .basic-info .basic-info-item .info-content p {
        margin-bottom: 5px;
        color: #ffffff;
        font-size: 14px; }
        .footer-section3 .footer-top .basic-info .basic-info-item .info-content p a {
          color: #ffffff; }
      .footer-section3 .footer-top .basic-info .basic-info-item .info-content h3 {
        font-weight: 700;
        color: #ffbe30;
        font-size: 18px; }
        .footer-section3 .footer-top .basic-info .basic-info-item .info-content h3 a {
          color: #ffbe30; }
  .footer-section3 .footer-top .about-wrapper .social-links .social-title {
    font-weight: 700;
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 24px;
    text-transform: capitalize; }
  .footer-section3 .footer-top .about-wrapper .social-links ul {
    display: table;
    margin: 0 auto; }
    .footer-section3 .footer-top .about-wrapper .social-links ul li {
      margin-right: 15px; }
      .footer-section3 .footer-top .about-wrapper .social-links ul li:last-child {
        margin-right: 0px; }
      .footer-section3 .footer-top .about-wrapper .social-links ul li a {
        color: #878787;
        font-size: 18px; }
        .footer-section3 .footer-top .about-wrapper .social-links ul li a:hover {
          color: #ffbe30; }

/* footer section 3 - end
==================================================================================================== */
/* footer-bottom - end
==================================================================================================== */
.footer-bottom {
  padding: 30px 0px;
  background-color: #2e2e2e; }
  .footer-bottom .copyright-text {
    color: #878787; }
    .footer-bottom .copyright-text .site-link {
      color: #ffbe30; }
    .footer-bottom .copyright-text .fa-heart {
      color: #ff5a26; }
    .footer-bottom .copyright-text .author-link {
      color: #878787; }
      .footer-bottom .copyright-text .author-link:hover {
        color: #ffbe30; }
  .footer-bottom .footer-menu ul {
    margin: 0px;
    padding: 0px;
    float: right;
    display: table; }
    .footer-bottom .footer-menu ul li {
      float: left;
      margin-right: 40px;
      display: inline-block; }
      .footer-bottom .footer-menu ul li:last-child {
        margin-right: 0px; }
      .footer-bottom .footer-menu ul li a {
        color: #878787;
        text-transform: capitalize; }
        .footer-bottom .footer-menu ul li a:hover {
          color: #ffbe30; }

/* footer-bottom - end
==================================================================================================== */
/* map section - start
==================================================================================================== */
.map-section {
  position: relative; }
  .map-section .address-wrapper {
    top: 50%;
    z-index: 1;
    left: 6.666%;
    padding: 30px;
    max-width: 520px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
    -webkit-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2); }
    .map-section .address-wrapper p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6); }
    .map-section .address-wrapper .address-info-topbar .address-info-left {
      width: 60%;
      float: left; }
      .map-section .address-wrapper .address-info-topbar .address-info-left .title-text {
        font-weight: 900;
        color: #ffffff;
        font-size: 18px; }
    .map-section .address-wrapper .address-info-topbar .address-info-right {
      width: 40%;
      float: right; }
      .map-section .address-wrapper .address-info-topbar .address-info-right ul {
        padding: 0px;
        display: table;
        margin: 0px auto; }
        .map-section .address-wrapper .address-info-topbar .address-info-right ul li {
          float: left;
          width: 80px;
          height: 80px;
          list-style: none;
          margin-right: 10px;
          display: inline-block; }
          .map-section .address-wrapper .address-info-topbar .address-info-right ul li:last-child {
            margin-right: 0px; }
          .map-section .address-wrapper .address-info-topbar .address-info-right ul li button {
            width: 100%;
            height: 100%;
            padding: 10px;
            display: block;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.1); }
            .map-section .address-wrapper .address-info-topbar .address-info-right ul li button .icon {
              color: #ffffff;
              font-size: 24px; }
            .map-section .address-wrapper .address-info-topbar .address-info-right ul li button small {
              width: 100%;
              display: block;
              color: #ffffff; }
    .map-section .address-wrapper .address-info-bottombar .address-info-left {
      width: 60%;
      float: left; }
      .map-section .address-wrapper .address-info-bottombar .address-info-left .rating-star .rating-point {
        float: left;
        font-weight: 700;
        margin-right: 5px;
        color: #ffffff;
        font-size: 18px; }
      .map-section .address-wrapper .address-info-bottombar .address-info-left .rating-star ul {
        margin: 0px;
        padding: 0px;
        display: table; }
        .map-section .address-wrapper .address-info-bottombar .address-info-left .rating-star ul li {
          float: left;
          list-style: none;
          margin-right: 2px;
          color: #ffffff;
          display: inline-block; }
    .map-section .address-wrapper .address-info-bottombar .address-info-right {
      width: 40%;
      float: right; }
      .map-section .address-wrapper .address-info-bottombar .address-info-right .map-larger-btn {
        width: 100%;
        height: 50px;
        display: block;
        font-weight: 700;
        line-height: 50px;
        padding: 0px 30px;
        border-radius: 30px;
        color: #ffbe30;
        font-size: 14px;
        text-transform: uppercase;
        background-color: #ffffff; }
        .map-section .address-wrapper .address-info-bottombar .address-info-right .map-larger-btn:hover {
          color: #ffffff;
          background-color: #ffbe30; }
  .map-section .find-event-form {
    top: 50%;
    z-index: 1;
    left: 6.666%;
    padding: 30px;
    max-width: 330px;
    border-radius: 3px;
    position: absolute;
    background: #ffffff;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2); }
    .map-section .find-event-form .from-title .title-text {
      font-weight: 400;
      font-size: 30px; }
      .map-section .find-event-form .from-title .title-text strong {
        font-weight: 900; }
    .map-section .find-event-form .from-title .sub-title {
      font-weight: 700;
      color: #ffbe30; }
    .map-section .find-event-form .form-wrapper .form-item {
      margin-bottom: 10px; }
      .map-section .find-event-form .form-wrapper .form-item:last-child {
        margin-bottom: 0px; }
      .map-section .find-event-form .form-wrapper .form-item select {
        width: 100%;
        height: 100%;
        display: block;
        font-weight: 700;
        padding: 0px 20px;
        border-radius: 3px;
        color: #333333;
        -webkit-appearance: none;
        text-transform: capitalize;
        border: 1px solid #f0f0f0;}
      
        .map-section .find-event-form .form-wrapper .form-item select:focus {
          outline: none;
          border-color: #ffbe30; }
      .map-section .find-event-form .form-wrapper .form-item .custom-btn {
        width: 100%;
        display: block;
        border-radius: 3px; }
        .map-section .find-event-form .form-wrapper .form-item .custom-btn:after, .map-section .find-event-form .form-wrapper .form-item .custom-btn:before {
          border-radius: 3px; }
  .map-section #google-map {
    width: 100%;
    height: 400px; }
    .map-section #google-map > div + div {
      display: none; }

.event-location-map #google-map {
  width: 100%;
  height: 900px; }

/* map section - end
==================================================================================================== */
/* breadcrumb section - start
==================================================================================================== */
.breadcrumb-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .breadcrumb-section .overlay-black {
    padding: 80px 0px; }
  .breadcrumb-section .breadcrumb-title {
    padding: 0px 70px; }
    .breadcrumb-section .breadcrumb-title .sub-title {
      color: #ffffff;
      margin-bottom: 15px;
      letter-spacing: 8px;
      font-size: 18px;
      text-transform: uppercase; }
    .breadcrumb-section .breadcrumb-title .big-title {
      margin: 0px;
      font-size: 42px;
      color: #ffffff;
       }
      .breadcrumb-section .breadcrumb-title .big-title strong {
        font-weight: 600;
        color: white;
      font-size: 25px; }
  .breadcrumb-section .breadcrumb-list ul {
    padding: 0px;
    margin: 0 auto;
    display: table; }
    .breadcrumb-section .breadcrumb-list ul .breadcrumb-item {
      float: left;
      list-style: none;
      position: relative;
      margin-right: 30px;
      padding-right: 30px;
      color: #ffffff;
      display: inline-block;
      text-transform: capitalize; }
      .breadcrumb-section .breadcrumb-list ul .breadcrumb-item:before {
        top: 0;
        bottom: 0;
        right: -5px;
        margin: 0px;
        padding: 0px;
        content: '|';
        font-weight: 700;
        position: absolute;
        color: #ffffff; }
      .breadcrumb-section .breadcrumb-list ul .breadcrumb-item:last-child {
        margin-right: 0px;
        padding-right: 0px; }
        .breadcrumb-section .breadcrumb-list ul .breadcrumb-item:last-child:before {
          display: none; }
      .breadcrumb-section .breadcrumb-list ul .breadcrumb-item .breadcrumb-link {
        font-weight: 700;
        color: #ffffff;
        text-transform: capitalize; }
        .breadcrumb-section .breadcrumb-list ul .breadcrumb-item .breadcrumb-link:hover {
          color: #ffbe30; }

/* breadcrumb section - end
==================================================================================================== */
/* sidebar section - start
==================================================================================================== */
.sidebar-section .section-title .big-title {
  font-size: 30px; }

.sidebar-section .tickets-details-btn {
  padding: 4px 15px !important; }

.sidebar-section .map-wrapper #google-map {
  width: 100%;
  height: 300px; }

.sidebar-section .location-wrapper {
  border-radius: 3px;
  background-color: #f7f7f7; }
  .sidebar-section .location-wrapper .title-wrapper {
    padding: 30px;
    position: relative;
    border-bottom: 1px solid #f0f0f0; }
    .sidebar-section .location-wrapper .title-wrapper .up-arrow-icon {
      top: 50%;
      right: 30px;
      position: absolute;
      color: #878787;
      font-size: 18px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .sidebar-section .location-wrapper .title-wrapper .icon {
      float: left;
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin-right: 15px;
      text-align: center;
      color: #ffffff;
      border-radius: 100%;
      font-size: 18px;
      background-color: #ffbe30; }
    .sidebar-section .location-wrapper .title-wrapper .title-content {
      display: table; }
      .sidebar-section .location-wrapper .title-wrapper .title-content small {
        font-weight: 600;
        font-size: 14px; }
      .sidebar-section .location-wrapper .title-wrapper .title-content h3 {
        font-weight: 900;
        font-size: 18px;
        text-transform: uppercase; }
  .sidebar-section .location-wrapper .location-info-list {
    padding: 30px; }
    .sidebar-section .location-wrapper .location-info-list .area-name {
      font-weight: 700;
      padding: 10px 30px;
      border-radius: 3px;
      text-align: center;
      color: #ffbe30;
      text-transform: capitalize;
      background-color: #ffffff; }
    .sidebar-section .location-wrapper .location-info-list ul {
      width: 100%;
      display: table;
      margin-top: 30px; }
      .sidebar-section .location-wrapper .location-info-list ul li {
        margin-bottom: 5px;
        color: #333333;
        font-size: 14px; }
        .sidebar-section .location-wrapper .location-info-list ul li:last-child {
          margin-bottom: 0px; }
        .sidebar-section .location-wrapper .location-info-list ul li i {
          color: #ffbe30; }
  .sidebar-section .location-wrapper .button {
    padding: 30px;
    padding-top: 0px;
    border-bottom: 1px solid #f0f0f0; }
  .sidebar-section .location-wrapper .custom-btn {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .sidebar-section .location-wrapper .contact-links {
    padding: 30px; }
    .sidebar-section .location-wrapper .contact-links ul {
      width: 100%;
      display: table;
      margin-bottom: 30px; }
      .sidebar-section .location-wrapper .contact-links ul li {
        margin-bottom: 5px; }
        .sidebar-section .location-wrapper .contact-links ul li:last-child {
          margin-bottom: 0px; }
        .sidebar-section .location-wrapper .contact-links ul li a {
          font-weight: 700;
          color: #333333; }
          .sidebar-section .location-wrapper .contact-links ul li a .icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin-right: 5px;
            text-align: center;
            border-radius: 100%;
            color: #ffffff;
            background-color: #ffbe30; }

.sidebar-section .faq-wrapper .faq-accordion .card {
  border: none;
  text-align: left;
  margin-bottom: 5px;
  border-radius: 3px; }
  .sidebar-section .faq-wrapper .faq-accordion .card .card-header {
    margin: 0px;
    padding: 0px;
    border: none;
    background: none; }
    .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn {
      margin: 0px;
      width: 100%;
      height: 100%;
      display: block;
      text-align: left;
      font-weight: 700;
      border-radius: 3px;
      padding: 15px 30px;
      position: relative;
      color: #333333;
      font-size: 18px;
      background-color: #f7f7f7; }
      .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn:after {
        top: 50%;
        right: 30px;
        content: '\f0aa';
        font-weight: 900;
        position: absolute;
        color: #878787;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free';
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
      .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn:not(.collapsed) {
        color: #ffbe30;
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1); }
        .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn:not(.collapsed):after {
          content: '\f0ab';
          color: #ffbe30; }
      .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn:hover {
        color: #ffbe30;
        text-decoration: none; }
        .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn:hover:after {
          color: #ffbe30; }
  .sidebar-section .faq-wrapper .faq-accordion .card .card-body {
    padding: 20px; }

.sidebar-section .spacial-event-wrapper {
  overflow: hidden;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .sidebar-section .spacial-event-wrapper .overlay-black {
    padding: 80px 50px; }

/* sidebar section - end
==================================================================================================== */
/* index - 1 main-carousel1 - start
==================================================================================================== */
 .item {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: 100% 100%;
  object-fit: c;
}
  .slider-item-content {
    z-index: 1;
    text-align: center;
    padding: 200px 200px;
    padding-bottom: 300px; }
    .slider-item-content .medium-text {
      font-size: 60px;
      font-weight: 400;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 0.8); }
  .slider-item-content .big-text {
      font-size: 72px;
      font-weight: 900;
      color: #ffffff; }
.slider-item-content .small-text {
      color: #ffffff;
      letter-spacing: 6px;
      text-transform: uppercase; }
     .slider-item-content .link-groups {
      margin-top: 20px;
      text-align: center; }
      .slider-item-content .link-groups a {
        margin: 5px;
        -webkit-box-shadow: none;
        box-shadow: none; }
   .slider-item-content .link-groups .start-btn {
        font-weight: 700;
        padding: 7px 15px;
        color: #ffffff;
        border-radius: 30px;
        font-size: 14px;
        text-transform: uppercase;
        border: 3px solid #ffbe30; }
        .slide-section .main-carousel1 .item .slider-item-content .link-groups .start-btn:hover {
          color: #ffffff;
          background-color: #ffbe30; }

.slide-section .owl-theme .owl-dots {
  left: 50%;
  margin: 0px;
  bottom: 100px;
  display: table;
  padding: 0px 15px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-bottom: 1px solid #ffffff; }
  .slide-section .owl-theme .owl-dots .owl-dot {
    float: left;
    min-width: 50px;
    min-height: 50px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    position: relative;
    color: #ffffff;
    display: inline-block;
    font-size: 18px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
    .slide-section .owl-theme .owl-dots .owl-dot:after {
      left: 0;
      right: 0;
      opacity: 0;
      z-index: 1;
      content: '';
      height: 3px;
      bottom: -2px;
      position: absolute;
      -webkit-transform: scaleX(0.6);
      -ms-transform: scaleX(0.6);
      transform: scaleX(0.6);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-color: #ffbe30; }
  .slide-section .owl-theme .owl-dots .owl-dot.active {
    font-size: 30px; }
    .slide-section .owl-theme .owl-dots .owl-dot.active:after {
      opacity: 1;
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }

.slide-section .owl-theme .owl-nav .owl-next,
.slide-section .owl-theme .owl-nav .owl-prev {
  top: 50%;
  opacity: .1;
  width: 50px;
  height: 50px;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  text-align: center;
  color: transparent;
  position: absolute;
  border-radius: 100%;
  background: transparent;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  .slide-section .owl-theme .owl-nav .owl-next span,
  .slide-section .owl-theme .owl-nav .owl-prev span {
    margin: 0px;
    padding: 0px;
    color: transparent;
    background: transparent; }
  .slide-section .owl-theme .owl-nav .owl-next:before,
  .slide-section .owl-theme .owl-nav .owl-prev:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: 900;
    line-height: 50px;
    position: absolute;
    color: #333333;
    font-size: 24px;
    background: #ffffff;
    font-family: 'Font Awesome 5 Free'; }
  .slide-section .owl-theme .owl-nav .owl-next:hover,
  .slide-section .owl-theme .owl-nav .owl-prev:hover {
    opacity: .8; }

.slide-section .owl-theme .owl-nav .owl-prev {
  left: 7%; }
  .slide-section .owl-theme .owl-nav .owl-prev:before {
    content: '\f060'; }

.slide-section .owl-theme .owl-nav .owl-next {
  right: 7%; }
  .slide-section .owl-theme .owl-nav .owl-next:before {
    content: '\f061'; }

/* index - 1 main-carousel1 - end
==================================================================================================== */
/* index - 2 - start
==================================================================================================== */
.main-carousel2 .item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .main-carousel2 .item .overlay-black {
    padding: 200px 0px; }
  .main-carousel2 .item .reg-form {
    padding: 30px;
    text-align: center;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.6); }
    .main-carousel2 .item .reg-form .from-title {
      margin-bottom: 30px; }
      .main-carousel2 .item .reg-form .from-title .title-text {
        margin: 0px;
        font-weight: 400;
        color: #ffffff;
        font-size: 30px;
        text-transform: uppercase; }
        .main-carousel2 .item .reg-form .from-title .title-text strong {
          font-weight: 900; }
      .main-carousel2 .item .reg-form .from-title .sub-title {
        font-weight: 700;
        color: #ffbe30;
        text-transform: capitalize; }
    .main-carousel2 .item .reg-form .form-wrapper .form-item {
      margin-bottom: 10px; }
      .main-carousel2 .item .reg-form .form-wrapper .form-item:last-child {
        margin-bottom: 0px; }
      .main-carousel2 .item .reg-form .form-wrapper .form-item input,
      .main-carousel2 .item .reg-form .form-wrapper .form-item select {
        width: 100%;
        height: 100%;
        display: block;
        padding: 0px 20px;
        border-radius: 2px;
        color: #ffffff;
        border: 1px solid #333333;
        background-color: #333333; }
        .main-carousel2 .item .reg-form .form-wrapper .form-item input:focus,
        .main-carousel2 .item .reg-form .form-wrapper .form-item select:focus {
          outline: none;
          border-color: #ffbe30; }
      .main-carousel2 .item .reg-form .form-wrapper .form-item select {
        -webkit-appearance: none;
      }
    .main-carousel2 .item .reg-form .submit-btn {
      font-weight: 700;
      color: #ffbe30;
      font-size: 18px;
      text-transform: uppercase; }
      .main-carousel2 .item .reg-form .submit-btn:hover {
        text-decoration: underline; }
  .main-carousel2 .item .slider-content {
    padding: 0px 70px; }
    .main-carousel2 .item .slider-content .countdown-timer ul li {
      color: #ffffff;
      border-color: #ffffff; }
    .main-carousel2 .item .slider-content .date {
      width: 100%;
      display: table;
      font-weight: 700;
      color: #ffffff;
      margin-bottom: 15px;
      letter-spacing: 10px;
      text-transform: uppercase; }
    .main-carousel2 .item .slider-content .title-text {
      font-size: 66px;
      font-weight: 400;
      color: #ffffff; }
    .main-carousel2 .item .slider-content .bold-text {
      width: 100%;
      display: table;
      font-size: 66px;
      font-weight: 900;
      margin-bottom: 50px;
      color: #ffbe30;
      font-family: "Roboto", sans-serif; }
    .main-carousel2 .item .slider-content .details-btn {
      font-weight: 700;
      padding: 12px 35px;
      text-align: center;
      color: #ffffff;
      border-radius: 30px;
      font-size: 14px;
      text-transform: uppercase;
      border: 3px solid #ffbe30; }
      .main-carousel2 .item .slider-content .details-btn:hover {
        background-color: #ffbe30; }

.main-carousel2 .slick-prev,
.main-carousel2 .slick-next {
  z-index: 1;
  opacity: .3;
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: absolute;
  border-radius: 100%;
  background-color: #ffffff; }
  .main-carousel2 .slick-prev:before,
  .main-carousel2 .slick-next:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: 900;
    line-height: 40px;
    position: absolute;
    color: #333333;
    font-size: 18px;
    font-family: 'Font Awesome 5 Free'; }
  .main-carousel2 .slick-prev:hover,
  .main-carousel2 .slick-next:hover {
    opacity: 1; }

.main-carousel2 .slick-prev {
  top: 45%;
  left: 90%; }
  .main-carousel2 .slick-prev:before {
    content: '\f060'; }

.main-carousel2 .slick-next {
  top: 50%;
  right: 7%; }
  .main-carousel2 .slick-next:before {
    content: '\f061'; }

/* index - 2 - end
==================================================================================================== */
/* testimonial section - start
==================================================================================================== */
.testimonial5-section .item {
  padding: 0px 180px; }
  .testimonial5-section .item .quote-icon {
    display: table;
    margin: 0 auto 50px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .testimonial5-section .item .quote-icon i {
      font-size: 54px;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important; }
  .testimonial5-section .item .clients-comment {
    font-weight: 700;
    line-height: 30px;
    color: #333333;
    margin-bottom: 50px;
    font-size: 18px; }
  .testimonial5-section .item .client-info .client-name {
    font-weight: 800;
    color: #ffbe30;
    font-size: 24px; }

.testimonial5-section .owl-theme .owl-nav .owl-next,
.testimonial5-section .owl-theme .owl-nav .owl-prev {
  top: 50%;
  width: 40px;
  margin: 0px;
  opacity: .1;
  padding: 0px;
  height: 40px;
  font-size: 16px;
  overflow: hidden;
  position: absolute;
  border-radius: 100%;
  color: #ffffff;
  background: #333333;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  .testimonial5-section .owl-theme .owl-nav .owl-next span,
  .testimonial5-section .owl-theme .owl-nav .owl-prev span {
    color: transparent; }
  .testimonial5-section .owl-theme .owl-nav .owl-next:before,
  .testimonial5-section .owl-theme .owl-nav .owl-prev:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    position: absolute;
    color: #ffffff;
    font-size: 18px;
    font-family: 'Font Awesome 5 Free'; }
  .testimonial5-section .owl-theme .owl-nav .owl-next:hover,
  .testimonial5-section .owl-theme .owl-nav .owl-prev:hover {
    opacity: 1; }

.testimonial5-section .owl-theme .owl-nav .owl-prev {
  left: 0px; }
  .testimonial5-section .owl-theme .owl-nav .owl-prev:before {
    content: '\f053'; }

.testimonial5-section .owl-theme .owl-nav .owl-next {
  right: 0px; }
  .testimonial5-section .owl-theme .owl-nav .owl-next:before {
    content: '\f054'; }

.testimonial5-section .owl-theme .owl-dots {
  margin-top: 50px; }
  .testimonial5-section .owl-theme .owl-dots .owl-dot.active span,
  .testimonial5-section .owl-theme .owl-dots .owl-dot:hover span {
    background: #ffbe30; }

/* testimonial section - end
==================================================================================================== */
/* sponsor section - start
==================================================================================================== */
.default-sponsor-section {
  padding: 80px 0px; }
  .default-sponsor-section ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: table; }
    .default-sponsor-section ul li {
      width: 25%;
      float: left;
      height: 160px;
      list-style: none;
      display: inline-block;
      border-right: 1px solid #f7f7f7; }
      .default-sponsor-section ul li:last-child {
        border-right: none; }
      .default-sponsor-section ul li a {
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        padding: 0px 30px;
        text-align: center;
        line-height: 160px;
        position: relative;
        background-color: #ffffff; }
        .default-sponsor-section ul li a:before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: -1;
          content: '';
          position: absolute;
          background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
          background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
          background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
          background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
      .default-sponsor-section ul li:hover a {
        z-index: 2;
        -webkit-box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2); }
        .default-sponsor-section ul li:hover a:before {
          opacity: 1; }

.absolute-sponsor-section {
  padding: 60px 0px;
  position: relative; }
  .absolute-sponsor-section ul {
    top: 0;
    left: 50%;
    margin: 0px;
    padding: 0px;
    width: 1170px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 5px 50px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 50px 2px rgba(0, 0, 0, 0.2); }
    .absolute-sponsor-section ul li {
      width: 25%;
      height: 160px;
      display: inline-block;
      background-color: #ffffff;
      border-right: 1px solid #f0f0f0; }
      .absolute-sponsor-section ul li:last-child {
        border-right: none; }
      .absolute-sponsor-section ul li a {
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        padding: 0px 50px;
        line-height: 160px;
        position: relative; }
        .absolute-sponsor-section ul li a:before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          content: '';
          z-index: -1;
          position: absolute;
          background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
          background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
          background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
          background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .absolute-sponsor-section ul li a img {
          -webkit-filter: grayscale(1);
          filter: grayscale(1); }
      .absolute-sponsor-section ul li:hover a:before {
        opacity: 1; }
      .absolute-sponsor-section ul li:hover a img {
        -webkit-filter: grayscale(0);
        filter: grayscale(0); }

/* sponsor section - end
==================================================================================================== */
/* upcomming-event-section - start
==================================================================================================== */
.upcomming-event-section .upcomming-event-carousel .item .event-item {
  position: relative;
  margin-bottom: 120px; }
  .upcomming-event-section .upcomming-event-carousel .item .event-item .countdown-timer {
    opacity: 0; }
    .upcomming-event-section .upcomming-event-carousel .item .event-item .countdown-timer ul {
      display: table;
      margin: 0px auto 30px; }
 .event-image {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative; }
    .upcomming-event-section .upcomming-event-carousel .item .event-item .event-image .post-date {
      top: 30px;
      left: 30px;
      z-index: 1;
      height: 70px;
      padding: 10px;
      min-width: 70px;
      text-align: center;
      position: absolute;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
      .upcomming-event-section .upcomming-event-carousel .item .event-item .event-image .post-date .date,
      .upcomming-event-section .upcomming-event-carousel .item .event-item .event-image .post-date .month {
        display: block;
        color: #ffffff; }
      .upcomming-event-section .upcomming-event-carousel .item .event-item .event-image .post-date .date {
        font-weight: 900;
        font-size: 24px;
        font-family: "Roboto", sans-serif; }
      .upcomming-event-section .upcomming-event-carousel .item .event-item .event-image .post-date .month {
        font-size: 16px;
        text-transform: uppercase; }
  .event-item .event-content {
    /* opacity: 0; */
    right: 50px;
    bottom: -80px;
  border-radius: 25px;
    padding: 30px 30px;
    position: absolute;
    /* visibility: hidden; */
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.1); }
    .event-item .event-content1 {
    /* opacity: 0; */
    /* right: 50px; */
    bottom: -80px;
  
    padding: 30px 30px;
    /* position: absolute; */
    border-radius: 25px;
    /* visibility: hidden; */
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    color: white;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.1); }
    .upcomming-event-section .upcomming-event-carousel .item .event-item .event-content .event-title {
      position: relative; }
      .upcomming-event-section .upcomming-event-carousel .item .event-item .event-content .event-title:before {
        top: 0px;
        width: 6px;
        bottom: 0px;
        left: -33px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: #ffbe30; }
      .upcomming-event-section .upcomming-event-carousel .item .event-item .event-content .event-title .title {
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 18px; }
    .upcomming-event-section .upcomming-event-carousel .item .event-item .event-content .custom-btn {
      -webkit-box-shadow: none;
      box-shadow: none; }
  .upcomming-event-section .upcomming-event-carousel .item .event-item .event-post-meta ul li {
    margin-bottom: 5px;
    font-size: 14px; }
    .upcomming-event-section .upcomming-event-carousel .item .event-item .event-post-meta ul li:last-child {
      margin-bottom: 0px; }
    .upcomming-event-section .upcomming-event-carousel .item .event-item .event-post-meta ul li .icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-right: 10px;
      text-align: center;
      border-radius: 100%;
      color: #ffbe30;
      font-size: 14px;
      background-color: #f7f7f7; }

.upcomming-event-section .upcomming-event-carousel .owl-item.active.center .item .event-item .countdown-timer {
  opacity: 1; }

.upcomming-event-section .upcomming-event-carousel .owl-item.active.center .item .event-item .event-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.upcomming-event-section .owl-theme .owl-nav [class*=owl-] {
  top: 50%;
  z-index: 1;
  margin: 0px;
  width: 46px;
  height: 46px;
  padding: 0px;
  position: absolute;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2); }
  .upcomming-event-section .owl-theme .owl-nav [class*=owl-] span {
    display: none; }
  .upcomming-event-section .owl-theme .owl-nav [class*=owl-]:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    font-weight: 900;
    line-height: 46px;
    text-align: center;
    position: absolute;
    border-radius: 100%;
    font-size: 24px;
    color: #f0f0f0;
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-family: 'Font Awesome 5 Free'; }
  .upcomming-event-section .owl-theme .owl-nav [class*=owl-]:hover:before {
    color: #ffbe30; }

.upcomming-event-section .owl-theme .owl-nav .owl-prev {
  left: -23px; }
  .upcomming-event-section .owl-theme .owl-nav .owl-prev:before {
    content: '\f060'; }

.upcomming-event-section .owl-theme .owl-nav .owl-next {
  right: -23px; }
  .upcomming-event-section .owl-theme .owl-nav .owl-next:before {
    content: '\f061'; }

/* upcomming-event-section - end
==================================================================================================== */
/* about-section - start
==================================================================================================== */
.about-section {
  background-color: #f7f7f7; }
  .about-section .section-title .big-title {
    margin-bottom: 30px; }
  .about-section .section-title .custom-btn {
    -webkit-box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2); }
  .about-section .about-item-wrapper {
    padding-left: 50px; }
    .about-section .about-item-wrapper ul {
      width: 100%;
      display: table;
      border-top: 1px solid #f0f0f0;
      border-left: 1px solid #f0f0f0; }
      .about-section .about-item-wrapper ul li {
        height: 213px;
        width: 33.333%;
        background-color: #ffffff;
        border-right: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0; }
        .about-section .about-item-wrapper ul li .about-item {
          width: 100%;
          height: 100%;
          display: block;
          padding: 35px 30px;
          text-align: center;
          position: relative; }
          .about-section .about-item-wrapper ul li .about-item:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            content: '';
            z-index: -1;
            position: absolute;
            background:linear-gradient(to bottom right, #edd099, #ffaf00);
            background:linear-gradient(to bottom right, #edd099, #ffaf00);
            background:linear-gradient(to bottom right, #edd099, #ffaf00);
            background:linear-gradient(to bottom right, #edd099, #ffaf00);
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
          .about-section .about-item-wrapper ul li .about-item .icon {
            display: table;
            margin: 0 auto 30px; }
            .about-section .about-item-wrapper ul li .about-item .icon i {
              font-size: 48px;
              background: -webkit-gradient(linear, left top, right bottom, from(#e0a231));
              background: -webkit-linear-gradient(#e0a231);
              background: -o-linear-gradient(#e0a231);
              background: linear-gradient(#e0a231);
              -webkit-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              -webkit-background-clip: text !important;
              -webkit-text-fill-color: transparent !important; }

              .about-section .about-item-wrapper ul li .about-item .icon .onee {
                font-size: 48px;
                background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
                background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
                background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
                background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
                -webkit-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: transparent !important; }
          .about-section .about-item-wrapper ul li .about-item .title {
            width: 100%;
            display: block;
            font-weight: 700;
            color: #333333;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
            text-transform: capitalize;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
          .about-section .about-item-wrapper ul li .about-item .sub-title {
            color: #878787;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
        .about-section .about-item-wrapper ul li:hover .about-item {
          z-index: 1;
          -webkit-transform: scale(1.03);
          -ms-transform: scale(1.03);
          transform: scale(1.03); }
          .about-section .about-item-wrapper ul li:hover .about-item:before {
            opacity: 1; }
          .about-section .about-item-wrapper ul li:hover .about-item .icon i {
            background: transparent;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: #ffffff !important; }
          .about-section .about-item-wrapper ul li:hover .about-item .title {
            color: #ffffff; }
          .about-section .about-item-wrapper ul li:hover .about-item .sub-title {
            color: #ffffff; }

/* about-section - end
==================================================================================================== */
/* conference-section - start
==================================================================================================== */
.conference-section .overlay-black {
  background-color: rgba(51, 51, 51, 0.9); }

.conference-section .section-title .sub-title {
  color: #ffffff; }

.conference-section .section-title .big-title {
  color: #ffffff;
  font-size: 36px; }

.conference-section .conference-location {
  padding-top: 42px; }
  .conference-section .conference-location ul {
    float: right;
    display: table; }
    .conference-section .conference-location ul li {
      margin-right: 30px; }
      .conference-section .conference-location ul li:last-child {
        margin-right: 0px; }
      .conference-section .conference-location ul li label {
        margin: 0px; }
      .conference-section .conference-location ul li .custom-select {
        width: auto;
        border: none;
        font-weight: 600;
        position: relative;
        color: #ffffff;
        display: inline-block; }
        .conference-section .conference-location ul li .custom-select option {
          color: #333333; }

.conference-section .tab-wrapper {
  position: relative; }
  .conference-section .tab-wrapper .tab-menu .more-btn {
    padding: 0px 70px; }
    .conference-section .tab-wrapper .tab-menu .more-btn a {
      color: #ffffff; }
      .conference-section .tab-wrapper .tab-menu .more-btn a:hover {
        color: #ffbe30; }
  .conference-section .tab-wrapper .tab-menu .tab-nav {
    height: 424px;
    padding: 0px 70px;
    overflow-y: scroll;
    position: relative; }
    .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item {
      width: 100%;
      display: block;
      list-style: none;
      margin-bottom: 2px; }
      .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item:last-child {
        margin-bottom: 0px; }
      .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link {
        width: 100%;
        height: 100%;
        padding: 30px;
        display: table;
        position: relative;
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
        .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link:after {
          top: 50%;
          opacity: 0;
          z-index: 1;
          width: 0px;
          height: 0px;
          content: '';
          right: -30px;
          position: absolute;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          border-top: 15px solid transparent;
          border-left: 15px solid rgba(0, 0, 0, 0.2);
          border-right: 15px solid transparent;
          border-bottom: 15px solid transparent; }
        .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link .image {
          float: left;
          width: 80px;
          height: 80px;
          overflow: hidden;
          margin-right: 30px;
          background: transparent; }
          .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link .image img {
            width: 100%;
            height: 100%; }
        .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link .title {
          width: 100%;
          display: block;
          font-size: 18px;
          font-family: "Roboto", sans-serif; }
        .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link .price {
          width: 100%;
          display: block;
          font-weight: 700; }
      .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item:last-child .nav-link {
        border-bottom: none; }
      .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link:hover {
        background-color: rgba(0, 0, 0, 0.2); }
        .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link:hover:after {
          opacity: 1; }
      .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link.active {
        border-color: transparent;
        background-color: rgba(0, 0, 0, 0.2); }
        .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link.active:after {
          opacity: 1; }
  .conference-section .tab-wrapper .tab-content {
    top: 0;
    right: 0;
    width: 50%;
    position: absolute;
    -webkit-box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2); }
    .conference-section .tab-wrapper .tab-content .tab-pane .image {
      height: 424px;
      overflow: hidden;
      position: relative; }
      .conference-section .tab-wrapper .tab-content .tab-pane .image img {
        width: 100%;
        height: 100%;
        display: block; }
      .conference-section .tab-wrapper .tab-content .tab-pane .image .custom-btn {
        top: 50%;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .conference-section .tab-wrapper .tab-content .badge-item {
      z-index: 1;
      top: -15px;
      left: 40px;
      width: 80px;
      margin: 0px;
      padding: 0px;
      height: auto;
      overflow: hidden;
      text-align: center;
      position: absolute;
      border-radius: 0px;
      padding-bottom: 20px; }
      .conference-section .tab-wrapper .tab-content .badge-item:after, .conference-section .tab-wrapper .tab-content .badge-item:before {
        z-index: -1;
        width: 30px;
        content: '';
        height: 60px;
        bottom: -8px;
        position: absolute; }
      .conference-section .tab-wrapper .tab-content .badge-item:after {
        right: -4px;
        background-color: #84b400;
        -webkit-transform: rotate(-63deg);
        -ms-transform: rotate(-63deg);
        transform: rotate(-63deg); }
      .conference-section .tab-wrapper .tab-content .badge-item:before {
        left: -4px;
        background-color: #84b400;
        -webkit-transform: rotate(63deg);
        -ms-transform: rotate(63deg);
        transform: rotate(63deg); }
      .conference-section .tab-wrapper .tab-content .badge-item .content {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        padding: 15px 10px;
        color: #ffffff;
        background-color: #84b400; }
        .conference-section .tab-wrapper .tab-content .badge-item .content i, .conference-section .tab-wrapper .tab-content .badge-item .content strong {
          width: 100%;
          display: block; }
        .conference-section .tab-wrapper .tab-content .badge-item .content i, .conference-section .tab-wrapper .tab-content .badge-item .content strong {
          font-weight: 700;
          font-size: 24px; }
        .conference-section .tab-wrapper .tab-content .badge-item .content small {
          display: table;
          font-size: 12px;
          margin-top: 5px;
          text-transform: uppercase; }

.tab-nav::-webkit-scrollbar {
  width: 6px;
  height: 60px; }

.tab-nav::-webkit-scrollbar-track {
  width: 10px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5); }

.tab-nav::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: -webkit-radial-gradient(top, #ff3e00, #ffbe30);
  background: radial-gradient(to bottom, #ff3e00, #ffbe30); }

/* conference-section - end
==================================================================================================== */
/* special-offer-section - start
==================================================================================================== */
.special-offer-section {
  padding: 60px 0px;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .special-offer-section .special-offer-content h2 {
    color: #ffffff;
    font-size: 36px; }
    .special-offer-section .special-offer-content h2 strong {
      font-size: 54px;
      font-weight: 900;
      color: #ffbe30; }
    .special-offer-section .special-offer-content h2 span {
      font-weight: 900; }
  .special-offer-section .event-makeing-btn {
    padding: 23px 0px;
    text-align: right; }
    .special-offer-section .event-makeing-btn a {
      font-weight: 600;
      padding: 15px 45px;
      text-align: center;
      border-radius: 30px;
      color: #ffbe30;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #ffffff;
      -webkit-box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2); }
      .special-offer-section .event-makeing-btn a:hover {
        color: #ffffff;
        background-color: #ffbe30; }

/* special-offer-section - end
==================================================================================================== */
/* event-section - start
==================================================================================================== */
.event-section .section-title .big-title {
  font-size: 42px; }

.event-section .event-tab-menu {
  padding-top: 45px; }
  .event-section .event-tab-menu .nav {
    float: right;
    display: table; }
    .event-section .event-tab-menu .nav li {
      float: left;
      margin-right: 30px;
      display: inline-block; }
      .event-section .event-tab-menu .nav li:last-child {
        margin-right: 0px; }
      .event-section .event-tab-menu .nav li a {
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        padding-bottom: 15px;
        color: #878787;
        font-size: 18px;
        text-transform: capitalize;
        font-family: "Roboto", sans-serif; }
        .event-section .event-tab-menu .nav li a strong {
          font-weight: 900; }
          .event-section .event-tab-menu .nav li a strong i {
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
        .event-section .event-tab-menu .nav li a:before {
          left: 0;
          right: 0;
          opacity: 0;
          height: 3px;
          bottom: -2px;
          content: '';
          position: absolute;
          -webkit-transform: scale(0.8);
          -ms-transform: scale(0.8);
          transform: scale(0.8);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: #ffbe30; }
        .event-section .event-tab-menu .nav li a:hover {
          color: #333333; }
          .event-section .event-tab-menu .nav li a:hover strong i {
            color: #ffbe30; }
      .event-section .event-tab-menu .nav li .active {
        color: #333333; }
        .event-section .event-tab-menu .nav li .active strong i {
          color: #ffbe30; }
        .event-section .event-tab-menu .nav li .active:before {
          opacity: 1;
          bottom: 0px;
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1); }

.event-section .tab-content .event-item {
  padding: 15px;
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #f0f0f0; }
  .event-section .tab-content .event-item .event-image {
    float: left;
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin-right: 30px; }
    .event-section .tab-content .event-item .event-image img {
      width: 100%;
      height: 100%;
      display: block; }
    .event-section .tab-content .event-item .event-image .post-date {
      top: 0px;
      left: 0px;
      z-index: 1;
      width: 70px;
      height: 70px;
      padding: 10px;
      text-align: center;
      position: absolute;
      color: #ffffff;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
      .event-section .tab-content .event-item .event-image .post-date .date {
        font-weight: 900;
        font-size: 24px;
        font-family: "Roboto", sans-serif; }
      .event-section .tab-content .event-item .event-image .post-date .month {
        font-size: 16px;
        text-transform: uppercase; }
  .event-section .tab-content .event-item .event-content {
    display: table;
    padding: 10px 0px; }
    .event-section .tab-content .event-item .event-content .event-title {
      position: relative; }
      .event-section .tab-content .event-item .event-content .event-title:after {
        top: 0;
        width: 6px;
        left: -33px;
        bottom: 0px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: #ffbe30; }
      .event-section .tab-content .event-item .event-content .event-title .title {
        margin: 0px;
        font-weight: 900;
        color: #333333;
        font-size: 18px; }
    .event-section .tab-content .event-item .event-content .event-post-meta ul li {
      margin-bottom: 5px;
      font-size: 14px; }
      .event-section .tab-content .event-item .event-content .event-post-meta ul li .icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        text-align: center;
        border-radius: 100%;
        color: #ffbe30;
        font-size: 14px;
        background-color: #f7f7f7; }
    .event-section .tab-content .event-item .event-content .tickets-details-btn {
      z-index: 1;
      font-weight: 600;
      overflow: hidden;
      padding: 15px 45px;
      position: relative;
      border-radius: 30px;
      color: #878787;
      font-size: 14px;
      text-transform: uppercase;
      background: #f7f7f7; }
      .event-section .tab-content .event-item .event-content .tickets-details-btn:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        content: '';
        position: absolute;
        border-radius: 30px;
        background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
        background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
        background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
        background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .event-section .tab-content .event-item .event-content .tickets-details-btn:hover {
        color: #ffffff;
        background: #ffbe30; }
        .event-section .tab-content .event-item .event-content .tickets-details-btn:hover:before {
          opacity: 1; }
  .event-section .tab-content .event-item:hover {
    border-color: #ffbe30; }

.event-section .tab-content .pagination ul {
  margin: 50px auto 0px; }

/* event-section - end
==================================================================================================== */
/* event-gallery-section - start
==================================================================================================== */
.event-gallery-section {
  width: 100%;
  display: table; }
  .event-gallery-section .filters-button-group {
    text-align: center; }
    .event-gallery-section .filters-button-group .button {
      position: relative;
      margin-right: 30px;
      padding-bottom: 15px;
      color: #878787;
      font-size: 18px;
      text-transform: capitalize; }
      .event-gallery-section .filters-button-group .button i {
        color: #878787;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .event-gallery-section .filters-button-group .button strong {
        font-weight: 700; }
      .event-gallery-section .filters-button-group .button:last-child {
        margin-right: 0px; }
      .event-gallery-section .filters-button-group .button:after {
        left: 0;
        right: 0;
        opacity: 0;
        width: 100%;
        height: 3px;
        content: '';
        bottom: -3px;
        position: absolute;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: #ffbe30; }
      .event-gallery-section .filters-button-group .button:hover {
        color: #333333; }
        .event-gallery-section .filters-button-group .button:hover i {
          color: #ffbe30; }
    .event-gallery-section .filters-button-group .button.is-checked {
      color: #333333; }
      .event-gallery-section .filters-button-group .button.is-checked i {
        color: #ffbe30; }
      .event-gallery-section .filters-button-group .button.is-checked:after {
        opacity: 1;
        bottom: 0px;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
  .event-gallery-section .grid {
    width: 100%;
    display: table; }
  .event-gallery-section .grid-item,
  .event-gallery-section .grid-sizer {
    width: 25%;
    max-height: 395px;
    overflow: hidden; }
  .event-gallery-section .grid-item--width2 {
    width: 50%;
    max-height: 395px; }
  .event-gallery-section .grid-item--height2 {
    width: 25%;
    max-height: 790px; }
  .event-gallery-section .grid-item {
    float: left;
    display: inline-block; }
  .event-gallery-section .grid-item {
    position: relative; }
    .event-gallery-section .grid-item a {
      position: relative; }
      .event-gallery-section .grid-item a:before, .event-gallery-section .grid-item a:after {
        z-index: 1;
        opacity: 0;
        content: '';
        position: absolute;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
     
     
    .event-gallery-section .grid-item img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .event-gallery-section .grid-item .item-content {
      left: 0px;
      bottom: 0px;
      width: 100%;
      padding: 30px;
      position: absolute;
      -webkit-transition: all .4s ease-in-out;
      -o-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out; }
      .event-gallery-section .grid-item .item-content h3 {
        font-weight: 900;
        margin-bottom: 0px;
        color: #ffffff;
        font-size: 18px;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); }
      .event-gallery-section .grid-item .item-content span {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); }
    .event-gallery-section .grid-item:hover a:after, .event-gallery-section .grid-item:hover a:before {
      opacity: 1; }
    .event-gallery-section .grid-item:hover img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1); }
    .event-gallery-section .grid-item:hover .item-content {
      opacity: 0;
      bottom: -15px; }
  .event-gallery-section .grid-item.video-gallery a:after {
    content: '\f144'; }
  .event-gallery-section .grid-item.photo-gallery a:after {
    content: '\f03e'; }
  .event-gallery-section .pagination ul {
    display: table;
    margin: 0 auto; }

/* event-gallery-section - end
==================================================================================================== */
/* event-expertise-section - start
==================================================================================================== */
.event-expertise-section .event-expertise-carousel .item {
  padding-left: 40px;
  padding-bottom: 30px;
  position: relative; }
  .event-expertise-section .event-expertise-carousel .item .expertise-title {
    left: -87px;
    bottom: 126px;
    color: #dbdbdb;
    font-weight: 700;
    position: absolute;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .event-expertise-section .event-expertise-carousel .item .expertise-item {
    position: relative; }
    .event-expertise-section .event-expertise-carousel .item .expertise-item .image img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .event-expertise-section .event-expertise-carousel .item .expertise-item .content {
      z-index: 1;
      left: 30px;
      right: 30px;
      bottom: -30px;
      padding: 30px;
      text-align: center;
      position: absolute;
      background-color: #ffffff; }
      .event-expertise-section .event-expertise-carousel .item .expertise-item .content .title {
        font-weight: 900;
        font-size: 18px; }
      .event-expertise-section .event-expertise-carousel .item .expertise-item .content p {
        margin: 0;
        font-size: 14px; }
  .event-expertise-section .event-expertise-carousel .item:hover .expertise-title {
    color: #ffbe30; }
  .event-expertise-section .event-expertise-carousel .item:hover .image img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
  .event-expertise-section .event-expertise-carousel .item:hover .image .plus-effect {
    opacity: 1; }

.event-expertise-section .owl-theme .owl-dots {
  margin-top: 80px !important; }
  .event-expertise-section .owl-theme .owl-dots .owl-dot {
    width: 15px;
    height: 15px;
    margin: 0px 5px; }
    .event-expertise-section .owl-theme .owl-dots .owl-dot span {
      margin: 0px;
      width: 100%;
      height: 100%;
      padding: 0px;
      display: block;
      border-radius: 100%;
      background-color: #dddddd;
      border: 3px solid #dddddd; }
    .event-expertise-section .owl-theme .owl-dots .owl-dot:hover span {
      background-color: #ffffff; }
  .event-expertise-section .owl-theme .owl-dots .owl-dot.active {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5); }
    .event-expertise-section .owl-theme .owl-dots .owl-dot.active span {
      background-color: #ffffff; }

/* event-expertise-section - end
==================================================================================================== */
/* speaker-section - start
==================================================================================================== */
.speaker-section .overlay-white {
  padding-top: 100px;
  background: -webkit-radial-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), white);
  background: -o-radial-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), white);
  background: radial-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), white); }

.speaker-section .speaker-carousel {
  position: relative; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-image {
    padding: 0px 70px;
    position: relative;
    margin-bottom: -8px; }
    .speaker-section .speaker-carousel .slider-for .item .speaker-image img {
      width: 100%;
      height: 100%;
      display: block; }
    .speaker-section .speaker-carousel .slider-for .item .speaker-image .speaker-name {
      z-index: 1;
      left: -100px;
      bottom: 160px;
      color: #dddddd;
      position: absolute;
      padding-left: 90px;
      font-size: 24px;
      text-transform: uppercase;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      .speaker-section .speaker-carousel .slider-for .item .speaker-image .speaker-name:after {
        left: 0;
        top: 50%;
        width: 80px;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #dddddd;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
  .speaker-section .speaker-carousel .slider-for .item .speaker-content {
    padding-top: 50px;
    padding-bottom: 100px; }
    .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info {
      padding-right: 100px; }
      .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-title {
        color: #333333; }
        .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-title .speaker-name {
          margin-right: 30px;
          padding-right: 30px;
          font-size: 24px;
          font-family: "Roboto", sans-serif;
          border-right: 1px solid #878787; }
      .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network {
        width: 100%;
        display: table; }
        .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network ul {
          display: table; }
          .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network ul li {
            margin-right: 15px; }
            .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network ul li:last-child {
              margin-right: 0px; }
            .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network ul li a {
              color: #878787;
              font-size: 18px; }
              .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network ul li a:hover {
                color: #ffbe30; }
  .speaker-section .speaker-carousel .slider-nav {
    right: 0;
    width: 50%;
    margin: 0px;
    height: auto;
    bottom: 20px;
    position: absolute;
    padding-bottom: 30px; }
    .speaker-section .speaker-carousel .slider-nav .item {
      padding: 35px 8px 20px 8px; }
      .speaker-section .speaker-carousel .slider-nav .item .item-content {
        z-index: 1;
        opacity: .8;
        padding: 15px;
        cursor: pointer;
        padding-top: 45px;
        text-align: center;
        position: relative;
        background-color: #ffffff;
        -webkit-box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2); }
        .speaker-section .speaker-carousel .slider-nav .item .item-content:before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: -1;
          content: '';
          position: absolute;
          background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
          background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
          background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
          background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .speaker-section .speaker-carousel .slider-nav .item .item-content .speaker-thumbnail {
          left: 50%;
          z-index: 1;
          top: -35px;
          width: 70px;
          height: 70px;
          overflow: hidden;
          position: absolute;
          text-align: center;
          position: absolute;
          border-radius: 100%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          border: 3px solid #ffffff;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: #f0f0f0; }
        .speaker-section .speaker-carousel .slider-nav .item .item-content .speaker-name {
          margin: 0px;
          font-weight: 900;
          font-size: 18px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .speaker-section .speaker-carousel .slider-nav .item .item-content .sub-title {
          font-size: 14px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .speaker-section .speaker-carousel .slider-nav .item .item-content:hover {
          opacity: 1; }
          .speaker-section .speaker-carousel .slider-nav .item .item-content:hover:before {
            opacity: 1; }
          .speaker-section .speaker-carousel .slider-nav .item .item-content:hover .speaker-name {
            color: #ffffff; }
          .speaker-section .speaker-carousel .slider-nav .item .item-content:hover .sub-title {
            color: #ffffff; }
    .speaker-section .speaker-carousel .slider-nav .slick-slide.slick-current.slick-active .item-content {
      opacity: 1; }
      .speaker-section .speaker-carousel .slider-nav .slick-slide.slick-current.slick-active .item-content:before {
        opacity: 1; }
      .speaker-section .speaker-carousel .slider-nav .slick-slide.slick-current.slick-active .item-content .speaker-name {
        color: #ffffff; }
      .speaker-section .speaker-carousel .slider-nav .slick-slide.slick-current.slick-active .item-content .sub-title {
        color: #ffffff; }

.speaker-section .slick-dots {
  right: 0;
  width: auto;
  bottom: 10px;
  display: table;
  position: absolute; }
  .speaker-section .slick-dots li {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .speaker-section .slick-dots li button {
      opacity: 1;
      width: 100%;
      height: 100%;
      padding: 0px;
      display: block;
      border-radius: 100%;
      background-color: #dddddd;
      border: 3px solid transparent; }
      .speaker-section .slick-dots li button:before {
        content: ""; }
    .speaker-section .slick-dots li:hover button {
      border: 3px solid #dddddd;
      background-color: #ffffff; }
  .speaker-section .slick-dots .slick-active {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5); }
    .speaker-section .slick-dots .slick-active button {
      border: 3px solid #dddddd;
      background-color: #ffffff; }

/* speaker-section - end
==================================================================================================== */
/* advertisement-section - start
==================================================================================================== */
.advertisement-section {
  padding: 80px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .advertisement-section .advertisement-content {
    padding: 0px 100px;
    color: #ffffff; }
    .advertisement-section .advertisement-content .purchase-btn {
      font-weight: 600;
      padding: 15px 45px;
      border-radius: 30px;
      color: #ffbe30;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #ffffff;
      -webkit-box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2); }
      .advertisement-section .advertisement-content .purchase-btn:hover {
        color: #ffffff;
        background-color: #ffbe30; }

/* advertisement-section - end
==================================================================================================== */
/* partners-clients-section - start
==================================================================================================== */
.partners-clients-section .section-title {
  padding: 0px 110px; }
  .partners-clients-section .section-title .big-title {
    margin-bottom: 30px; }

.partners-clients-section .partners-wrapper {
  position: relative;
  padding-left: 42px; }
  .partners-clients-section .partners-wrapper .carousel-title {
    top: 140px;
    z-index: 1;
    left: -138px;
    color: #dddddd;
    position: absolute;
    font-size: 30px;
    text-transform: uppercase;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .partners-clients-section .partners-wrapper .owl-theme .owl-dots {
    display: none; }
  .partners-clients-section .partners-wrapper .owl-theme .owl-nav {
    width: 42px;
    left: -41px;
    height: 82px;
    bottom: 1px;
    display: table;
    position: absolute; }
    .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-next,
    .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-prev {
      left: 0;
      margin: 0px;
      width: 40px;
      height: 40px;
      padding: 0px;
      display: table;
      position: absolute;
      color: transparent;
      background: transparent; }
      .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-next span,
      .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-prev span {
        margin: 0px;
        padding: 0px;
        color: transparent;
        background: transparent; }
      .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-next:before,
      .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-prev:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-weight: 900;
        line-height: 40px;
        position: absolute;
        color: #878787;
        background-color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free'; }
      .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-next:hover:before,
      .partners-clients-section .partners-wrapper .owl-theme .owl-nav .owl-prev:hover:before {
        color: #ffbe30; }
  .partners-clients-section .partners-wrapper .owl-theme .owl-prev {
    bottom: 0; }
    .partners-clients-section .partners-wrapper .owl-theme .owl-prev:before {
      content: '\f063'; }
  .partners-clients-section .partners-wrapper .owl-theme .owl-next {
    top: 0; }
    .partners-clients-section .partners-wrapper .owl-theme .owl-next:before {
      content: '\f062'; }
  .partners-clients-section .partners-wrapper .partners-carousel .item ul {
    margin: 0px;
    padding: 0px;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0; }
    .partners-clients-section .partners-wrapper .partners-carousel .item ul li {
      width: 50%;
      float: left;
      height: 150px;
      list-style: none;
      display: inline-block;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0; }
      .partners-clients-section .partners-wrapper .partners-carousel .item ul li a {
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        padding: 45px 30px;
        line-height: 150px;
        position: relative;
        background-color: #ffffff; }
        .partners-clients-section .partners-wrapper .partners-carousel .item ul li a img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
        .partners-clients-section .partners-wrapper .partners-carousel .item ul li a:before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: -1;
          content: '';
          position: absolute;
          background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
          background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
          background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
          background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .partners-clients-section .partners-wrapper .partners-carousel .item ul li a:hover img {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%); }
        .partners-clients-section .partners-wrapper .partners-carousel .item ul li a:hover:before {
          opacity: 1; }

.partners-clients-section .clients-testimonial {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .partners-clients-section .clients-testimonial .overlay-black {
    padding: 40px 30px; }
  .partners-clients-section .clients-testimonial .section-title {
    padding: 0px; }
    .partners-clients-section .clients-testimonial .section-title .sub-title {
      color: #ffbe30; }
    .partners-clients-section .clients-testimonial .section-title .big-title {
      color: #ffffff; }
  .partners-clients-section .clients-testimonial .client-info {
    margin-bottom: 30px; }
    .partners-clients-section .clients-testimonial .client-info .client-name {
      font-weight: 900;
      color: #ffbe30;
      font-size: 18px; }
    .partners-clients-section .clients-testimonial .client-info .client-sub-title {
      color: rgba(255, 255, 255, 0.5); }
  .partners-clients-section .clients-testimonial .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: rgba(255, 255, 255, 0.2); }
  .partners-clients-section .clients-testimonial .owl-theme .owl-dots .owl-dot:hover span,
  .partners-clients-section .clients-testimonial .owl-theme .owl-dots .owl-dot.active span {
    background: white; }

/* partners-clients-section - end
==================================================================================================== */
/* news-update-section - start
==================================================================================================== */
.news-update-section .faq-accordion {
  width: 100%;
  display: table; }
  .news-update-section .faq-accordion .card {
    width: 100%;
    border: none;
    display: table;
    position: relative;
    border-radius: 0px; }
    .news-update-section .faq-accordion .card .card-header {
      width: 100%;
      padding: 0px;
      border: none;
      display: table;
      background: none;
      margin-bottom: 0;
      border-radius: 0px; }
      .news-update-section .faq-accordion .card .card-header .btn {
        width: 100%;
        border: none;
        display: block;
        font-weight: 900;
        text-align: left;
        padding: 15px 30px;
        border-radius: 0px;
        position: relative;
        font-size: 18px;
        font-family: "Roboto", sans-serif;
        background-color: #ffffff;
        border-left: 3px solid transparent;
        border-bottom: 1px solid #f0f0f0; }
        .news-update-section .faq-accordion .card .card-header .btn span {
          margin-right: 5px;
          color: #878787; }
        .news-update-section .faq-accordion .card .card-header .btn:focus {
          outline: none;
          -webkit-box-shadow: none;
          box-shadow: none; }
        .news-update-section .faq-accordion .card .card-header .btn:after {
          top: 50%;
          right: 30px;
          content: '\f0aa';
          font-weight: 900;
          position: absolute;
          color: #878787;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          font-family: 'Font Awesome 5 Free'; }
        .news-update-section .faq-accordion .card .card-header .btn:not(.collapsed) {
          background-color: #f7f7f7;
          border-left: 3px solid #ffbe30;
          border-bottom: 1px solid #ffffff; }
          .news-update-section .faq-accordion .card .card-header .btn:not(.collapsed):after {
            content: '\f0ab';
            color: #ffbe30; }
    .news-update-section .faq-accordion .card .card-body {
      padding: 30px;
      color: #333333;
      background-color: #f7f7f7;
      border-left: 3px solid #ffbe30; }
      .news-update-section .faq-accordion .card .card-body > h3 {
        font-weight: 700;
        margin-bottom: 15px;
        color: #ffbe30;
        font-size: 18px; }

.news-update-section .latest-blog-wrapper .latest-blog {
  margin-bottom: 30px; }
  .news-update-section .latest-blog-wrapper .latest-blog:last-child {
    margin-bottom: 0px; }
  .news-update-section .latest-blog-wrapper .latest-blog .blog-image {
    float: left;
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: relative; }
  .news-update-section .latest-blog-wrapper .latest-blog .blog-content {
    display: table;
    padding: 12px 30px; }
    .news-update-section .latest-blog-wrapper .latest-blog .blog-content .blog-title h3 {
      font-weight: 900;
      font-size: 18px; }
    .news-update-section .latest-blog-wrapper .latest-blog .blog-content .blog-title span {
      font-weight: 700;
      color: #ffbe30; }
  .news-update-section .latest-blog-wrapper .latest-blog:hover .plus-effect {
    opacity: 1; }

/* news-update-section - end
==================================================================================================== */
.homepage2 .event-gallery-section .section-title .big-title {
  font-size: 36px; }

.homepage2 .event-gallery-section .filters-button-group {
  text-align: center;
  padding-top: 38px; }

.homepage2 .testimonial5-section .overlay-black {
  background: rgba(51, 51, 51, 0.7); }

.homepage2 .testimonial5-section .item .clients-comment {
  color: #ffffff; }

.homepage2 .testimonial5-section .owl-theme .owl-nav .owl-next,
.homepage2 .testimonial5-section .owl-theme .owl-nav .owl-prev {
  opacity: .5; }
  .homepage2 .testimonial5-section .owl-theme .owl-nav .owl-next:hover,
  .homepage2 .testimonial5-section .owl-theme .owl-nav .owl-prev:hover {
    opacity: 1; }

/* home page 2 - start
==================================================================================================== */
.event-advertisement-section {
  padding: 30px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .event-advertisement-section .event-item:hover .plus-effect {
    opacity: 1; }
  .event-advertisement-section .event-item .event-image {
    float: left;
    width: 250px;
    height: 200px;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2); }
    .event-advertisement-section .event-item .event-image img {
      width: 100%;
      height: 100%;
      display: block; }
  .event-advertisement-section .event-item .event-content {
    display: table;
    padding: 0px 30px; }
    .event-advertisement-section .event-item .event-content .event-title {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .event-advertisement-section .event-item .event-content .event-title .tag {
        font-weight: 700;
        margin-bottom: 5px;
        color: #ffffff; }
        .event-advertisement-section .event-item .event-content .event-title .tag i {
          color: #ffbe30; }
      .event-advertisement-section .event-item .event-content .event-title .title-text {
        margin-bottom: 5px;
        color: #ffffff;
        font-size: 24px; }
      .event-advertisement-section .event-item .event-content .event-title .ticket-price {
        font-weight: 700;
        color: #ffbe30; }
    .event-advertisement-section .event-item .event-content .event-info ul {
      width: 100%;
      display: table; }
      .event-advertisement-section .event-item .event-content .event-info ul li {
        margin-bottom: 5px;
        color: #ffffff; }
        .event-advertisement-section .event-item .event-content .event-info ul li:last-child {
          margin-bottom: 0px; }
        .event-advertisement-section .event-item .event-content .event-info ul li .icon {
          width: 30px;
          height: 30px;
          margin-right: 5px;
          line-height: 30px;
          text-align: center;
          border-radius: 100%;
          color: #ffbe30;
          background-color: #ffffff; }
  .event-advertisement-section .right-content-wrapper .countdown-timer ul {
    float: right; }
    .event-advertisement-section .right-content-wrapper .countdown-timer ul li {
      margin: 0px;
      border: 0px;
      padding: 15px 30px;
      border-radius: 0px;
      color: #ffffff;
      border-right: 1px solid rgba(255, 255, 255, 0.5); }
      .event-advertisement-section .right-content-wrapper .countdown-timer ul li:last-child {
        padding-right: 0px;
        border-right: none; }
  .event-advertisement-section .right-content-wrapper .booking-btn {
    font-weight: 700;
    padding: 15px 45px;
    margin-right: 10px;
    border-radius: 30px;
    color: #ffbe30;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #ffffff; }
    .event-advertisement-section .right-content-wrapper .booking-btn:hover {
      color: #ffffff;
      background-color: #ffbe30; }
  .event-advertisement-section .right-content-wrapper .details-btn {
    font-weight: 700;
    padding: 12px 45px;
    border-radius: 30px;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    border: 3px solid #ffffff; }
    .event-advertisement-section .right-content-wrapper .details-btn:hover {
      color: #333333;
      background-color: #ffffff; }

.event-expertise-section2 .expertise-item {
  padding: 12px;
  border-radius: 3px;
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .event-expertise-section2 .expertise-item .expertise-image {
    overflow: hidden;
    position: relative; }
    .event-expertise-section2 .expertise-item .expertise-image img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  .event-expertise-section2 .expertise-item .expertise-content {
    margin-top: 12px;
    position: relative;
    padding-right: 30px; }
    .event-expertise-section2 .expertise-item .expertise-content .link-btn {
      top: 50%;
      right: 0px;
      position: absolute;
      color: #878787;
      font-size: 24px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .event-expertise-section2 .expertise-item .expertise-content .title {
      font-weight: 900;
      margin-bottom: 5px;
      font-size: 18px; }
    .event-expertise-section2 .expertise-item .expertise-content p {
      margin: 0px;
      font-size: 14px; }
  .event-expertise-section2 .expertise-item:hover {
    border-color: #ffbe30;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.1); }
    .event-expertise-section2 .expertise-item:hover .expertise-image img {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2); }
    .event-expertise-section2 .expertise-item:hover .expertise-image .plus-effect {
      opacity: 1; }
    .event-expertise-section2 .expertise-item:hover .expertise-content .link-btn {
      color: #ffbe30; }

.event-section .tab-content .event-item2 {
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #f0f0f0; }
  .event-section .tab-content .event-item2 .event-image {
    overflow: hidden;
    position: relative; }
    .event-section .tab-content .event-item2 .event-image .post-date {
      top: 15px;
      left: 15px;
      z-index: 1;
      width: 70px;
      height: 70px;
      padding: 10px;
      text-align: center;
      position: absolute;
      color: #ffffff;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
      .event-section .tab-content .event-item2 .event-image .post-date .date {
        font-weight: 900;
        font-size: 24px;
        font-family: "Roboto", sans-serif; }
      .event-section .tab-content .event-item2 .event-image .post-date .month {
        font-size: 16px;
        text-transform: uppercase; }
  .event-section .tab-content .event-item2 .event-content {
    padding: 30px; }
    .event-section .tab-content .event-item2 .event-content .event-title {
      position: relative; }
      .event-section .tab-content .event-item2 .event-content .event-title:after {
        top: 0;
        width: 6px;
        left: -30px;
        bottom: 0px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: #ffbe30; }
      .event-section .tab-content .event-item2 .event-content .event-title .title {
        margin: 0px;
        font-weight: 900;
        color: #333333;
        font-size: 24px; }
    .event-section .tab-content .event-item2 .event-content .event-post-meta ul li {
      margin-bottom: 5px;
      font-size: 14px; }
      .event-section .tab-content .event-item2 .event-content .event-post-meta ul li .icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        text-align: center;
        border-radius: 100%;
        color: #ffffff;
        font-size: 14px;
        background-color: #ffbe30; }
    .event-section .tab-content .event-item2 .event-content .tickets-details-btn {
      z-index: 1;
      font-weight: 600;
      overflow: hidden;
      padding: 15px 45px;
      position: relative;
      border-radius: 30px;
      color: #878787;
      font-size: 14px;
      text-transform: uppercase;
      background: #f7f7f7; }
      .event-section .tab-content .event-item2 .event-content .tickets-details-btn:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        content: '';
        position: absolute;
        border-radius: 30px;
        background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
        background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
        background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
        background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .event-section .tab-content .event-item2 .event-content .tickets-details-btn:hover {
        color: #ffffff;
        background: #ffbe30; }
        .event-section .tab-content .event-item2 .event-content .tickets-details-btn:hover:before {
          opacity: 1; }
  .event-section .tab-content .event-item2:hover {
    border-color: #ffbe30; }

.event-section .tab-content .pagination ul {
  margin: 50px auto 0px; }

/* home page 2 - end
==================================================================================================== */
.homepage3 .main-carousel2 .item .slider-content {
  padding: 0px; }
  .homepage3 .main-carousel2 .item .slider-content .custom-btn {
    -webkit-box-shadow: none;
    box-shadow: none; }

.homepage3 .main-carousel2 .slick-next {
  top: 51%; }

.homepage3 .testimonial5-section .overlay-black {
  background: rgba(51, 51, 51, 0.7); }

.homepage3 .testimonial5-section .item {
  padding-top: 50px; }
  .homepage3 .testimonial5-section .item .quote-icon {
    z-index: 1;
    width: 80px;
    height: 60px;
    display: table;
    position: relative;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto 50px;
    font-size: 36px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #f7f7f7;
    border-left: 3px solid #f7f7f7;
    border-right: 3px solid #f7f7f7; }
    .homepage3 .testimonial5-section .item .quote-icon i {
      font-size: 48px;
      line-height: 66px;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important; }
    .homepage3 .testimonial5-section .item .quote-icon:before {
      top: -22px;
      left: -59px;
      width: 51px;
      content: '';
      z-index: -1;
      height: 51px;
      position: absolute;
      border-radius: 8px;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-color: #f7f7f7;
      border-top: 3px solid #f7f7f7;
      border-left: 3px solid #f7f7f7;
      -webkit-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
      -ms-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
      transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg); }
    .homepage3 .testimonial5-section .item .quote-icon:after {
      content: '';
      left: -59px;
      width: 51px;
      z-index: -1;
      height: 51px;
      bottom: -22px;
      position: absolute;
      border-radius: 8px;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-color: #f7f7f7;
      border-right: 3px solid #f7f7f7;
      border-bottom: 3px solid #f7f7f7;
      -webkit-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
      -ms-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
      transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg); }
  .homepage3 .testimonial5-section .item .clients-comment {
    color: #ffffff; }

.homepage3 .testimonial5-section .owl-theme .owl-nav .owl-next,
.homepage3 .testimonial5-section .owl-theme .owl-nav .owl-prev {
  opacity: .5; }
  .homepage3 .testimonial5-section .owl-theme .owl-nav .owl-next:hover,
  .homepage3 .testimonial5-section .owl-theme .owl-nav .owl-prev:hover {
    opacity: 1; }

.homepage3 .event-expertise-section .link-btn {
  padding: 22px 0px; }
  .homepage3 .event-expertise-section .link-btn a {
    font-weight: 900;
    text-align: center;
    padding: 12px 30px;
    color: #333333;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    border: 3px solid #ffbe30; }
    .homepage3 .event-expertise-section .link-btn a:hover {
      color: #ffffff;
      background-color: #ffbe30; }

.homepage3 .default-sponsor-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .homepage3 .default-sponsor-section ul li {
    border-right: 2px solid transparent; }

/* home page 3 - start
==================================================================================================== */
.absolute-eventmake-section {
  position: relative; }
  .absolute-eventmake-section .eventmaking-wrapper {
    top: -50px;
    left: 50%;
    z-index: 1;
    width: 1170px;
    margin: 0 auto;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 5px 80px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 80px 5px rgba(0, 0, 0, 0.2); }
    .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs {
      width: 100%;
      margin: 0px;
      padding: 0px;
      display: table; }
      .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li {
        float: left;
        width: auto;
        height: 50px;
        margin-right: 10px;
        display: inline-block; }
        .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li:last-child {
          margin-right: 0px; }
        .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li a {
          width: 100%;
          height: 100%;
          display: block;
          font-weight: 700;
          line-height: 50px;
          padding: 0px 30px;
          color: #878787;
          font-size: 18px;
          text-transform: capitalize;
          background-color: rgba(51, 51, 51, 0.5); }
          .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li a i {
            color: #878787;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
        .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li:hover a {
          color: #333333;
          background-color: #ffffff; }
          .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li:hover a i {
            color: #ffbe30; }
        .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li .active {
          color: #333333;
          background-color: #ffffff; }
          .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li .active i {
            color: #ffbe30; }
    .absolute-eventmake-section .eventmaking-wrapper .tab-content {
      background-color: #ffffff; }
      .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane {
        padding: 30px; }
        .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul {
          margin: 0px;
          width: 100%;
          padding: 0px;
          display: table; }
          .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li {
            float: left;
            width: 19.611%;
            list-style: none;
            margin-right: 5px;
            display: inline-block; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li:last-child {
              margin-right: 0px; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li input,
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li select {
              width: 100%;
              height: 50px;
              display: block;
              font-weight: 700;
              padding: 0px 20px;
              border-radius: 3px;
              color: #333333;
              font-size: 14px;
              -webkit-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              border: 1px solid #f0f0f0;
              background-color: #f7f7f7; }
              .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li input:focus,
              .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li select:focus {
                outline: none;
                border-color: #ffbe30; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li select {
              -webkit-appearance: none;
              text-transform: uppercase;}
             
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li .custom-btn {
              width: 100%;
              display: block;
              -webkit-box-shadow: none;
              box-shadow: none;
              border-radius: 3px; }
              .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li .custom-btn:after, .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li .custom-btn:before {
                border-radius: 3px; }
      .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location {
        padding: 0px 30px 30px 30px; }
        .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location .title-text {
          float: left;
          margin-right: 15px;
          font-size: 14px; }
        .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul {
          margin: 0px;
          padding: 0px;
          display: table; }
          .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li {
            float: left;
            list-style: none;
            position: relative;
            margin-right: 15px;
            padding-right: 15px;
            color: #878787;
            display: inline-block;
            font-size: 14px;
            text-transform: capitalize;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li strong {
              -webkit-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li:after {
              top: 0;
              right: 0;
              bottom: 0;
              content: '|';
              position: absolute;
              color: #878787; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li:last-child {
              margin-right: 0px;
              padding-right: 0px; }
              .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li:last-child:after {
                display: none; }
            .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li:hover {
              color: #333333; }
              .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location ul li:hover strong {
                color: #ffbe30; }

.about-section2 .section-pragraph {
  margin: 0px;
  line-height: 30px;
  color: #333333; }

.about-section2 .services-list ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: table;
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0; }
  .about-section2 .services-list ul li {
    float: left;
    width: 33.333%;
    list-style: none;
    display: inline-block;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0; }
    .about-section2 .services-list ul li a {
      z-index: 1;
      width: 100%;
      height: 100%;
      display: table;
      padding: 50px 40px;
      position: relative;
      background-color: #ffffff; }
      .about-section2 .services-list ul li a:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        content: '';
        position: absolute;
        background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
        background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
        background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
        background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .about-section2 .services-list ul li a .icon {
        z-index: 1;
        float: left;
        width: 80px;
        height: 60px;
        position: relative;
        border-radius: 5px;
        text-align: center;
        margin-right: 30px;
        font-size: 36px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: #f7f7f7;
        border-left: 3px solid #f7f7f7;
        border-right: 3px solid #f7f7f7; }
        .about-section2 .services-list ul li a .icon i {
          font-size: 48px;
          line-height: 66px;
          background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
          background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
          background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
          background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important; }
        .about-section2 .services-list ul li a .icon:before {
          top: -22px;
          left: -59px;
          width: 51px;
          content: '';
          z-index: -1;
          height: 51px;
          position: absolute;
          border-radius: 8px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: #f7f7f7;
          border-top: 3px solid #f7f7f7;
          border-left: 3px solid #f7f7f7;
          -webkit-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
          -ms-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
          transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg); }
        .about-section2 .services-list ul li a .icon:after {
          content: '';
          left: -59px;
          width: 51px;
          z-index: -1;
          height: 51px;
          bottom: -22px;
          position: absolute;
          border-radius: 8px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: #f7f7f7;
          border-right: 3px solid #f7f7f7;
          border-bottom: 3px solid #f7f7f7;
          -webkit-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
          -ms-transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg);
          transform: scale(1) rotate(45deg) translateX(50px) translateY(-50px) skewX(-10deg) skewY(-10deg); }
      .about-section2 .services-list ul li a .title-text {
        display: table;
        margin-top: 10px;
        font-weight: 900;
        color: #333333;
        font-size: 18px;
        text-transform: capitalize;
        font-family: "Roboto", sans-serif;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .about-section2 .services-list ul li a .sub-title {
        font-weight: 700;
        color: #878787;
        text-transform: capitalize;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .about-section2 .services-list ul li a:hover:before {
        opacity: 1; }
      .about-section2 .services-list ul li a:hover .icon {
        background-color: #ffffff;
        border-left: 3px solid #ffffff;
        border-right: 3px solid #ffffff; }
        .about-section2 .services-list ul li a:hover .icon:before {
          background-color: #ffffff;
          border-top: 3px solid #ffffff;
          border-left: 3px solid #ffffff; }
        .about-section2 .services-list ul li a:hover .icon:after {
          background-color: #ffffff;
          border-right: 3px solid #ffffff;
          border-bottom: 3px solid #ffffff; }
      .about-section2 .services-list ul li a:hover .title-text {
        color: #ffffff; }
      .about-section2 .services-list ul li a:hover .sub-title {
        color: #ffffff; }

.upcomming-event-section2 .comming-event-item {
  position: relative; }
  .upcomming-event-section2 .comming-event-item .event-content {
    padding-right: 70px; }
    .upcomming-event-section2 .comming-event-item .event-content .event-title {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #f0f0f0; }
      .upcomming-event-section2 .comming-event-item .event-content .event-title .tag {
        font-weight: 900;
        margin-bottom: 15px;
        color: #333333;
        font-size: 16px; }
        .upcomming-event-section2 .comming-event-item .event-content .event-title .tag i {
          color: #ffbe30; }
      .upcomming-event-section2 .comming-event-item .event-content .event-title .title-text {
        font-weight: 400;
        font-size: 30px; }
        .upcomming-event-section2 .comming-event-item .event-content .event-title .title-text strong {
          font-weight: 900; }
      .upcomming-event-section2 .comming-event-item .event-content .event-title .sub-title {
        font-weight: 700;
        color: #ffbe30; }
    .upcomming-event-section2 .comming-event-item .event-content .event-info-list ul li {
      margin-bottom: 5px; }
      .upcomming-event-section2 .comming-event-item .event-content .event-info-list ul li:last-child {
        margin-bottom: 0px; }
      .upcomming-event-section2 .comming-event-item .event-content .event-info-list ul li .icon {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin-right: 10px;
        color: #ffffff;
        border-radius: 100%;
        font-size: 16px;
        background-color: #ffbe30; }
    .upcomming-event-section2 .comming-event-item .event-content .custom-btn {
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-right: 15px; }
    .upcomming-event-section2 .comming-event-item .event-content .details-btn {
      font-weight: 700;
      padding: 12px 45px;
      text-align: center;
      color: #333333;
      border-radius: 30px;
      font-size: 14px;
      text-transform: uppercase;
      border: 3px solid #ffbe30; }
      .upcomming-event-section2 .comming-event-item .event-content .details-btn:hover {
        color: #ffffff;
        background-color: #ffbe30; }
  .upcomming-event-section2 .comming-event-item .event-image {
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    float: left;
    padding: 22px 0px;
    position: absolute;
    padding-left: 110px; }
    .upcomming-event-section2 .comming-event-item .event-image .big-image {
      overflow: hidden;
      border-radius: 3px;
      position: relative;
      -webkit-box-shadow: 0 0 70px 3px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 70px 3px rgba(0, 0, 0, 0.2); }
      .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item {
        top: 0px;
        z-index: 1;
        right: 40px;
        width: 80px;
        margin: 0px;
        padding: 0px;
        height: auto;
        overflow: hidden;
        text-align: center;
        position: absolute;
        border-radius: 0px;
        padding-bottom: 20px; }
        .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item:after, .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item:before {
          z-index: -1;
          width: 30px;
          content: '';
          height: 60px;
          bottom: -8px;
          position: absolute; }
        .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item:after {
          right: -4px;
          background-color: #84b400;
          -webkit-transform: rotate(-63deg);
          -ms-transform: rotate(-63deg);
          transform: rotate(-63deg); }
        .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item:before {
          left: -4px;
          background-color: #84b400;
          -webkit-transform: rotate(63deg);
          -ms-transform: rotate(63deg);
          transform: rotate(63deg); }
        .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item .content {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          padding: 15px 10px;
          color: #ffffff;
          background-color: #84b400; }
          .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item .content i, .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item .content strong {
            width: 100%;
            display: block; }
          .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item .content i, .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item .content strong {
            font-weight: 700;
            font-size: 24px; }
          .upcomming-event-section2 .comming-event-item .event-image .big-image .badge-item .content small {
            display: table;
            font-size: 12px;
            margin-top: 5px;
            text-transform: uppercase; }
      .upcomming-event-section2 .comming-event-item .event-image .big-image .brand-logo {
        right: 30px;
        bottom: 30px;
        max-width: 160px;
        position: absolute; }
    .upcomming-event-section2 .comming-event-item .event-image .small-image {
      top: 50%;
      left: 0px;
      z-index: 1;
      width: 220px;
      height: 250px;
      overflow: hidden;
      position: absolute;
      border-radius: 3px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-box-shadow: 0 0 70px 3px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 70px 3px rgba(0, 0, 0, 0.2); }

/* home page 3 - end
==================================================================================================== */
.homepage4 .main-carousel2 .item .slider-content {
  padding: 0px; }
  .homepage4 .main-carousel2 .item .slider-content .custom-btn {
    -webkit-box-shadow: none;
    box-shadow: none; }

.homepage4 .main-carousel2 .slick-next {
  top: 51%; }

.homepage4 .event-gallery-section .section-title .big-title {
  font-size: 36px; }

.homepage4 .event-gallery-section .filters-button-group {
  text-align: center;
  padding-top: 38px; }

.homepage4 .absolute-sponsor-section ul {
  top: 40px; }

.homepage4 .event-expertise-section {
  padding-top: 200px; }

.homepage4 .news-update-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .homepage4 .news-update-section .section-title .sub-title,
  .homepage4 .news-update-section .section-title .big-title {
    color: #ffffff; }
  .homepage4 .news-update-section .faq-accordion .card {
    margin-bottom: 10px;
    background: transparent; }
    .homepage4 .news-update-section .faq-accordion .card:last-child {
      margin-bottom: 0px; }
    .homepage4 .news-update-section .faq-accordion .card .card-header .btn {
      border-radius: 30px;
      color: #ffffff;
      background-color: #000000;
      border-left: none !important;
      border-bottom: none !important; }
      .homepage4 .news-update-section .faq-accordion .card .card-header .btn:not(.collapsed) {
        background-color: #333333;
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2); }
    .homepage4 .news-update-section .faq-accordion .card .card-body {
      color: #878787;
      border-left: none !important;
      background-color: transparent; }
  .homepage4 .news-update-section .latest-blog-wrapper .latest-blog .blog-content .blog-title h3 {
    font-weight: 900;
    color: #ffffff;
    font-size: 18px; }

.homepage4 .contact-section .contact-form .form-item input {
  background-color: #ffffff; }

.homepage4 .contact-section .contact-form textarea {
  background-color: #ffffff; }

/* home page 4 - start
==================================================================================================== */
.slide-section {
  position: relative; }
  .slide-section .booking-form-section {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 20px 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8); }
    .slide-section .booking-form-section .section-title .big-title {
      color: #ffffff;
      font-size: 30px;
      text-transform: uppercase; }
    .slide-section .booking-form-section .section-title .ticket-price {
      font-weight: 700;
      color: #ffbe30;
      text-transform: capitalize; }
    .slide-section .booking-form-section .booking-form ul {
      width: 100%;
      margin: 0px;
      padding: 0px;
      display: table; }
      .slide-section .booking-form-section .booking-form ul li {
        float: left;
        width: 24.5%;
        list-style: none;
        margin-right: 5px;
        display: inline-block; }
        .slide-section .booking-form-section .booking-form ul li:last-child {
          margin-right: 0px; }
        .slide-section .booking-form-section .booking-form ul li input,
        .slide-section .booking-form-section .booking-form ul li select {
          width: 100%;
          height: 50px;
          padding: 0px 30px;
          border-radius: 30px;
          color: #ffffff;
          background-color: #000000;
          border: 2px solid #000000;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
          .slide-section .booking-form-section .booking-form ul li input:focus,
          .slide-section .booking-form-section .booking-form ul li select:focus {
            outline: none;
            border-color: #ffbe30; }
        .slide-section .booking-form-section .booking-form ul li select {
          color: #ffbe30;
          -webkit-appearance: none;
        }
        .slide-section .booking-form-section .booking-form ul li .custom-btn {
          width: 100%;
          display: block;
          -webkit-box-shadow: none;
          box-shadow: none; }

.our-management-section .section-title .big-title {
  margin-bottom: 30px; }

.our-management-section .management-service-carousel .item a {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  padding: 50px 40px;
  position: relative;
  background-color: #ffffff; }
  .our-management-section .management-service-carousel .item a:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    content: '';
    position: absolute;
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .our-management-section .management-service-carousel .item a .icon {
    z-index: 1;
    display: table;
    position: relative;
    text-align: center;
    margin: 0 auto 50px;
    font-size: 36px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .our-management-section .management-service-carousel .item a .icon i {
      font-size: 48px;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important; }
  .our-management-section .management-service-carousel .item a .title-text {
    width: 100%;
    display: table;
    margin-top: 10px;
    font-weight: 900;
    color: #333333;
    font-size: 18px;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .our-management-section .management-service-carousel .item a .sub-title {
    width: 100%;
    font-weight: 700;
    color: #878787;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .our-management-section .management-service-carousel .item a:hover:before {
    opacity: 1; }
  .our-management-section .management-service-carousel .item a:hover .icon i {
    background: transparent;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: #ffffff !important; }
  .our-management-section .management-service-carousel .item a:hover .title-text {
    color: #ffffff; }
  .our-management-section .management-service-carousel .item a:hover .sub-title {
    color: #ffffff; }

.our-management-section .owl-theme .owl-dots {
  height: 16px;
  padding: 3px;
  width: 360px;
  margin: 0px auto;
  border-radius: 30px;
  margin-top: 80px !important;
  background-color: #f0f0f0; }
  .our-management-section .owl-theme .owl-dots button {
    float: left;
    width: 116px;
    height: 10px;
    margin-right: 2px;
    border-radius: 30px;
    display: inline-block; }
    .our-management-section .owl-theme .owl-dots button:last-child {
      margin-right: 0px; }
    .our-management-section .owl-theme .owl-dots button span {
      width: 100%;
      margin: 0px;
      height: 100%;
      padding: 0px;
      display: block;
      background: green;
      background: transparent;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .our-management-section .owl-theme .owl-dots button:hover span {
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
  .our-management-section .owl-theme .owl-dots .active span {
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }

.upcomming-event-section3 .section-title {
  padding: 0px 120px; }
  .upcomming-event-section3 .section-title .big-title {
    margin-bottom: 30px; }

.upcomming-event-section3 .comming-event-item .event-image {
  position: relative;
  padding-right: 70px;
  padding-bottom: 70px; }
  .upcomming-event-section3 .comming-event-item .event-image .big-image {
    overflow: hidden;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.2); }
  .upcomming-event-section3 .comming-event-item .event-image .small-image {
    right: 0px;
    bottom: 0px;
    width: 250px;
    height: 300px;
    overflow: hidden;
    position: absolute;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.2); }

.upcomming-event-section3 .comming-event-item .event-content {
  padding-right: 70px; }
  .upcomming-event-section3 .comming-event-item .event-content .event-title {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0; }
    .upcomming-event-section3 .comming-event-item .event-content .event-title .tag {
      font-weight: 900;
      color: #333333;
      font-size: 16px; }
      .upcomming-event-section3 .comming-event-item .event-content .event-title .tag i {
        color: #ffbe30; }
    .upcomming-event-section3 .comming-event-item .event-content .event-title .title-text {
      font-weight: 400;
      font-size: 30px; }
      .upcomming-event-section3 .comming-event-item .event-content .event-title .title-text strong {
        font-weight: 900; }
    .upcomming-event-section3 .comming-event-item .event-content .event-title .sub-title {
      font-weight: 700;
      color: #ffbe30; }
  .upcomming-event-section3 .comming-event-item .event-content .event-info-list ul li {
    margin-bottom: 5px; }
    .upcomming-event-section3 .comming-event-item .event-content .event-info-list ul li:last-child {
      margin-bottom: 0px; }
    .upcomming-event-section3 .comming-event-item .event-content .event-info-list ul li .icon {
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      margin-right: 10px;
      color: #ffffff;
      border-radius: 100%;
      font-size: 16px;
      background-color: #ffbe30; }
  .upcomming-event-section3 .comming-event-item .event-content .custom-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-right: 15px; }
  .upcomming-event-section3 .comming-event-item .event-content .details-btn {
    font-weight: 700;
    padding: 12px 45px;
    text-align: center;
    color: #333333;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    border: 3px solid #ffbe30; }
    .upcomming-event-section3 .comming-event-item .event-content .details-btn:hover {
      color: #ffffff;
      background-color: #ffbe30; }

.awesome-event-section .awesome-event-menu ul {
  padding: 0px;
  display: table;
  margin: 0px auto 50px; }
  .awesome-event-section .awesome-event-menu ul li {
    float: left;
    width: auto;
    height: 50px;
    margin-right: 15px;
    text-align: center; }
    .awesome-event-section .awesome-event-menu ul li:last-child {
      margin-right: 0px; }
    .awesome-event-section .awesome-event-menu ul li a {
      width: 100%;
      height: 100%;
      display: block;
      font-weight: 400;
      line-height: 50px;
      padding: 0px 35px;
      text-align: center;
      border-radius: 30px;
      color: #878787;
      font-size: 16px;
      text-transform: capitalize;
      background-color: #f0f0f0; }
      .awesome-event-section .awesome-event-menu ul li a strong {
        font-weight: 700; }
      .awesome-event-section .awesome-event-menu ul li a i {
        color: #878787;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
    .awesome-event-section .awesome-event-menu ul li:hover a {
      color: #333333; }
      .awesome-event-section .awesome-event-menu ul li:hover a i {
        color: #ffbe30; }
    .awesome-event-section .awesome-event-menu ul li .active {
      color: #333333;
      background-color: #ffffff;
      -webkit-box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.1); }
      .awesome-event-section .awesome-event-menu ul li .active i {
        color: #ffbe30; }

.awesome-event-section .tab-content .image-wrapper .post-date {
  top: 30px;
  left: 30px;
  z-index: 1;
  height: 70px;
  padding: 10px;
  min-width: 70px;
  text-align: center;
  position: absolute;
  background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
  background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
  background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
  background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
  .awesome-event-section .tab-content .image-wrapper .post-date .date,
  .awesome-event-section .tab-content .image-wrapper .post-date .month {
    display: block;
    color: #ffffff; }
  .awesome-event-section .tab-content .image-wrapper .post-date .date {
    font-weight: 900;
    font-size: 24px;
    font-family: "Roboto", sans-serif; }
  .awesome-event-section .tab-content .image-wrapper .post-date .month {
    font-size: 16px;
    text-transform: uppercase; }

.awesome-event-section .tab-content .image-wrapper .tag {
  top: 55px;
  right: 30px;
  font-weight: 700;
  position: absolute;
  color: #ffffff;
  font-size: 18px; }
  .awesome-event-section .tab-content .image-wrapper .tag i {
    color: #ffbe30; }

.awesome-event-section .tab-content .featured-event {
  position: relative;
  padding-bottom: 90px; }
  .awesome-event-section .tab-content .featured-event .event-content {
    bottom: 0px;
    right: 30px;
    padding: 30px;
    max-width: 300px;
    position: absolute;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 80px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 80px 1px rgba(0, 0, 0, 0.1); }
    .awesome-event-section .tab-content .featured-event .event-content .event-title {
      position: relative; }
      .awesome-event-section .tab-content .featured-event .event-content .event-title:before {
        top: 0px;
        width: 6px;
        bottom: 0px;
        left: -33px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: #ffbe30; }
      .awesome-event-section .tab-content .featured-event .event-content .event-title .title-text {
        font-weight: 900;
        margin-bottom: 15px;
        font-size: 18px; }
      .awesome-event-section .tab-content .featured-event .event-content .event-title .ticket-price {
        font-weight: 700;
        color: #ffbe30;
        text-transform: capitalize; }
    .awesome-event-section .tab-content .featured-event .event-content .event-post-meta ul li {
      margin-bottom: 5px;
      font-size: 14px; }
      .awesome-event-section .tab-content .featured-event .event-content .event-post-meta ul li:last-child {
        margin-bottom: 0px; }
      .awesome-event-section .tab-content .featured-event .event-content .event-post-meta ul li .icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        text-align: center;
        border-radius: 100%;
        color: #ffffff;
        font-size: 14px;
        background-color: #ffbe30; }
    .awesome-event-section .tab-content .featured-event .event-content .custom-btn {
      -webkit-box-shadow: none;
      box-shadow: none; }

.awesome-event-section .tab-content .awesome-event-item .event-content {
  padding: 30px;
  background-color: #ffffff; }
  .awesome-event-section .tab-content .awesome-event-item .event-content .event-title .title-text {
    font-weight: 900;
    margin-bottom: 15px;
    font-size: 18px; }
  .awesome-event-section .tab-content .awesome-event-item .event-content .event-title .ticket-price {
    font-weight: 700;
    color: #ffbe30;
    text-transform: capitalize; }
  .awesome-event-section .tab-content .awesome-event-item .event-content .tickets-details-btn {
    z-index: 1;
    font-weight: 600;
    overflow: hidden;
    padding: 15px 30px;
    position: relative;
    border-radius: 30px;
    color: #878787;
    font-size: 14px;
    text-transform: uppercase;
    background: #f7f7f7; }
    .awesome-event-section .tab-content .awesome-event-item .event-content .tickets-details-btn:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: -1;
      content: '';
      position: absolute;
      border-radius: 30px;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .awesome-event-section .tab-content .awesome-event-item .event-content .tickets-details-btn:hover {
      color: #ffffff;
      background: #ffbe30; }
      .awesome-event-section .tab-content .awesome-event-item .event-content .tickets-details-btn:hover:before {
        opacity: 1; }

.awesome-event-section .tab-content .pagination ul {
  display: table;
  text-align: center;
  margin: 80px auto 0px; }

/* home page 4 - end
==================================================================================================== */
/* about page - start
==================================================================================================== */
.our-management-section .management-item .item-title {
  margin-bottom: 30px; }
  .our-management-section .management-item .item-title .title-text {
    margin: 0px;
    display: table;
    font-weight: 900;
    position: relative;
    padding-right: 60px;
    font-size: 24px; }
    .our-management-section .management-item .item-title .title-text:after {
      right: 0;
      bottom: 6px;
      height: 5px;
      width: 50px;
      content: '';
      position: absolute;
      background-color: #ffbe30; }

.award-section .company-age {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .award-section .company-age .banner-img {
    top: 50%;
    left: 50%;
    width: 390px;
    height: 430px;
    overflow: hidden;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.award-section .awaed-item-area {
  position: relative; }
  .award-section .awaed-item-area:before {
    top: 0;
    bottom: 0;
    left: 13px;
    width: 2px;
    content: '';
    height: 100%;
    position: absolute;
    background-color: #878787; }
  .award-section .awaed-item-area .arrow-up,
  .award-section .awaed-item-area .arrow-down {
    left: 0px;
    z-index: 2;
    position: absolute;
    color: #878787;
    font-size: 30px; }
  .award-section .awaed-item-area .arrow-up {
    top: -22px; }
  .award-section .awaed-item-area .arrow-down {
    bottom: -22px; }
  .award-section .awaed-item-area .awaed-wrapper {
    max-height: 425px;
    overflow-y: scroll;
    position: relative; }
    .award-section .awaed-item-area .awaed-wrapper .awaed-item {
      padding: 30px 0px;
      position: relative;
      padding-left: 120px; }
      .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content .awaed-title {
        font-weight: 700;
        position: relative;
        font-size: 24px; }
        .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content .awaed-title .awaed-date {
          top: 5px;
          left: -105px;
          padding-left: 10px;
          position: absolute;
          padding-bottom: 10px;
          color: #ffbe30;
          font-size: 16px;
          text-transform: uppercase; }
          .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content .awaed-title .awaed-date:before {
            left: -6px;
            z-index: 1;
            content: '';
            width: 10px;
            bottom: -4px;
            height: 10px;
            position: absolute;
            border-radius: 100%;
            background-color: #ffbe30; }
          .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content .awaed-title .awaed-date:after {
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 2px;
            width: 100%;
            position: absolute;
            background-color: #878787; }
      .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content p {
        font-size: 14px; }

.awaed-wrapper::-webkit-scrollbar {
  display: none; }

.service-section .team-btn {
  padding-top: 45px; }

.service-section .service-wrapper > ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: table;
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0; }
  .service-section .service-wrapper > ul > li {
    width: 25%;
    float: left;
    display: inline-block;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0; }
    .service-section .service-wrapper > ul > li a {
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      text-align: center;
      padding: 60px 30px;
      color: #333333;
      background-color: #ffffff; }
      .service-section .service-wrapper > ul > li a .icon {
        display: table;
        margin: 0 auto 30px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .service-section .service-wrapper > ul > li a .icon i {
          font-size: 54px;
          background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
          background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
          background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
          background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important; }
      .service-section .service-wrapper > ul > li a .service-title {
        width: 100%;
        display: block;
        font-weight: 900;
        font-size: 18px;
        font-family: "Roboto", sans-serif;
        text-transform: capitalize; }
      .service-section .service-wrapper > ul > li a .service-sub-title {
        width: 100%;
        display: block;
        font-size: 14px;
        text-transform: capitalize; }
      .service-section .service-wrapper > ul > li a:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        content: '';
        position: absolute;
        background: -webkit-gradient(linear, left top, right bottom, from(#edd099), to(#ffaf00));
        background: -webkit-linear-gradient(to bottom right, #edd099, #ffaf00);
        background: -o-linear-gradient(to bottom right, #edd099, #ffaf00);
        background: linear-gradient(to bottom right, #edd099, #ffaf00);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .service-section .service-wrapper > ul > li a:hover {
        z-index: 2;
        color: #ffffff;
        -webkit-transform: scale(1.03);
        -ms-transform: scale(1.03);
        transform: scale(1.03);
        -webkit-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2); }
        .service-section .service-wrapper > ul > li a:hover .icon i {
          background: transparent !important;
          -webkit-text-fill-color: #ffffff !important; }
        .service-section .service-wrapper > ul > li a:hover:before {
          opacity: 1; }

/* about page - end
==================================================================================================== */
/* event page - start
==================================================================================================== */
.event-search-section {
  padding: 80px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .event-search-section .section-title .sub-title {
    color: #ffffff;
    letter-spacing: 1.5px;
    font-size: 14px; }
  .event-search-section .section-title .big-title {
    color: #ffffff; }
  .event-search-section .search-form ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: table; }
    .event-search-section .search-form ul li {
      float: left;
      width: 32.877%;
      list-style: none;
      margin-right: 5px;
      display: inline-block; }
      .event-search-section .search-form ul li:last-child {
        margin-right: 0px; }
      .event-search-section .search-form ul li .title {
        margin-bottom: 15px;
        color: #ffffff;
        font-size: 14px;
        text-transform: uppercase; }
      .event-search-section .search-form ul li input {
        border-radius: 3px; }
      .event-search-section .search-form ul li #event-category-select {
        width: 100%;
        height: 50px;
        border: none;
        font-weight: 600;
        padding: 0px 20px;
        border-radius: 3px;
        -webkit-appearance: none;}
        
      .event-search-section .search-form ul li .submit-btn {
        width: 100%;
        height: 50px;
        margin-top: 37px;
        font-weight: 700;
        padding: 0px 20px;
        text-align: center;
        border-radius: 3px;
        color: #ffffff;
        text-transform: uppercase;
        background-color: #333333; }

.event-section .search-result-form {
  position: relative;
  margin-bottom: 30px;
  padding-right: 100px; }
  .event-section .search-result-form ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: table; }
    .event-section .search-result-form ul li {
      float: left;
      min-width: 100px;
      list-style: none;
      margin-right: 30px;
      display: inline-block; }
      .event-section .search-result-form ul li:last-child {
        margin-right: 0px; }
      .event-section .search-result-form ul li .result-text {
        font-weight: 600;
        color: #878787;
        font-size: 16px; }
      .event-section .search-result-form ul li #year-select,
      .event-section .search-result-form ul li #munth-select {
        margin: 0px;
        border: none;
        padding: 0px;
        position: relative;
        padding-right: 20px;
        display: inline-block;
        -webkit-appearance: none;}
        
      .event-section .search-result-form ul li label {
        float: left;
        margin: 0px;
        margin-right: 5px; }
  .event-section .search-result-form .event-layout-btngroup {
    right: 0;
    bottom: 0;
    width: auto;
    display: table;
    position: absolute; }
    .event-section .search-result-form .event-layout-btngroup li {
      width: auto;
      min-width: auto;
      line-height: 0px;
      margin-right: 10px; }
      .event-section .search-result-form .event-layout-btngroup li:last-child {
        margin-right: 0px; }
      .event-section .search-result-form .event-layout-btngroup li a {
        line-height: 0px;
        color: #878787;
        font-size: 24px; }
      .event-section .search-result-form .event-layout-btngroup li > .active {
        color: #333333; }

.event-section .event-list-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #f0f0f0; }
  .event-section .event-list-item .event-image {
    float: left;
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin-right: 30px; }
    .event-section .event-list-item .event-image img {
      width: 100%;
      height: 100%;
      display: block; }
    .event-section .event-list-item .event-image .post-date {
      top: 0px;
      left: 0px;
      z-index: 1;
      width: 70px;
      height: 70px;
      padding: 10px;
      text-align: center;
      position: absolute;
      color: #ffffff;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
      .event-section .event-list-item .event-image .post-date .date {
        font-weight: 900;
        font-size: 24px;
        font-family: "Roboto", sans-serif; }
      .event-section .event-list-item .event-image .post-date .month {
        font-size: 16px;
        text-transform: uppercase; }
  .event-section .event-list-item .event-content {
    display: table;
    padding: 10px 0px; }
    .event-section .event-list-item .event-content .event-title {
      position: relative; }
      .event-section .event-list-item .event-content .event-title:after {
        top: 0;
        width: 6px;
        left: -33px;
        bottom: 0px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: #ffbe30; }
      .event-section .event-list-item .event-content .event-title .title {
        margin: 0px;
        font-weight: 400;
        color: #333333;
        font-size: 24px; }
        .event-section .event-list-item .event-content .event-title .title strong {
          font-weight: 900; }
    .event-section .event-list-item .event-content .event-info-list ul li {
      margin-right: 10px; }
      .event-section .event-list-item .event-content .event-info-list ul li:last-child {
        margin-right: 0px; }
      .event-section .event-list-item .event-content .event-info-list ul li .icon {
        float: left;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 10px;
        color: #ffffff;
        border-radius: 100%;
        background-color: #ffbe30; }
      .event-section .event-list-item .event-content .event-info-list ul li .info-content {
        display: table; }
        .event-section .event-list-item .event-content .event-info-list ul li .info-content small {
          font-weight: 700;
          font-size: 14px; }
        .event-section .event-list-item .event-content .event-info-list ul li .info-content h3 {
          font-weight: 900;
          font-size: 18px; }
    .event-section .event-list-item .event-content .tickets-details-btn {
      z-index: 1;
      font-weight: 600;
      overflow: hidden;
      padding: 15px 45px;
      position: relative;
      border-radius: 30px;
      color: #878787;
      font-size: 14px;
      text-transform: uppercase;
      background: #f7f7f7; }
      .event-section .event-list-item .event-content .tickets-details-btn:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        content: '';
        position: absolute;
        border-radius: 30px;
        background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
        background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
        background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
        background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .event-section .event-list-item .event-content .tickets-details-btn:hover {
        color: #ffffff;
        background: #ffbe30; }
        .event-section .event-list-item .event-content .tickets-details-btn:hover:before {
          opacity: 1; }
  .event-section .event-list-item:hover {
    border-color: #ffbe30; }

.event-section .event-grid-item {
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #f0f0f0; }
  .event-section .event-grid-item .event-image {
    width: 100%;
    height: 235px;
    overflow: hidden;
    position: relative; }
    .event-section .event-grid-item .event-image .post-date {
      top: 15px;
      left: 15px;
      z-index: 1;
      width: 70px;
      height: 70px;
      padding: 10px;
      text-align: center;
      position: absolute;
      color: #ffffff;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
      .event-section .event-grid-item .event-image .post-date .date {
        font-weight: 900;
        font-size: 24px;
        font-family: "Roboto", sans-serif; }
      .event-section .event-grid-item .event-image .post-date .month {
        font-size: 16px;
        text-transform: uppercase; }
  .event-section .event-grid-item .event-content {
    padding: 30px;
    background-color: #ffffff; }
    .event-section .event-grid-item .event-content .event-title {
      position: relative; }
      .event-section .event-grid-item .event-content .event-title:after {
        top: 0;
        width: 6px;
        left: -30px;
        bottom: 0px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: #ffbe30; }
      .event-section .event-grid-item .event-content .event-title .title {
        font-weight: 700;
        font-size: 24px; }
      .event-section .event-grid-item .event-content .event-title .ticket-price {
        font-weight: 700; }
    .event-section .event-grid-item .event-content .event-post-meta ul li {
      margin-bottom: 5px;
      font-size: 14px; }
      .event-section .event-grid-item .event-content .event-post-meta ul li .icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        text-align: center;
        border-radius: 100%;
        color: #ffffff;
        font-size: 14px;
        background-color: #ffbe30; }
  .event-section .event-grid-item .tickets-details-btn {
    z-index: 1;
    font-weight: 600;
    overflow: hidden;
    padding: 15px 45px;
    position: relative;
    border-radius: 30px;
    color: #878787;
    font-size: 14px;
    text-transform: uppercase;
    background: #f7f7f7; }
    .event-section .event-grid-item .tickets-details-btn:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: -1;
      content: '';
      position: absolute;
      border-radius: 30px;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .event-section .event-grid-item .tickets-details-btn:hover {
      color: #ffffff;
      background: #ffbe30; }
      .event-section .event-grid-item .tickets-details-btn:hover:before {
        opacity: 1; }
  .event-section .event-grid-item:hover {
    border: 1px solid #ffbe30; }

.event-section .sidebar-section .spacial-event-wrapper .overlay-black {
  padding: 50px 25px; }

.event-section .sidebar-section .spacial-event-wrapper .title-large {
  font-size: 24px; }

/* event page - end
==================================================================================================== */
/* event details page - start
==================================================================================================== */
.event-details-section .section-title .big-title {
  font-size: 36px; }

.event-details-section .event-details .event-title .tag-item {
  font-weight: 700;
  margin-bottom: 5px;
  color: #333333; }
  .event-details-section .event-details .event-title .tag-item i {
    margin-right: 5px;
    margin-bottom: 5px;
    color: #ffbe30; }

.event-details-section .event-details .event-title .event-title {
  margin: 0px;
  font-size: 36px; }
  .event-details-section .event-details .event-title .event-title strong {
    font-weight: 900; }

.event-details-section .event-details .event-details-carousel {
  margin-bottom: 30px; }
  .event-details-section .event-details .event-details-carousel .item {
    height: 380px;
    overflow: hidden; }

.event-details-section .event-details .owl-theme .owl-dots {
  display: none; }

.event-details-section .event-details .owl-theme .owl-nav {
  top: 0;
  right: 0;
  width: auto;
  padding: 10px;
  position: absolute; }
  .event-details-section .event-details .owl-theme .owl-nav .owl-next,
  .event-details-section .event-details .owl-theme .owl-nav .owl-prev {
    margin: 0px;
    width: 35px;
    margin: 5px;
    height: 35px;
    padding: 0px;
    overflow: hidden;
    position: relative;
    color: transparent;
    border-radius: 100%;
    background: transparent; }
    .event-details-section .event-details .owl-theme .owl-nav .owl-next:before,
    .event-details-section .event-details .owl-theme .owl-nav .owl-prev:before {
      top: 0;
      left: 0;
      width: 100%;
      opacity: .5;
      height: 100%;
      display: block;
      font-weight: 900;
      line-height: 35px;
      position: absolute;
      color: #333333;
      background-color: #ffffff;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      font-family: 'Font Awesome 5 Free'; }
    .event-details-section .event-details .owl-theme .owl-nav .owl-next:hover:before,
    .event-details-section .event-details .owl-theme .owl-nav .owl-prev:hover:before {
      opacity: 1; }
  .event-details-section .event-details .owl-theme .owl-nav .owl-next:before {
    content: '\f061'; }
  .event-details-section .event-details .owl-theme .owl-nav .owl-prev:before {
    content: '\f060'; }

.event-details-section .event-details .event-info-list ul {
  width: 100%;
  display: table; }
  .event-details-section .event-details .event-info-list ul li {
    width: 32.9%;
    padding: 15px;
    margin-right: 5px;
    border-radius: 3px;
    background-color: #f7f7f7; }
    .event-details-section .event-details .event-info-list ul li:last-child {
      margin-right: 0px; }
    .event-details-section .event-details .event-info-list ul li .icon {
      float: left;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 100%;
      color: #ffffff;
      font-size: 18px;
      background-color: #ffbe30; }
    .event-details-section .event-details .event-info-list ul li .event-content {
      display: table;
      padding-left: 15px; }
      .event-details-section .event-details .event-info-list ul li .event-content .event-title {
        font-weight: 700;
        color: #878787;
        font-size: 14px; }
      .event-details-section .event-details .event-info-list ul li .event-content .event-date {
        margin: 0px;
        font-weight: 900;
        color: #333333;
        font-size: 16px;
        text-transform: uppercase; }

.event-details-section .event-schedule .schedule-date-menu {
  margin: 0px;
  padding: 0px; }
  .event-details-section .event-schedule .schedule-date-menu > li {
    float: left;
    overflow: hidden;
    margin-right: 15px;
    display: inline-block;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .event-details-section .event-schedule .schedule-date-menu > li:last-child {
      margin-right: 0px; }
    .event-details-section .event-schedule .schedule-date-menu > li > a {
      width: 100%;
      height: 100%;
      display: block;
      font-weight: 900;
      padding: 15px 45px;
      text-align: center;
      color: #878787;
      font-size: 24px;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      background-color: #f7f7f7; }
      .event-details-section .event-schedule .schedule-date-menu > li > a small {
        width: 100%;
        display: block;
        font-weight: 700;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        text-transform: capitalize; }
    .event-details-section .event-schedule .schedule-date-menu > li > .active {
      color: #ffffff;
      background-color: #ffbe30; }

.event-details-section .event-schedule .tab-content .hotal-menu {
  z-index: 1;
  margin: 0px;
  padding: 30px;
  -webkit-box-shadow: 0 1px 16px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 16px 2px rgba(0, 0, 0, 0.1); }
  .event-details-section .event-schedule .tab-content .hotal-menu > li {
    float: left;
    height: 40px;
    width: 24.444%;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 3px;
    display: inline-block; }
    .event-details-section .event-schedule .tab-content .hotal-menu > li:last-child {
      margin-right: 0px; }
    .event-details-section .event-schedule .tab-content .hotal-menu > li > a {
      width: 100%;
      height: 100%;
      display: block;
      font-weight: 700;
      line-height: 40px;
      padding: 0px 30px;
      text-align: center;
      color: #878787;
      text-transform: capitalize;
      background-color: #f7f7f7; }
    .event-details-section .event-schedule .tab-content .hotal-menu > li > .active {
      color: #ffffff;
      background-color: #ffbe30; }

.event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item {
  padding: 30px;
  margin-bottom: 2px;
  background-color: #f7f7f7; }
  .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-image {
    width: 220px;
    float: right;
    height: 240px;
    overflow: hidden;
    border-radius: 3px; }
  .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content {
    display: table;
    padding-right: 15px; }
    .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li {
      margin-right: 15px; }
      .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li:last-child {
        margin-right: 0px; }
      .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li .icon {
        float: left;
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 100%;
        color: #ffffff;
        font-size: 18px;
        background-color: #ffbe30; }
      .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li .event-content {
        display: table;
        padding-left: 5px; }
        .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li .event-content .event-title {
          font-weight: 700;
          color: #878787;
          font-size: 14px; }
        .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li .event-content .event-date {
          margin: 0px;
          font-weight: 900;
          color: #333333;
          font-size: 16px;
          text-transform: uppercase; }

.event-details-section .event-pricing-plan .pricing-list ul .pricing-table {
  width: 33.333%;
  overflow: hidden;
  padding: 50px 30px;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #f7f7f7; }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .popularity-sign {
    top: 35px;
    left: -50px;
    min-width: 200px;
    padding: 5px 15px;
    position: absolute;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #85b200;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-header .amount {
    font-size: 54px;
    font-weight: 400;
    color: #ffbe30; }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-header .pricing-table-title {
    margin: 0px;
    font-weight: 900;
    color: #333333;
    font-size: 18px;
    text-transform: uppercase; }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body {
    padding: 30px 0px; }
    .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body ul {
      margin: 0px;
      padding: 0px; }
      .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body ul li {
        width: 100%;
        padding: 8px;
        display: block;
        list-style: none;
        text-align: left;
        position: relative;
        padding-left: 35px;
        border-bottom: 1px solid #dddddd; }
        .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body ul li:last-child {
          border-bottom: none; }
        .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body ul li:before {
          top: 50%;
          left: 5px;
          font-weight: 900;
          position: absolute;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-family: 'Font Awesome 5 Free'; }
      .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body ul .item-on:before {
        color: green;
        content: '\f00c'; }
      .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-body ul .item-off:before {
        color: red;
        content: '\f00d'; }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-footer {
    text-align: center; }
    .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-footer .custom-btn {
      width: 100%;
      display: block;
      font-weight: 700;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 12px 40px;
      background: #ffffff;
      text-transform: uppercase;
      color: #333333 !important; }
      .event-details-section .event-pricing-plan .pricing-list ul .pricing-table .pricing-footer .custom-btn:before {
        opacity: 0; }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table:hover .pricing-footer .custom-btn {
    color: #ffffff !important; }
    .event-details-section .event-pricing-plan .pricing-list ul .pricing-table:hover .pricing-footer .custom-btn:before {
      opacity: 1; }
    .event-details-section .event-pricing-plan .pricing-list ul .pricing-table:hover .pricing-footer .custom-btn:after {
      opacity: 0; }

.event-details-section .event-pricing-plan .pricing-list ul .popular-pricing-table {
  z-index: 1;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1); }
  .event-details-section .event-pricing-plan .pricing-list ul .popular-pricing-table .pricing-footer .custom-btn {
    color: #ffffff !important; }
    .event-details-section .event-pricing-plan .pricing-list ul .popular-pricing-table .pricing-footer .custom-btn:before {
      opacity: 1; }
    .event-details-section .event-pricing-plan .pricing-list ul .popular-pricing-table .pricing-footer .custom-btn:after {
      opacity: 0; }

.event-details-section .register-wrapper .register-table ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: table;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #f0f0f0; }
  .event-details-section .register-wrapper .register-table ul li {
    width: 100%;
    display: table;
    font-size: 14px; }
  .event-details-section .register-wrapper .register-table ul .rt-header {
    border-radius: 3px;
    padding: 15px 30px;
    color: rgba(255, 255, 255, 0.8);
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
    .event-details-section .register-wrapper .register-table ul .rt-header .header-leftside-content {
      width: 70%;
      float: left; }
      .event-details-section .register-wrapper .register-table ul .rt-header .header-leftside-content strong {
        color: #ffffff; }
    .event-details-section .register-wrapper .register-table ul .rt-header .header-rightside-content {
      width: 30%;
      float: right;
      text-align: right; }
  .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item {
    width: 100%;
    display: table;
    padding: 15px 30px;
    border-bottom: 1px solid #f0f0f0; }
    .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item .leftside-content {
      width: 80%;
      float: left; }
      .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item .leftside-content .ticket-title {
        margin: 0px;
        font-size: 24px; }
      .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item .leftside-content .ticket-price {
        width: 100%;
        display: table;
        font-weight: 700; }
    .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item .rightside-content {
      width: 20%;
      float: right; }
      .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item .rightside-content .ticket-select {
        width: 100%;
        height: 45px;
        position: relative;
        border-radius: 3px;
        background-color: #f7f7f7; }
        .event-details-section .register-wrapper .register-table ul .rt-body .rt-body-item .rightside-content .ticket-select select {
          width: 100%;
          height: 100%;
          display: block;
          font-weight: 700;
          padding: 0px 20px;
          border-radius: 3px;
          color: #333333;
          -webkit-appearance: none;
          border: 1px solid #f0f0f0;
        }
  .event-details-section .register-wrapper .register-table ul .rt-footer {
    padding: 15px 30px; }
    .event-details-section .register-wrapper .register-table ul .rt-footer .footer-leftside-content {
      width: 80%;
      float: left;
      padding: 11px 0px; }
      .event-details-section .register-wrapper .register-table ul .rt-footer .footer-leftside-content .payment-card {
        margin: -5px 0px;
        margin-right: 30px; }
      .event-details-section .register-wrapper .register-table ul .rt-footer .footer-leftside-content .total-price {
        font-weight: 700;
        color: #333333; }
    .event-details-section .register-wrapper .register-table ul .rt-footer .footer-rightside-content {
      width: 20%;
      float: right; }
      .event-details-section .register-wrapper .register-table ul .rt-footer .footer-rightside-content .custom-btn {
        width: 100%;
        height: 45px;
        padding: 0px;
        display: block;
        font-weight: 700;
        -webkit-box-shadow: none;
        box-shadow: none;
        line-height: 45px;
        border-radius: 3px;
        font-size: 14px;
        text-transform: uppercase; }
        .event-details-section .register-wrapper .register-table ul .rt-footer .footer-rightside-content .custom-btn:after, .event-details-section .register-wrapper .register-table ul .rt-footer .footer-rightside-content .custom-btn:before {
          border-radius: 3px; }

.event-details-section .reviewer-comment-wrapper .comment-bar {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #878787; }
  .event-details-section .reviewer-comment-wrapper .comment-bar .admin-image {
    float: left;
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 30px;
    text-align: center;
    border-radius: 100%;
    background-color: #f7f7f7; }
  .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content {
    display: table; }
    .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .admin-name .rateing-star ul li {
      color: #878787;
      font-size: 14px; }
    .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .admin-name .rateing-star ul .rated {
      color: #ffbe30; }
    .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .admin-name .name a {
      font-weight: 700;
      color: #333333;
      font-size: 30px;
      text-transform: capitalize; }
    .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left {
      padding: 10px 0px; }
      .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left .title {
        float: left;
        font-weight: 700;
        margin-right: 15px; }
      .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left ul {
        padding: 0px;
        display: table;
        margin: -10px 0px;
        overflow: hidden;
        border-radius: 3px;
        border: 1px solid #878787; }
        .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left ul li {
          float: left;
          height: 40px;
          min-width: 40px;
          text-align: center;
          display: inline-block;
          border-right: 1px solid #878787; }
          .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left ul li:last-child {
            border-right: none; }
          .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left ul li button {
            width: 100%;
            height: 100%;
            display: block;
            font-weight: 600;
            padding: 0px 15px;
            line-height: 40px;
            color: #878787; }
            .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left ul li button:hover {
              color: #ffbe30; }
    .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul {
      margin: 0px;
      padding: 0px;
      float: right; }
      .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul li {
        float: left;
        height: 40px;
        min-width: 40px;
        margin-right: 10px;
        display: inline-block; }
        .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul li:last-child {
          margin-right: 0px; }
        .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul li button {
          width: 100%;
          height: 100%;
          display: block;
          font-weight: 600;
          padding: 0px 15px;
          line-height: 40px;
          border-radius: 3px;
          color: #878787;
          border: 1px solid #878787; }
          .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul li button:hover {
            color: #ffbe30;
            border-color: #ffbe30; }

.event-details-section .pagination {
  display: block; }
  .event-details-section .pagination ul {
    float: right; }

.event-details-section .comment-form .section-title {
  position: relative; }
  .event-details-section .comment-form .section-title .rateing-star-wrapper {
    right: 0;
    bottom: 5px;
    display: table;
    position: absolute; }
    .event-details-section .comment-form .section-title .rateing-star-wrapper .rating-title {
      font-weight: 700;
      font-size: 16px; }
    .event-details-section .comment-form .section-title .rateing-star-wrapper .rateing-star-form .form-check {
      margin: 0px;
      text-align: center;
      line-height: normal;
      display: inline-block; }
      .event-details-section .comment-form .section-title .rateing-star-wrapper .rateing-star-form .form-check input[type=checkbox] {
        position: relative; }
        .event-details-section .comment-form .section-title .rateing-star-wrapper .rateing-star-form .form-check input[type=checkbox]:before {
          top: 4px;
          left: -8px;
          cursor: pointer;
          content: "\f005";
          font-weight: 900;
          line-height: 0px;
          position: absolute;
          color: #878787;
          font-size: 24px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          font-family: 'Font Awesome 5 Free'; }
        .event-details-section .comment-form .section-title .rateing-star-wrapper .rateing-star-form .form-check input[type=checkbox]:hover:before {
          color: #ffbe30; }
      .event-details-section .comment-form .section-title .rateing-star-wrapper .rateing-star-form .form-check input[type=checkbox]:checked:before {
        color: #ffbe30; }

.event-details-section .comment-form .form-wrapper .custom-btn {
  -webkit-box-shadow: none;
  box-shadow: none; }

/* event details page - end
==================================================================================================== */
/* service page - start
==================================================================================================== */
.service-section .service-wrapper .service-item {
  margin-bottom: 50px; }
  .service-section .service-wrapper .service-item:last-child {
    margin-bottom: 0px; }
  .service-section .service-wrapper .service-item .service-image {
    width: 48%;
    position: relative;
    padding-right: 70px;
    padding-bottom: 70px; }
    .service-section .service-wrapper .service-item .service-image .big-image {
      overflow: hidden;
      border-radius: 3px;
      -webkit-box-shadow: 0px 0px 60px 3px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 60px 3px rgba(0, 0, 0, 0.2); }
    .service-section .service-wrapper .service-item .service-image .small-image {
      z-index: 1;
      right: 0px;
      bottom: 0px;
      overflow: hidden;
      border-radius: 3px;
      position: absolute;
      -webkit-box-shadow: 0px 0px 60px 3px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 60px 3px rgba(0, 0, 0, 0.2); }
  .service-section .service-wrapper .service-item .service-content {

    padding: 0px 30px; }
    .service-section .service-wrapper .service-item .service-content .service-title {
      padding-bottom: 30px;
      border-bottom: 1px solid #f0f0f0; }
      .service-section .service-wrapper .service-item .service-content .service-title .title-text {
        font-weight: 400;
        font-size: 30px; }
        .service-section .service-wrapper .service-item .service-content .service-title .title-text strong {
          font-weight: 900; }
      .service-section .service-wrapper .service-item .service-content .service-title .service-price {
        font-weight: 900;
        color: #ffbe30; }
    .service-section .service-wrapper .service-item .service-content .service-type-list ul {
      width: 100%;
      margin: 0px;
      padding: 0px;
      display: table; }
      .service-section .service-wrapper .service-item .service-content .service-type-list ul li {
        width: 50%;
        float: left;
        list-style: none;
        font-weight: 700;
        margin-bottom: 5px;
        padding-right: 15px;
        display: inline-block;
        font-size: 14px;
        text-transform: uppercase; }
        .service-section .service-wrapper .service-item .service-content .service-type-list ul li .icon {
          width: 30px;
          height: 30px;
          line-height: 30px;
          margin-right: 5px;
          text-align: center;
          color: #ffffff;
          border-radius: 100%;
          font-size: 14px;
          background-color: #ffbe30; }
    .service-section .service-wrapper .service-item .service-content .custom-btn {
      -webkit-box-shadow: none;
      box-shadow: none; }
    .service-section .service-wrapper .service-item .service-content .conference-btn {
      font-weight: 700;
      padding: 12px 35px;
      border-radius: 30px;
      color: #ffbe30;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #ffffff;
      border: 3px solid #ffbe30; }
      .service-section .service-wrapper .service-item .service-content .conference-btn:hover {
        color: #ffffff;
        background-color: #ffbe30; }

/* service page - end
==================================================================================================== */
/* blog section - start
==================================================================================================== */
.blog-section .layout-btn-group {
  width: 100%;
  display: table;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0; }
  .blog-section .layout-btn-group h3 {
    margin: 0px;
    font-weight: 400;
    font-size: 24px; }

.blog-section .blog-layout-menubar {
  margin: 0px;
  padding: 0px; }
  .blog-section .blog-layout-menubar li {
    float: left;
    width: 40px;
    height: 40px;
    margin: -6px 0px;
    margin-right: 5px;
    display: inline-block; }
    .blog-section .blog-layout-menubar li:last-child {
      margin-right: 0px; }
    .blog-section .blog-layout-menubar li a {
      width: 100%;
      height: 100%;
      display: block;
      line-height: 40px;
      border-radius: 5px;
      text-align: center;
      font-size: 18px;
      color: #f0f0f0;
      background-color: #ffffff;
      border: 1px solid #f0f0f0; }
      .blog-section .blog-layout-menubar li a:hover {
        color: #ffbe30; }
    .blog-section .blog-layout-menubar li .active {
      color: #ffbe30; }

.blog-section .tag {
  font-weight: 600;
  color: #ffbe30;
  font-size: 14px;
  text-transform: capitalize; }

.blog-section .blog-title {
  font-weight: 700;
  padding: 10px 0px;
  font-size: 18px;
  border-bottom: 1px solid #f0f0f0; }

.blog-section .tickets-details-btn {
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
  padding: 15px 30px;
  position: relative;
  border-radius: 3px;
  color: #878787;
  font-size: 14px;
  text-transform: uppercase;
  background: #f7f7f7; }
  .blog-section .tickets-details-btn:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    content: '';
    position: absolute;
    border-radius: 3px;
    background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
    background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
    background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
    background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .blog-section .tickets-details-btn:hover {
    color: #ffffff;
    background: #ffbe30; }
    .blog-section .tickets-details-btn:hover:before {
      opacity: 1; }

.blog-section .blog-grid-item {
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  background-color: #f7f7f7; }
  .blog-section .blog-grid-item .event-date {
    top: 15px;
    left: 20px;
    z-index: 1;
    font-weight: 700;
    position: absolute;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); }
  .blog-section .blog-grid-item .blog-image {
    overflow: hidden;
    position: relative; }
  .blog-section .blog-grid-item .blog-content {
    padding: 25px; }
    .blog-section .blog-grid-item .blog-content .tickets-details-btn {
      background: #ffffff; }
  .blog-section .blog-grid-item:hover {
    border-color: #ffbe30; }
    .blog-section .blog-grid-item:hover .plus-effect {
      opacity: 1; }

.blog-section .blog-grid-item.without-image .event-date {
  padding: 30px;
  position: static;
  text-shadow: none;
  color: #333333;
  font-size: 18px;
  border-bottom: 2px solid #f0f0f0; }

.blog-section .blog-list-item {
  margin-bottom: 50px; }
  .blog-section .blog-list-item .blog-image {
    width: 50%;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2); }
    .blog-section .blog-list-item .blog-image .event-date {
      top: 15px;
      left: 20px;
      z-index: 1;
      font-weight: 700;
      position: absolute;
      color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); }
  .blog-section .blog-list-item .blog-content {
    width: 50%;
    padding: 0px 30px; }
  .blog-section .blog-list-item:hover .plus-effect {
    opacity: 1; }

.blog-section .blog-big-item {
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  background-color: #f7f7f7; }
  .blog-section .blog-big-item .blog-image {
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2); }
    .blog-section .blog-big-item .blog-image .event-date {
      top: 15px;
      left: 20px;
      z-index: 1;
      font-weight: 700;
      position: absolute;
      color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); }
  .blog-section .blog-big-item .blog-content {
    padding: 15px;
    padding-top: 30px; }
    .blog-section .blog-big-item .blog-content .tickets-details-btn {
      background: #ffffff; }
  .blog-section .blog-big-item:hover .plus-effect {
    opacity: 1; }

.blog-section .pagination {
  margin-top: 70px; }
  .blog-section .pagination ul {
    display: table;
    margin: 0 auto; }

/* blog section - end
==================================================================================================== */
/* faq section - start
==================================================================================================== */
.faq-section .faq-content-wrapper .faq-accordion .card {
  border: none;
  position: relative;
  border-radius: 0px; }
  .faq-section .faq-content-wrapper .faq-accordion .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    margin-bottom: 0;
    border-radius: 0px; }
    .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn {
      width: 100%;
      border: none;
      display: block;
      font-weight: 900;
      text-align: left;
      padding: 15px 30px;
      border-radius: 0px;
      position: relative;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      background-color: #ffffff;
      border-left: 3px solid transparent;
      border-bottom: 1px solid #f0f0f0; }
      .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn span {
        margin-right: 5px;
        color: #878787; }
      .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn:after {
        top: 50%;
        right: 30px;
        content: '\f0aa';
        font-weight: 900;
        position: absolute;
        color: #878787;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free'; }
      .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn:not(.collapsed) {
        background-color: #f7f7f7;
        border-left: 3px solid #ffbe30;
        border-bottom: 1px solid #ffffff; }
        .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn:not(.collapsed):after {
          content: '\f0ab';
          color: #ffbe30; }
  .faq-section .faq-content-wrapper .faq-accordion .card .card-body {
    padding: 30px;
    color: #333333;
    background-color: #f7f7f7;
    border-left: 3px solid #ffbe30; }
    .faq-section .faq-content-wrapper .faq-accordion .card .card-body > h3 {
      font-weight: 700;
      margin-bottom: 15px;
      color: #ffbe30;
      font-size: 18px; }

/* faq section - end
==================================================================================================== */
/* booking page - start
==================================================================================================== */
.booking-timeout-section {
  padding: 80px 0px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper {
    width: 100%;
    display: table;
    margin: -24px 0px; }
    .booking-timeout-section .rightside-wrapper .clock-wrapper ul {
      margin: 0px;
      padding: 0px;
      float: right;
      display: table; }
      .booking-timeout-section .rightside-wrapper .clock-wrapper ul li {
        float: left;
        width: 100px;
        height: auto;
        margin: 0px 5px;
        overflow: hidden;
        list-style: none;
        text-align: center;
        border-radius: 5px;
        display: inline-block;
        -webkit-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2); }
        .booking-timeout-section .rightside-wrapper .clock-wrapper ul li:last-child {
          margin-right: 0px; }
        .booking-timeout-section .rightside-wrapper .clock-wrapper ul li strong {
          width: 100%;
          display: block;
          font-weight: 700;
          text-align: center;
          padding: 20px 10px;
          color: #333333;
          font-size: 36px;
          font-family: "Roboto", sans-serif;
          background-color: #ffffff;
          border-bottom: 2px solid #f0f0f0; }
        .booking-timeout-section .rightside-wrapper .clock-wrapper ul li small {
          width: 100%;
          display: block;
          font-weight: 700;
          padding: 5px 10px;
          color: #ffbe30;
          text-transform: uppercase; }

.booking-section .section-title .big-title {
  font-size: 30px; }

.booking-section .booking-content-wrapper .order-summary-table {
  margin: 0px;
  padding: 30px;
  border-radius: 3px;
  background-color: #ffffff; }
  .booking-section .booking-content-wrapper .order-summary-table .table {
    margin: 0px; }
    .booking-section .booking-content-wrapper .order-summary-table .table thead tr th {
      color: #ffbe30;
      font-size: 16px;
      text-transform: uppercase; }
    .booking-section .booking-content-wrapper .order-summary-table .table tr {
      border: none; }
      .booking-section .booking-content-wrapper .order-summary-table .table tr th,
      .booking-section .booking-content-wrapper .order-summary-table .table tr td {
        border: 0px;
        padding: 15px;
        font-weight: 700;
        color: #333333; }
      .booking-section .booking-content-wrapper .order-summary-table .table tr td input[type=text] {
        width: 50px;
        border: none;
        display: table;
        margin: 0 auto;
        background: none;
        text-align: center;
        background-color: red;}
       
        .booking-section .booking-content-wrapper .order-summary-table .table tr td input[type=text]:focus {
          outline: none; }
    .booking-section .booking-content-wrapper .order-summary-table .table .tfooter {
      color: #ffffff;
      text-transform: uppercase;
      background: -webkit-gradient(linear, left top, right bottom, from(#ff3e00), to(#ffbe30));
      background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
      background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
      background: linear-gradient(to bottom right, #ff3e00, #ffbe30); }
      .booking-section .booking-content-wrapper .order-summary-table .table .tfooter th,
      .booking-section .booking-content-wrapper .order-summary-table .table .tfooter td {
        color: #ffffff; }

.booking-section .booking-content-wrapper .reg-info .form-title {
  font-weight: 900;
  color: #333333;
  margin-bottom: 15px;
  font-size: 24px;
  text-transform: uppercase; }

.booking-section .booking-content-wrapper .reg-info .form-wrapper .form-item {
  margin-bottom: 5px; }
  .booking-section .booking-content-wrapper .reg-info .form-wrapper .form-item input {
    border-radius: 3px;
    background-color: #ffffff; }
  .booking-section .booking-content-wrapper .reg-info .form-wrapper .form-item .cradit-card-select {
    width: 100%;
    height: 100%;
    display: block;
    font-weight: 700;
    padding: 0px 20px;
    border-radius: 3px;
    color: #333333;
    -webkit-appearance: none;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid #f0f0f0;
  }
    .booking-section .booking-content-wrapper .reg-info .form-wrapper .form-item .cradit-card-select:focus {
      border-color: #ffbe30; }

.booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form {
  margin-top: 20px; }
  .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form .title-text {
    font-weight: 700;
    margin-bottom: 19px;
    font-size: 18px; }
  .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: table; }
    .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul li {
      float: left;
      width: 32.4%;
      list-style: none;
      margin-right: 5px;
      display: inline-block; }
      .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul li:last-child {
        margin-right: 0px; }
      .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul li select {
        width: 100%;
        height: 100%;
        display: block;
        font-weight: 700;
        padding: 0px 20px;
        border-radius: 3px;
        color: #333333;
        -webkit-appearance: none;
        text-transform: capitalize;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border: 1px solid #f0f0f0;}
       
        .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul li select:focus {
          border-color: #ffbe30; }

.booking-section .booking-content-wrapper .billing-form .form-item {
  margin-bottom: 5px; }
  .booking-section .booking-content-wrapper .billing-form .form-item select {
    width: 100%;
    height: 100%;
    display: block;
    font-weight: 700;
    padding: 0px 20px;
    border-radius: 3px;
    color: #333333;
    -webkit-appearance: none;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid #f0f0f0;
  }
    .booking-section .booking-content-wrapper .billing-form .form-item select:focus {
      border-color: #ffbe30; }
  .booking-section .booking-content-wrapper .billing-form .form-item input {
    border-radius: 3px;
    background-color: #ffffff; }

.booking-section .booking-content-wrapper .billing-form .form-item-group ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: table; }
  .booking-section .booking-content-wrapper .billing-form .form-item-group ul li {
    float: left;
    width: 32.88%;
    list-style: none;
    margin-right: 5px;
    display: inline-block; }
    .booking-section .booking-content-wrapper .billing-form .form-item-group ul li:last-child {
      margin-right: 0px; }

.booking-section .sidebar-section .location-wrapper .location-info-list .area-name {
  background-color: #f7f7f7; }

/* booking page - end
==================================================================================================== */
/* contact section - start
==================================================================================================== */
.contact-section .contact-form .form-item {
  margin-bottom: 30px; }
  .contact-section .contact-form .form-item input {
    border-radius: 3px; }

.contact-section .contact-form textarea {
  border-radius: 3px;
  margin-bottom: 30px; }

/* contact section - end
==================================================================================================== */
/* error section - start
==================================================================================================== */
.error-section .icon {
  width: 360px;
  height: 360px;
  font-size: 200px;
  text-align: center;
  line-height: 360px;
  margin: 0 auto 30px;
  border-radius: 100%;
  color: #878787;
  background-color: #f0f0f0; }

.error-section .error-content h2 {
  font-size: 90px;
  font-weight: 900; }

.error-section .error-content h3 {
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase; }

.error-section .error-content .custom-btn {
  -webkit-box-shadow: none;
  box-shadow: none; }

/* error section - end
==================================================================================================== */
/* ================================================== 
* Version: 1.0.0
* css code for responsive layout
================================================== */
/* ==================================================
* 1 - media screen and (max-width: 1199px)
* 2 - media screen and (max-width: 991px)
* 3 - media screen and (max-width: 767px)
* 4 - media screen and (max-width: 680px)
* 5 - media screen and (max-width: 480px)
* 6 - media screen and (max-width: 320px)
================================================== */
@media screen and (max-width: 1349px) {
  .event-gallery-section .grid-item,
  .event-gallery-section .grid-sizer {
    width: 25%;
    max-height: 280px;
    overflow: hidden; }
  .event-gallery-section .grid-item {
    float: left;
    display: inline-block; }
  .event-gallery-section .grid-item--width2 {
    width: 50%;
    max-height: 280px; }
  .event-gallery-section .grid-item--height2 {
    width: 25%;
    max-height: 560px; } }

/*  ====================================================================================================
1 - media screen and (max-width: 1199px) - start
==================================================================================================== */
@media screen and (max-width: 1199px) {
  .upcomming-event-section .owl-theme .owl-nav .owl-prev {
    left: 5px; }
  .upcomming-event-section .owl-theme .owl-nav .owl-next {
    right: 5px; }
  .about-section .about-item-wrapper ul li .about-item .title {
    font-size: 14px; }
  .conference-section .tab-wrapper .tab-menu .tab-nav {
    padding: 0px 30px; }
  .special-offer-section .special-offer-content h2 {
    font-size: 30px; }
  .special-offer-section .event-makeing-btn .custom-btn {
    padding: 15px 30px; }
  .event-section .section-title .big-title {
    font-size: 36px; }
  .event-section .event-tab-menu .nav li {
    margin-right: 20px; }
  .event-section .event-tab-menu .nav li a {
    font-size: 16px; }
  .section-title .line-style {
    top: -15px;
    left: 0px; }
  .event-section .tab-content .event-item {
    padding: 10px; }
  .event-section .tab-content .event-item .event-image {
    width: 215px;
    margin-right: 15px; }
  .event-section .tab-content .event-item .event-content .event-title:after {
    left: -18px; }
  .event-gallery-section .grid-item .item-content {
    padding: 20px; }
  .event-expertise-section .event-expertise-carousel .item .expertise-item .content {
    z-index: 1;
    left: 15px;
    right: 15px;
    bottom: -30px;
    padding: 30px 15px; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-image {
    padding: 0px; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-image .speaker-name {
    display: none; }
  .section-title .big-title {
    font-size: 36px; }
  .main-carousel2 .item .slider-content .title-text,
  .main-carousel2 .item .slider-content .bold-text {
    font-size: 54px; }
  .main-carousel2 .slick-next {
    right: 6%; }
  .event-advertisement-section .right-content-wrapper .countdown-timer ul li {
    padding: 15px 15px; }
  .event-advertisement-section .right-content-wrapper .booking-btn {
    padding: 15px 25px;
    margin-right: 5px; }
  .event-advertisement-section .right-content-wrapper .details-btn {
    padding: 12px 25px; }
  .homepage2 .event-gallery-section .section-title .big-title {
    font-size: 30px; }
  .footer-section3 .footer-top .basic-info .basic-info-item .info-content h3 {
    margin: 0px;
    font-size: 16px; }
  .speaker-section .speaker-carousel .slider-nav .item .item-content {
    padding: 10px;
    padding-top: 45px; }
  .news-update-section .latest-blog-wrapper .latest-blog .blog-content .blog-title h3 {
    font-size: 16px; }
  .absolute-eventmake-section .eventmaking-wrapper {
    top: 0;
    left: 0;
    width: 90%;
    position: unset;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
  .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li {
    margin: 0;
    width: 50%;
    border: 2px solid transparent; }
  .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li a {
    background-color: transparent; }
  .about-section2 .services-list ul li a {
    padding: 50px 20px; }
  .about-section2 .services-list ul li a .icon {
    margin-right: 15px; }
  .upcomming-event-section2 .comming-event-item .event-content {
    padding-right: 0; }
  .upcomming-event-section2 .comming-event-item .event-image {
    padding: 0px; }
  .upcomming-event-section2 .comming-event-item .event-image .small-image {
    top: 70%;
    left: 30px; }
  .upcomming-event-section3 .comming-event-item .event-content {
    padding-right: 0px; }
  .upcomming-event-section3 .comming-event-item .event-image {
    padding: 0px; }
  .upcomming-event-section3 .comming-event-item .event-image .small-image {
    right: 15px;
    bottom: -50px;
    width: 150px;
    height: 180px; }
  .homepage4 .event-gallery-section .section-title .big-title {
    font-size: 30px; }
  .awesome-event-section .tab-content .awesome-event-item .event-content .event-title .title-text {
    font-size: 16px; }
  .awesome-event-section .tab-content .awesome-event-item .event-content {
    padding: 15px; }
  .awesome-event-section .tab-content .awesome-event-item .event-content .custom-btn {
    padding: 15px 25px; }
  .absolute-sponsor-section {
    padding: 50px 15px; }
    .absolute-sponsor-section ul {
      top: 0;
      left: 0;
      width: 100%;
      position: unset;
      -webkit-transform: translateX(0%);
      -ms-transform: translateX(0%);
      transform: translateX(0%); }
  .homepage4 .news-update-section .latest-blog-wrapper .latest-blog .blog-content .blog-title h3 {
    font-size: 14px; }
  .default-footer-section .footer-top .instagram-wrapper ul li {
    width: 100px;
    height: 100px; }
  .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > li > a {
    padding: 5px 12px; }
  .breadcrumb-section .breadcrumb-title {
    padding: 0px; }
  .service-section .service-wrapper > ul > li a .service-sub-title {
    font-size: 12px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul li {
    margin: 0px 2px; }
  .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul li {
    margin-right: 2px; }
  .booking-section .booking-content-wrapper .billing-form .form-item-group ul li {
    margin-right: 2px; }
  .event-search-section .search-form ul li {
    margin-right: 2px; }
  .event-section .sidebar-section .spacial-event-wrapper .title-large {
    font-size: 18px; }
  .event-section .sidebar-section .spacial-event-wrapper .overlay-black {
    padding: 30px 15px; }
  .event-section .event-grid-item .event-image {
    height: auto; }
  .event-details-section .event-details .event-details-carousel .item img {
    width: 100%;
    height: 100%;
    display: block; }
  .event-details-section .event-details .event-info-list ul li {
    margin-right: 2px; }
  .event-details-section .event-schedule .schedule-date-menu > li {
    margin-right: 2px; }
  .event-details-section .event-schedule .schedule-date-menu > li > a {
    padding: 15px 30px; }
  .event-details-section .event-schedule .tab-content .hotal-menu > li {
    margin-right: 2px; }
  .service-section .service-wrapper .service-item .service-content .service-type-list ul li {
    font-size: 12px; }
  .default-header-section .header-bottom .menu-item-list > ul > li > a {
    padding: 5px 12px; }
  .sticky-header-section .header-bottom .menu-item-list > ul > li > a {
    padding: 5px 10px; }
  .event-section .tab-content .event-item .event-content .tickets-details-btn {
    padding: 15px 30px; }
  .special-offer-section .event-makeing-btn a {
    padding: 15px 30px; }
  .scrolltop-fixed-header-section .header-bottom .menu-item-list > ul > li > a {
    padding: 5px 8px; }
  .awesome-event-section .tab-content .awesome-event-item .event-content .tickets-details-btn {
    padding: 15px 25px; }
  .error-section .icon {
    /* width: 300px;
    height: 300px; */
    font-size: 180px;
    line-height: 300px; }
  .error-section .error-content h3 {
    font-size: 18px; }
  .our-management-section .owl-theme .owl-dots button {
    width: 86px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul li {
    width: 90px;
    margin: 5px; } }

/* ====================================================================================================
1 - media screen and (max-width: 1199px) - end
==================================================================================================== */
/* ====================================================================================================
2 - media screen and (max-width: 991px) - start --->>>for medium device
==================================================================================================== */
@media screen and (max-width: 991px) {
  .header-section {
    display: none; }
  .header-altranative {
    display: block; }

  .slide-section .main-carousel1 .item .slider-item-content {
    padding: 100px;
    padding-bottom: 250px; }
  .upcomming-event-section .upcomming-event-carousel .item .event-item .event-content {
    right: 60px; }
  .about-section .about-item-wrapper {
    padding-left: 0px; }
  .conference-section .conference-location ul {
    float: none;
    margin: 0 auto; }
  .conference-section .tab-wrapper .tab-content {
    top: 0;
    right: 0;
    width: 100%;
    display: table;
    position: unset; }
  .conference-section .tab-wrapper .tab-menu {
    max-width: 460px;
    margin: 0 auto 30px; }
  .special-offer-section {
    text-align: center; }
  .special-offer-section .event-makeing-btn {
    padding: 0px;
    margin-top: 50px;
    text-align: center; }
  .section-title .line-style {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .section-title {
    text-align: center !important; }
  .event-section .event-tab-menu .nav {
    float: none;
    margin: 0 auto; }
  .event-section .tab-content .event-item {
    padding: 15px;
    max-width: 570px;
    margin: 0  auto 30px; }
  .event-section .tab-content .event-item .event-image {
    width: 250px;
    height: 250px;
    margin-right: 30px; }
  .event-section .tab-content .event-item .event-content .event-title:after {
    left: -33px; }
  .event-gallery-section .grid-item, .event-gallery-section .grid-sizer {
    width: 50%;
    max-height: 280px; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-content {
    text-align: center; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-image {
    display: table;
    margin: 0 auto; }
  .speaker-section .speaker-carousel .slider-nav {
    width: 100%;
    position: unset;
    padding-bottom: 30px; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info .speaker-social-network ul {
    margin: 0 auto; }
  .advertisement-section .advertisement-content {
    padding: 0px; }
  .partners-clients-section .section-title {
    padding: 0px; }
  .partners-clients-section .partners-wrapper {
    margin-bottom: 30px; }
  .news-update-section .faq-accordion {
    margin: 0 auto;
    display: table;
    max-width: 570px;
    margin-bottom: 80px; }
  .news-update-section .latest-blog-wrapper .latest-blog {
    display: table;
    max-width: 570px;
    margin: 0 auto 30px; }
  .footer-section .footer-top .about-wrapper,
  .footer-section .footer-top .usefullinks-wrapper {
    width: 100%;
    padding: 0px;
    display: table;
    margin-bottom: 30px; }
  .footer-section .footer-bottom .copyright-text {
    text-align: center;
    margin-bottom: 15px; }
  .footer-section .footer-bottom .footer-menu ul {
    float: none;
    margin: 0 auto; }
  .main-carousel2 .item .overlay-black {
    padding: 100px 0px; }
  .main-carousel2 .slick-prev,
  .main-carousel2 .item .reg-form {
    max-width: 360px;
    margin: 0 auto 30px; }
  .main-carousel2 .item .slider-content {
    padding: 0px;
    margin: 0 auto;
    max-width: 460px;
    text-align: center; }
  .main-carousel2 .slick-next,
  .main-carousel2 .slick-prev {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .main-carousel2 .slick-prev {
    left: 2%; }
  .main-carousel2 .slick-next {
    right: 2%; }
  .countdown-timer ul {
    display: table;
    margin: 0 auto; }
  .main-carousel2 .item .slider-content .title-text, .main-carousel2 .item .slider-content .bold-text {
    font-size: 48px; }
  .event-advertisement-section .event-item {
    width: 100%;
    display: table;
    margin-bottom: 30px; }
  .event-advertisement-section .right-content-wrapper {
    margin: 0 auto;
    max-width: 460px; }
    .event-advertisement-section .right-content-wrapper .countdown-timer ul {
      float: none;
      display: table;
      margin: 0 auto; }
    .event-advertisement-section .right-content-wrapper .text-right {
      text-align: center !important; }
  .testimonial5-section .item {
    padding: 0px 100px; }
  .footer-section3 .footer-top .about-wrapper {
    width: auto;
    display: table;
    margin: 0 auto;
    max-width: 360px;
    margin-bottom: 30px; }
  .footer-section3 .footer-top .basic-info .basic-info-item {
    margin-bottom: 30px; }
  .default-header-p {
    padding-top: 98px; }
  .absolute-eventmake-section {
    padding: 50px 0px; }
  .absolute-eventmake-section .eventmaking-wrapper {
    width: 100%;
    padding: 0px 15px; }
  .absolute-eventmake-section .eventmaking-wrapper .tab-content .top-event-location .title-text {
    float: none;
    margin: 0px;
    margin-bottom: 15px; }
  .about-section2 .section-pragraph {
    margin-bottom: 45px;
    text-align: center !important; }
  .about-section2 .services-list ul li {
    width: 50%; }
  .upcomming-event-section2 .comming-event-item .event-image {
    width: 100%;
    position: unset;
    padding: 0px 15px;
    position: relative; }
  .upcomming-event-section2 .comming-event-item .event-image .small-image {
    top: 72%; }
  .upcomming-event-section2 .comming-event-item .event-content {
    padding-right: 0;
    margin-bottom: 30px; }
  .homepage3 .event-expertise-section .link-btn {
    padding: 0px;
    margin-bottom: 50px;
    text-align: center !important; }
  .default-sponsor-section ul li {
    width: 50%;
    height: auto;
    border: 1px solid transparent; }
  .default-footer-section .footer-top .basic-info .basic-info-item {
    margin-bottom: 30px; }
  .register-modal .leftside-content, .register-modal .rightside-content {
    height: auto; }
  .reglog-modal-wrapper .leftside-content {
    display: none; }
  .reglog-modal-wrapper .rightside-content {
    float: none;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 50px 30px; }
  .reglog-modal-wrapper .rightside-content .or-text span:after,
  .reglog-modal-wrapper .rightside-content .or-text span:before {
    display: none; }
  .reglog-modal-wrapper {
    margin: 0 auto;
    max-width: 460px;
    border-radius: 3px; }
  .upcomming-event-section3 .comming-event-item .event-image {
    margin-bottom: 80px; }
  .awesome-event-section .tab-content .featured-event {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 60px; }
  .awesome-event-section .event-content-wrapper .tab-content .awesome-event-item .image-wrapper img {
    width: 100%;
    height: 100%;
    display: block; }
  .awesome-event-section .event-content-wrapper .tab-content .awesome-event-item .event-content {
    padding: 30px; }
  .homepage4 .absolute-sponsor-section ul {
    top: 0;
    width: 100%;
    display: table; }
  .absolute-sponsor-section ul {
    width: 100%;
    display: table; }
  .absolute-sponsor-section ul li {
    width: 50%;
    height: auto;
    text-align: center; }
  .default-footer-section .footer-top .instagram-wrapper ul li {
    width: 110px;
    height: 100px; }
  .award-section .company-age {
    margin-bottom: 30px; }
  .service-section .team-btn {
    padding: 0px;
    margin-bottom: 30px;
    text-align: center !important; }
  .service-section .service-wrapper > ul > li {
    width: 50%; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper {
    width: 100%;
    margin: 0px;
    display: table;
    margin-top: 30px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul {
    float: none;
    display: table;
    margin: 0 auto; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul li {
    margin: 0px 10px; }
  .sidebar-section {
    max-width: 370px;
    margin: 0 auto; }
  .event-search-section .section-title {
    margin-bottom: 50px; }
  .event-section .sidebar-section .spacial-event-wrapper {
    margin-bottom: 50px; }
  .event-section .sidebar-section .spacial-event-wrapper .overlay-black {
    padding: 50px 30px; }
  .event-details-section .event-details .event-info-list ul li .event-content .event-date {
    font-size: 14px; }
  .event-details-section .comment-form .section-title .rateing-star-wrapper {
    right: 0;
    bottom: 0;
    display: table;
    position: unset;
    text-align: center;
    margin: 30px auto; }
  .event-details-section .comment-form .form-wrapper {
    text-align: center;
    margin-bottom: 50px; }
  .faq-section .section-title .big-title {
    margin-bottom: 30px; }
  .faq-section .section-title .text-right {
    text-align: center !important; }
  .faq-section .faq-content-wrapper {
    overflow-x: scroll; }
    .faq-section .faq-content-wrapper .faq-accordion,
    .faq-section .faq-content-wrapper .faq-accordion2,
    .faq-section .faq-content-wrapper .faq-accordion3,
    .faq-section .faq-content-wrapper .faq-accordion4 {
      max-width: 570px;
      margin: 0 auto 50px; }
  .service-section .service-wrapper .service-item {
    border-radius: 3px;
    background-color: #f7f7f7; }
    .service-section .service-wrapper .service-item .service-image {
      width: 100%;
      padding-right: 0px;
      padding-bottom: 30px; }
      .service-section .service-wrapper .service-item .service-image .big-image img {
        width: 100%;
        height: 100%;
        display: block; }
      .service-section .service-wrapper .service-item .service-image .small-image {
        right: 30px; }
    .service-section .service-wrapper .service-item .service-content {
      width: 100%;
      padding: 30px 30px; }
  .blog-section .blog-wrapper .tab-content {
    margin-bottom: 80px; }
  .our-management-section .owl-theme .owl-dots button {
    width: 56px; } }

/* ====================================================================================================
2 - media screen and (max-width: 991px) - end --->>>for medium device
==================================================================================================== */
/* ====================================================================================================
3 - media screen and (max-width: 767px) - start --->>>For Mobile Device
==================================================================================================== */
/* ====================================================================================================
3 - media screen and (max-width: 767px) - end --->>>For Mobile Device
==================================================================================================== */
/* ====================================================================================================
4 - media screen and (max-width: 680px) - start
==================================================================================================== */
/* ====================================================================================================
4 - media screen and (max-width: 680px) - end
==================================================================================================== */
/* ====================================================================================================
5 - media screen and (max-width: 480px) - start
==================================================================================================== */
@media screen and (max-width: 480px) {
  .sec-ptb-100 {
    padding: 50px 0px; }
  .slide-section .main-carousel1 .item .slider-item-content {
    padding: 100px 0px;
    padding-bottom: 250px; }
  .slide-section .main-carousel1 .item .slider-item-content .medium-text {
    font-size: 30px; }
  .slide-section .main-carousel1 .item .slider-item-content .big-text {
    font-size: 30px; }
  .slide-section .main-carousel1 .item .slider-item-content .small-text {
    letter-spacing: 2px; }
  .section-title .big-title {
    font-size: 24px; }
  .section-title .sub-title {
    font-size: 16px;
    letter-spacing: 2px; }
  .countdown-timer ul li {
    margin: 0px 1px; }
  .upcomming-event-section .upcomming-event-carousel .item .event-item .event-image {
    height: auto; }
  .upcomming-event-section .upcomming-event-carousel .item .event-item .event-content {
    left: 0px;
    right: 0px;
    padding: 30px;
    max-width: 100%;
    position: unset;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f7f7f7; }
  .upcomming-event-section .upcomming-event-carousel .item .event-item {
    margin-bottom: 30px; }
  .about-section .about-item-wrapper ul li {
    width: 100%;
    height: auto; }
  .about-section .about-item-wrapper ul li .about-item .title {
    font-size: 18px; }
  .conference-section .conference-location ul li {
    margin: 0px;
    width: 100%;
    text-align: center; }
  .conference-section .tab-wrapper .tab-menu .tab-nav {
    padding: 0px; }
  .tab-nav::-webkit-scrollbar {
    display: none; }
  .conference-section .tab-wrapper .tab-menu .more-btn {
    padding: 0px; }
  .special-offer-section .special-offer-content h2 {
    font-size: 24px; }
  .special-offer-section .special-offer-content h2 strong {
    font-size: 48px; }
  .event-section .tab-content .event-item .event-image {
    width: 100%;
    height: auto;
    margin: 0px; }
  .event-gallery-section .grid-item,
  .event-gallery-section .grid-sizer {
    width: 100%;
    max-height: 280px; }
  .speaker-section .speaker-carousel .slider-for .item .speaker-content .speaker-info {
    padding: 0px; }
  .advertisement-section .advertisement-content .title-large {
    font-size: 24px; }
  .partners-clients-section .partners-wrapper .partners-carousel .item ul li {
    width: 100%;
    height: auto; }
  .event-section .event-tab-menu .nav li {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px; }
  .event-section .tab-content .event-item .event-image {
    width: 100%;
    height: auto;
    margin: 0px;
    margin-bottom: 15px; }
  .event-section .tab-content .event-item .event-content {
    width: 100%;
    display: table;
    padding: 0px 15px; }
  .news-update-section .faq-accordion .card .card-header .btn {
    white-space: normal; }
  .news-update-section .overlay-black.sec-ptb-100 {
    overflow-x: scroll; }
  .news-update-section .latest-blog-wrapper .latest-blog .blog-image {
    float: none;
    margin: 0 auto 30px; }
  .news-update-section .latest-blog-wrapper .latest-blog .blog-content {
    width: 100%;
    display: table;
    padding: 0px 30px;
    text-align: center; }
  .map-section .address-wrapper {
    display: none; }
  .footer-section2 .footer-bottom .footer-menu ul li {
    margin: 0;
    width: 50%;
    text-align: center; }
  .main-carousel2 .item .slider-content .date {
    letter-spacing: 1px; }
  .main-carousel2 .item .slider-content .bold-text,
  .main-carousel2 .item .slider-content .title-text {
    font-size: 36px; }
  .event-advertisement-section .event-item .event-image {
    float: none;
    margin: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 30px; }
  .event-advertisement-section .event-item .event-content {
    padding: 0px;
    display: table; }
  .event-advertisement-section .event-item .event-content .event-title .title-text {
    font-size: 18px; }
  .event-advertisement-section .event-item .event-content .event-title .tag {
    font-weight: 400; }
  .event-advertisement-section .right-content-wrapper .countdown-timer ul li {
    height: auto;
    padding: 8px 8px;
    min-width: 60px; }
  .event-advertisement-section .right-content-wrapper .booking-btn {
    margin: 10px 0px; }
  .testimonial5-section .item {
    padding: 0px; }
  .special-offer-section .special-offer-content h2 strong {
    font-size: 30px; }
  .default-sponsor-section ul li {
    width: 100%;
    height: auto;
    margin-bottom: 15px; }
  .footer-section3 .footer-bottom .footer-menu ul li {
    width: 50%;
    margin: 0px;
    text-align: center; }
  .countdown-timer ul li {
    padding: 10px 5px; }
  .homepage3 .main-carousel2 .item .slider-content .custom-btn {
    margin: 10px 0px; }
  .absolute-eventmake-section .eventmaking-wrapper .eventmake-tabs li {
    width: 100%;
    margin: 0px;
    text-align: center; }
  .absolute-eventmake-section .eventmaking-wrapper .tab-content .tab-pane ul li {
    width: 100%; }
  .about-section2 .services-list ul li {
    width: 100%; }
  .upcomming-event-section2 .comming-event-item .event-content {
    padding-right: 0;
    margin-bottom: 30px; }
    .upcomming-event-section2 .comming-event-item .event-content .text-left a {
      margin: 5px 0px; }
  .upcomming-event-section2 .comming-event-item .event-image .small-image {
    width: 80px;
    height: 80px; }
  .default-footer-section .footer-top .basic-info .basic-info-item .info-content h3 {
    margin: 0px;
    font-size: 16px; }
  .default-footer-section .footer-bottom .footer-menu ul li {
    width: 50%;
    margin: 0px;
    text-align: center; }
  .about-section2 .services-list ul li a .icon {
    width: 50px;
    height: 50px;
    border: none;
    font-size: 24px;
    border-radius: 3px; }
    .about-section2 .services-list ul li a .icon i {
      font-size: 24px;
      line-height: 50px; }
  .about-section2 .services-list ul li a .title-text {
    margin: 0px;
    font-size: 16px; }
  .about-section2 .services-list ul li a .icon:after,
  .about-section2 .services-list ul li a .icon:before {
    display: none; }
  .our-management-section .management-item {
    margin-bottom: 30px; }
  .our-management-section .management-item .item-title {
    margin-bottom: 15px; }
  .our-management-section .owl-theme .owl-dots {
    width: 100%; }
  .our-management-section .owl-theme .owl-dots button {
    width: 7.7%; }
  .upcomming-event-section3 .section-title {
    padding: 0px; }
  .upcomming-event-section3 .comming-event-item .event-content .custom-btn {
    margin: 10px 0px; }
  .awesome-event-section .awesome-event-menu ul li {
    width: 100%;
    margin: 0px;
    margin-bottom: 20px; }
  .awesome-event-section .tab-content .featured-event .event-content {
    right: 0px;
    bottom: 0px;
    padding: 30px;
    max-width: 100%;
    position: unset;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .awesome-event-section .tab-content .featured-event {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  .awesome-event-section .event-content-wrapper .tab-content .awesome-event-item {
    margin-bottom: 30px; }
  .absolute-sponsor-section ul li {
    width: 100%;
    border: none;
    border-bottom: 1px solid #f0f0f0; }
  .breadcrumb-section .breadcrumb-title .sub-title {
    font-size: 16px;
    letter-spacing: 1px; }
  .breadcrumb-section .breadcrumb-title .big-title {
    font-size: 30px; }
  .award-section .company-age .banner-img {
    -webkit-box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2); }
  .award-section .company-age {
    /* height: auto; */
    padding: 105px; }
  .award-section .company-age .banner-img {
    top: 0%;
    left: 0%;
    width: 100%;
    height: auto;
    position: unset;
    -webkit-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content .awaed-title {
    font-size: 18px; }
  .service-section .service-wrapper > ul > li {
    width: 100%; }
  .absolute-sponsor-section ul {
    width: 100%;
    display: table; }
  .award-section .awaed-item-area .awaed-wrapper .awaed-item .awaed-content .awaed-title {
    font-size: 14px; }
  .absolute-sponsor-section ul li a {
    padding: 0px 30px; }
  .booking-timeout-section .leftside-wrapper > h3 {
    font-size: 18px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul li {
    margin: 0px 5px; }
  .booking-section .booking-content-wrapper {
    overflow-x: scroll; }
  .booking-section .booking-content-wrapper .order-summary-table {
    padding: 15px;
    max-width: 770px; }
  .booking-section .section-title .big-title {
    font-size: 24px; }
  .booking-section .booking-content-wrapper .reg-info .form-title {
    font-size: 18px; }
  .booking-section .booking-content-wrapper .reg-info .form-wrapper .expired-date-form ul li {
    width: 100%; }
  .booking-section .booking-content-wrapper .billing-form .form-item-group ul li {
    width: 100%; }
  .event-search-section .search-form ul li {
    margin: 0px;
    width: 100%;
    margin-bottom: 30px; }
    .event-search-section .search-form ul li:last-child {
      margin-bottom: 0px; }
    .event-search-section .search-form ul li .submit-btn {
      margin-top: 0px; }
  .event-section .search-result-form {
    padding: 0px; }
  .event-section .search-result-form .event-layout-btngroup {
    display: table;
    position: unset;
    margin: 30px auto 0px; }
  .event-section .event-list-item .event-image {
    float: none;
    width: 100%;
    height: auto;
    margin-right: 0; }
  .event-section .event-list-item .event-content .event-title:after {
    left: -15px; }
  .event-details-section .event-details .event-title .event-title {
    font-size: 24px; }
  .event-details-section .event-details .event-details-carousel .item {
    height: auto; }
  .event-details-section .event-details .event-info-list ul li {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 5px; }
  .event-details-section .event-schedule .schedule-date-menu > li,
  .event-details-section .event-schedule .tab-content .hotal-menu > li {
    width: 100%;
    margin-right: 0px; }
  .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-image {
    width: 100%;
    float: none;
    height: auto;
    margin-bottom: 30px; }
    .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-image img {
      width: 100%;
      height: 100%;
      display: block; }
  .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content {
    width: 100%;
    padding: 0px; }
    .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-title {
      font-size: 24px; }
  .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-info-list ul li {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 5px; }
  .event-details-section .event-pricing-plan .pricing-list ul .pricing-table {
    width: 100%;
    margin-bottom: 30px; }
  .event-details-section .event-pricing-plan .pricing-list ul .popular-pricing-table {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .event-details-section .register-wrapper {
    overflow-x: scroll; }
    .event-details-section .register-wrapper .register-table ul {
      width: 770px; }
  .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left {
    margin-bottom: 15px; }
  .sidebar-section .spacial-event-wrapper .overlay-black {
    padding: 60px 30px; }
  .faq-section .faq-content-wrapper .faq-accordion .card .card-header .btn:after {
    display: none; }
  .service-section .service-wrapper .service-item .service-image .small-image {
    width: 150px; }
  .service-section .service-wrapper .service-item .service-content .service-title .title-text {
    font-size: 24px; }
  .service-section .service-wrapper .service-item .service-content .service-type-list ul li {
    width: 100%;
    padding-right: 0px; }
  .service-section .service-wrapper .service-item .service-content .custom-btn {
    margin-bottom: 10px; }
  .blog-section .layout-btn-group h3 {
    display: none; }
  .blog-section .blog-layout-menubar {
    display: table;
    margin: 0 auto;
    float: none !important; }
  .blog-section .blog-list-item .blog-image,
  .blog-section .blog-list-item .blog-content {
    width: 100%;
    float: none !important; }
  .blog-section .blog-list-item .blog-content {
    padding: 0px;
    padding-top: 30px;
    text-align: left !important; }
  .error-section .error-content {
    text-align: center; } }

/* ====================================================================================================
5 - media screen and (max-width: 480px) - end
==================================================================================================== */
/* ====================================================================================================
6 - media screen and (max-width: 320px) - start
==================================================================================================== */
@media screen and (max-width: 320px) {
  .countdown-timer ul li {
    height: 65px;
    min-width: 65px;
    padding: 10px 5px;
    border-radius: 10px;
    display: inline-block; }
  .countdown-timer ul li strong {
    font-size: 24px;
    font-weight: 700; }
  .countdown-timer ul li small {
    font-size: 12px; }
  .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link {
    padding: 8px; }
  .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link .image {
    width: 65px;
    height: 65px;
    margin-right: 8px; }
  .conference-section .tab-wrapper .tab-menu .tab-nav .nav-item .nav-link .title {
    font-size: 16px; }
  .custom-btn {
    padding: 12px 25px; }
  .special-offer-section .special-offer-content h2 {
    font-size: 18px; }
  .special-offer-section .special-offer-content h2 strong {
    font-size: 24px; }
  .main-carousel2 .item .slider-content .bold-text, .main-carousel2 .item .slider-content .title-text {
    font-size: 24px; }
  .event-section .tab-content .event-item2 .event-content .event-title .title {
    font-size: 18px; }
  .footer-section3 .footer-top .basic-info .basic-info-item .info-content h3 {
    font-size: 14px; }
  .about-section2 .services-list ul li a .icon {
    margin-right: 5px; }
  .default-footer-section .footer-top .basic-info .basic-info-item .info-content h3 {
    font-size: 14px; }
  .our-management-section .owl-theme .owl-dots button {
    width: 7.6%; }
  .map-section .find-event-form {
    left: 0px; }
  .homepage4 .event-gallery-section .section-title .big-title {
    font-size: 24px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul li {
    width: 100%;
    margin: 15px 0px; }
  .booking-timeout-section .rightside-wrapper .clock-wrapper ul li small {
    padding: 12px 10px; }
  .booking-section .ticket-buying-form {
    margin-bottom: 30px; }
  .sidebar-section .location-wrapper .contact-links ul li a {
    font-size: 14px; }
  .event-section .event-list-item .event-content .event-title .title {
    font-size: 18px; }
  .event-section .event-grid-item .event-content .event-title .title {
    font-size: 18px; }
  .event-details-section .event-schedule .tab-content .tab-content .tab-pane .hall-item .hall-content .event-title {
    font-size: 18px; }
  .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left .title {
    float: none;
    width: 100%;
    margin: 0px;
    margin-right: 0px;
    margin-bottom: 5px; }
  .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-left ul {
    margin: 0px 0px; }
  .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul {
    float: none;
    width: 100%; }
    .event-details-section .reviewer-comment-wrapper .comment-bar .comment-content .meta-wrapper .btn-group-right ul li {
      width: 100%;
      display: block;
      margin-bottom: 5px; }
  .event-details-section .section-title .big-title {
    font-size: 30px; }
  .event-details-section .comment-form .section-title .rateing-star-wrapper .rating-title {
    width: 100%;
    margin-bottom: 15px; }
  .event-details-section .comment-form .section-title .rateing-star-wrapper .rateing-star-form {
    display: table;
    margin: 0 auto;
    float: none !important; }
  .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn {
    padding: 15px 15px; }
  .sidebar-section .faq-wrapper .faq-accordion .card .card-header .btn:after {
    right: 15px; }
  .breadcrumb-section .breadcrumb-list ul .breadcrumb-item {
    margin-right: 15px;
    padding-right: 15px; }
  .service-section .service-wrapper .service-item .service-image .small-image {
    width: 100px; }
  .service-section .service-wrapper .service-item .service-content .service-title .title-text {
    font-size: 18px; }
  .service-section .service-wrapper .service-item .service-content {
    padding: 25px 15px; }
  .error-section .icon {
    width: 250px;
    height: 250px;
    font-size: 150px;
    line-height: 250px; }
  .event-section .tab-content .event-item2 .event-content .tickets-details-btn {
    padding: 15px 30px; } }

/* ====================================================================================================
6 - media screen and (max-width: 320px) - end
==================================================================================================== */
.fontsize30{
  font-size: 30px;
}
.title{
  text-align: center;
}
.custom-btn{

  justify-content: center;
}
.height380{
  height: 380px;
}

.about-section .about-item-wrapper ul li:hover .fontsize30{
  color: white;
  }

  .event-image1 {
    width: 100%;
    height: 490px;
    overflow: hidden;
    position: relative;
}
.swiper-button-prev{
  color:#ffbe30;
}
.swiper-button-next{
  color:#ffbe30; 
}
.swiper-pagination-bullet-active{
  color:#ffbe30; 
}
@media (max-width: 700px){
  .slider-item-content {
    z-index: 1;
    text-align: center;
    padding:15px 0px 15px 0px ;
    padding-bottom: 0px; }
    .slider-item-content .big-text 
     {
      font-size: 30px;
      padding:330px 60px 250px;
     }
     .slider-item-content .medium-text{
      font-size: 25px;
     }
     .widpad{
      width:100% !important;
      
    }
    
     
.slider-item-content .link-groups {
  padding: 0px 0px 15px 0px;
}

  }
.widpad{
  width:400px;
  padding: 10px;
}

.centerone{
  display: flex;
  justify-content: center;
}
.margin10{
  margin: 10px;
}
.height440{
height: 440px;
width: 550px;
}
.colorwhite{
  color:white;
}
.hadlogo{
  width: 90px;
  margin-left:25px ;
}
@media (max-width: 990px){
  .colorwhite{
    color:black;
  }

  .hadlogo{
    width: 35px;
  }
.clients-comment {
    padding: 0px 20px ;
      }
  /* .margintop{
    margin-top: 65px;
  } */
  .Social-media-icons{

  }
}

.sociallinkfooter{

  background-color: black;
  position: fixed;
  z-index: 1000;
  bottom: -1px;
  width: 100%;
}

.objectcenter{
display:flex;
justify-content: center;
margin: 15px;
}
.objectcenter svg{
  color: white;
  font-size: 20px;
}
@media (min-width: 990px){
  .sociallinkfooter{
    display: none;
  }
  .fontsizeb{
    font-size: 30px;
  }

 
}
.padditop10{
padding-bottom: 10px;
}
.mapimg{
  width: 100%;
  height: 30vh;
}
.paddiicon{
  padding: 10px 15px;
  border: 1px solid white ;
  border-radius: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.marcon{
  margin-bottom: 20px;
}
.iconsize{
  font-size: 20px;
}
.margtop10{
  margin-top: 10px;
}
.errorlogo{
  display: flex;
  text-align: center;
  justify-content: center;
}
.pad50{
  padding-top: 150px !important;
}
.pad40{
  padding-top: 40px;
}

.fontsizeb{
  font-size: 55px;
}
@media (max-width: 990px){

  .fontsizeb{
    font-size: 20px;
  }
  .Social-media-icons{
    display: none;
  }

 
}
@media (max-width: 700px){
  .textpaddingbottem{
      padding-bottom: 45px;
  }
.custom-btn{
  padding: 11px, 25px !important;
} }
@media (min-width:700px){
  .textpaddingbottem{
    padding-bottom: 0px;
}
.custom-btn {
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
  padding: 15px 35px;
  text-align: center;
  position: relative;
  border-radius: 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  text-transform: uppercase;
  background: #e0a231;
  color: #ffffff !important;
  -webkit-box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.2); }}


  .second-buttion{
    font-weight: 700;
    padding: 7px 15px;
    color: #ffffff;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    border: 3px solid #ffbe30; }
.textcent{
  margin-top: 20px;
  text-align: center;
}

/* Gallery1.css */
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 -10px;
}

.gallery-item {
  flex: 0 0 calc(33.33% - 20px);
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio for responsive videos */

  @media screen and (max-width: 768px) {
    padding-bottom: 100%; /* Full width for mobile */
  }
}

.video-wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Add more styles as needed for responsiveness */


.bannerbackgrounf{
  background-image: radial-gradient(circle, #808080, #5f5f5f, #3f3f3f, #222222, #000000);
}


.imagebanner1{
  position: absolute;
  /* top: 106px; */
  bottom: 0;
  right: 0;
  height: 500px;

}
.texticon{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
.swiper-pagination-bullet-active{
  background: gray !important;
}
.white_background{
  background-color: white;
}
.imageradus{
  border-radius: 50%;
  height: 35vh;
  width: 75%;

}
.image-center{
  display: flex;
  justify-content: center;
}