.aboutus_part_one{
padding: 5px;
}
.about_part_two{
    margin: 10px;
    padding: 5px;
color: white !important;
background-color: black;
}

.text-color{
    color: white;
}
.padding_three{
padding: 50px 0px;
}
.paddin-bott{
    padding: 20px 20px;
}
.text-centerr{
   
    text-align: center;
}